import { useEffect, useState } from "react";

export default function EPAsTable({props}) {
  const {getProgramsInfo, allBreakdowns, userInfo, scrollElementIntoView, programsData, epasData, evaluationsData, setEpaPageMode, getEpaShortStats, wordifyStatus, userSubscribedEpas, displayContentsForProgramId, handleDisplayProgramContentsSelection} = props;

  const [currentlyDisplayedContentsForProgramId, setCurrentlyDisplayedContentsForProgramId] = useState(null);

  const [programsInfo, setProgramsInfo] = useState(getProgramsInfo());
  const [programs, setPrograms] = useState([]);
  const [showOptions, setShowOptions] = useState({});


  function handleShowOptionsChange(e) {
    let {name, value, options, checked} = e.target
    setShowOptions(current=>({
      ...current,[name]: checked
    }));
    // console.log(checked);
  }

  useEffect(()=>{
    setPrograms((current)=>{
      // console.log("New programs:");
      // console.log((programsInfo["otherPrograms"] || []).concat(programsInfo["homeProgram"] || []));
      // return (programsInfo["otherPrograms"] || []).concat(programsInfo["homeProgram"] || []);
      return [...getProgramsInfo().homeProgram, ...getProgramsInfo().otherPrograms];
    });

  },[userInfo]);


  function getEvaluationsForProgram(prId) {
    let epasForProgram = props.epasData.filter(epa=>epa.program_id===prId);
    if (epasForProgram.length < 1) { return []; }
    let evals = [];
    props.evaluationsData.map(ev=>{
      let epa = epasForProgram.filter(epa=>epa.id===ev.epa_id)
      if (epa.length > 0) {
        evals.push({...ev,code:epa[0].code, short_name:epa[0].short_name, name:epa[0].name});
      }
    });
    // setEvals(current=>({...current,[prId]: evals}));
    // if (evals[prId] === undefined || evals[prId] === null ) {
      evals.sort((a,b)=> { return (a.code.localeCompare(b.code,"en",{numeric:true}))});
      return evals;
    // } else {
      // return evals[prId];
    // }
  }

  function isEvalDisplayable(ev,prId) {
    if (ev === undefined || ev === null) { return null; }
    switch (ev.status) {
      case "sent": return (showOptions[("epaSwitchOptionSent-" + prId)] === undefined || showOptions[("epaSwitchOptionSent-" + prId)] === null) ? true : showOptions[("epaSwitchOptionSent-" + prId)];
      case "unsent": return (showOptions[("epaSwitchOptionUnsent-" + prId)] === undefined || showOptions[("epaSwitchOptionUnsent-" + prId)] === null) ? true : showOptions[("epaSwitchOptionUnsent-" + prId)];
      case "completed": return (showOptions[("epaSwitchOptionCompleted-" + prId)] === undefined || showOptions[("epaSwitchOptionCompleted-" + prId)] === null) ? true : showOptions[("epaSwitchOptionCompleted-" + prId)];
      case "expired":
      case "deleted":
      case "failed": return (showOptions[("epaSwitchOptionExpired-" + prId)] === undefined || showOptions[("epaSwitchOptionExpired-" + prId)] === null) ? true : showOptions[("epaSwitchOptionExpired-" + prId)];
      default: return true;
    }
  }

  // Function to provide a list of EPAs that have not been touched by this user (i.e. either have no status at all or status
  // is unacceptable e.g. failed, expired, deleted - this does not include Unsent as that counts as planned!)
function getUntouchedEpas(prId, considerPendingAsTouched=false) {
  
  function isTouched(value) {
    switch ((value === null || value === undefined) ? "" : value.toLowerCase()){
      // case "sent":
      case "unsent":
      case "completed":
      return true;
      case "expired":
      case "deleted":
      case "failed":
        return false;
    }
    if (value.toLowerCase() == "sent"){
      return considerPendingAsTouched ? true : false;
    }
  }
  function isEpaUntouched(epa) {
    return (!touchedEpasEvalCount.hasOwnProperty(epa.id) || (touchedEpasEvalCount[epa.id]) < epa.quantity_required);
  }
  let epas = epasData.filter(e=>e.program_id === prId);
  let touchedEvals = evaluationsData.filter(e=>isTouched(e.status));
  
  let touchedEpasEvalCount = {};
  touchedEvals.map(e=>touchedEpasEvalCount[e.epa_id] = touchedEvals.filter(t=>e.epa_id===t.epa_id).length);
  let untouchedEpas = epas.filter(epa=>isEpaUntouched(epa));
  // for (let i = 0; i < untouchedEpas.length; i++) {
  //   if (touchedEpasEvalCount.hasOwnProperty(untouchedEpas[i].id)) {

  //   } else {

  //   }
  //   untouchedEpas[i]
    
  // }
  untouchedEpas.sort((a,b)=> { return (a.code.localeCompare(b.code,"en",{numeric:true}))});
  return untouchedEpas;
}


  
function propOrStr(obj, property, notFoundText) {
    if(obj === undefined || obj === null) { return notFoundText; }
    if (obj.hasOwnProperty(property)) {
        return obj[property];
    } else { return notFoundText; }
}

function formatDate(date) {
    if (date === undefined || date === null) { return date; }
    let d;
    if (typeof date === "string") { d = new Date(date); }
    else { d = date; }
    if (!(d instanceof Date)) { return d; }
    return {short:(d.toLocaleString('default', { month: 'short', timeZone:"UTC" })) + " " + d.getUTCDate(), long:d.toLocaleString('default', { month: 'short', day:"numeric", year:"numeric", weekday:"short", timeZone:"UTC" })};
    // return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
 }
 

function decorateRole(role) {
    // Only uppercase if abbreviated
    if (typeof role === "string") {
        switch (role.toLocaleLowerCase()) {
            case "ttl":
                return "TTL"
            default:
                break;
        }
    }
    return role;
}



const addButton = (program, epa) => {
  let subs = userSubscribedEpas({forProgramId:program.id});
  // Check if EPA is in the 'actionable' range, meaning it is subscribed to.
  let actionable = subs.filter(s=>s.id===epa.id).length>0
  if (actionable) {
    return <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addEpaForm" onClick={e=>{setEpaPageMode({mode:"add", prId: program.id, epaId: epa.id})}}>Add EPA</button>
  } else {
    return <><button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addEpaForm" disabled="disabled">Add EPA</button> <span className="info-tooltip fs-6 fw-normal" style={{fontFamily:"initial !important"}} data-info-tooltip-content="You cannot Add this EPA because you are not enrolled into its version. Check My Profile->Edit Account to see or change the versions you are enrolled into."><sup className="fw-bold">&#9432;</sup></span></>
  }
};
const editButton = (program, ev) => {
  let subs = userSubscribedEpas({forProgramId:program.id});
  // Check if EPA is in the 'actionable' range, meaning it is subscribed to.
  let actionable = subs.filter(s=>s.id===ev.epa_id).length>0
  if (actionable) {
    return <button className="btn btn-secondary btn-sm"  data-bs-toggle="modal" data-bs-target="#addEpaForm" onClick={e=>{setEpaPageMode({mode:"edit", prId: program.id, epaId: ev.epa_id, ev: ev})}}>Edit</button>
  } else {
    return <><button className="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#addEpaForm" disabled="disabled">Edit</button> <span className="info-tooltip fs-6 fw-normal" style={{fontFamily:"initial !important"}} data-info-tooltip-content="You cannot Edit this evaluation because you are not enrolled into its EPA version. Check My Profile->Edit Account to see or change the versions you are enrolled into."><sup className="fw-bold">&#9432;</sup></span></>
  }
};

function isDisplayed(programId) {
  // console.log("Displayed: " + programId + "   -   " + (displayContentsForProgramId && displayContentsForProgramId == programId));
  return (displayContentsForProgramId && displayContentsForProgramId == programId);
}


    return(
<div className="accordion" id="epaTableContainerParent">

  {programs.map((pr,index)=>{
    return(

      <div className="accordion-item" id={`epaTableContainer-heading-${pr.id}`} key={`epaTableContainer-heading-${pr.id}`}>


        <h2 className="accordion-header" id={`epaTableContainer-wrapper-${index}`}>
          {/* <button className= { "accordion-button" } type="button" data-bs-toggle="collapse" data-bs-target={`#epaTableContainer-container-${index}`} aria-expanded={displayContentsForProgramId == pr.id == 0 ? "true" : "false"} aria-controls={`epaTableContainer-container-${index}`} */}
          <button className= { isDisplayed(pr.id) ? "accordion-button" : "accordion-button collapsed" } type="button" data-bs-toggle="collapse" data-bs-target={`#epaTableContainer-container-${index}`} aria-expanded={displayContentsForProgramId == pr.id == 0 ? "true" : "false"} aria-controls={`epaTableContainer-container-${index}`}
          onClick={()=>{handleDisplayProgramContentsSelection(null); scrollElementIntoView({id:`epaTableContainer-heading-${pr.id}`,postpone:200});}} >
            <span className="fw-bold">{pr.name}</span> &nbsp; ({allBreakdowns({forProgramId: pr.id}).sumCompleted} of {allBreakdowns({forProgramId: pr.id}).sumRequired} EPAs completed, or {allBreakdowns({forProgramId: pr.id}).percentTotalCompleted}%)
          </button>
        </h2>
    {/* <div id={`epaTableContainer-container-${index}`} className={`accordion-collapse collapse`} aria-labelledby={`epaTableContainer-wrapper-${index}`}> */}
        <div id={`epaTableContainer-container-${index}`} className={`accordion-collapse collapse${ isDisplayed(pr.id) ? " show" : ""}`} aria-labelledby={`epaTableContainer-wrapper-${index}`}
        data-bs-parent="#epaTableContainerParent">
          <div className="accordion-body">



          <h4 className="text-center">{pr.name}</h4>
          <h5 className="text-center">You have previously sent these EPAs:</h5>
          Show: &nbsp;<div className="d-block d-lg-none"></div>
          <div className="form-check form-check-inline form-switch">
            <input defaultChecked onChange={(e)=>handleShowOptionsChange(e)} className="form-check-input" type="checkbox" role="switch" id={`epaSwitchOptionCompleted-${pr.id}`} name={`epaSwitchOptionCompleted-${pr.id}`} />
            <label className="form-check-label" htmlFor={`epaSwitchOptionCompleted-${pr.id}`}>Completed</label>
          </div><div className="d-block d-lg-none"></div>
          
          <div className="form-check form-check-inline form-switch">
            <input defaultChecked onChange={(e)=>handleShowOptionsChange(e)} className="form-check-input" type="checkbox" role="switch" id={`epaSwitchOptionSent-${pr.id}`} name={`epaSwitchOptionSent-${pr.id}`} />
            <label className="form-check-label" htmlFor={`epaSwitchOptionSent-${pr.id}`}>Pending</label>
          </div><div className="d-block d-lg-none"></div>

          <div className="form-check form-check-inline form-switch">
            <input defaultChecked onChange={(e)=>handleShowOptionsChange(e)} className="form-check-input" type="checkbox" role="switch" id={`epaSwitchOptionUnsent-${pr.id}`} name={`epaSwitchOptionUnsent-${pr.id}`} />
            <label className="form-check-label" htmlFor={`epaSwitchOptionUnsent-${pr.id}`}>Created but Unsent</label>
          </div><div className="d-block d-lg-none"></div>

          <div className="form-check form-check-inline form-switch">
            <input defaultChecked onChange={(e)=>handleShowOptionsChange(e)} className="form-check-input" type="checkbox" role="switch" id={`epaSwitchOptionExpired-${pr.id}`} name={`epaSwitchOptionExpired-${pr.id}`} />
            <label className="form-check-label" htmlFor={`epaSwitchOptionExpired-${pr.id}`}>Expired/Deleted/Failed</label>
          </div>


          <table className="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" className="d-none d-md-table-cell">Name</th>
                <th scope="col" className="d-md-none">Name</th>
                <th scope="col">Status</th>

                <th scope="col" className="d-none d-md-table-cell">Sent On</th>
                <th scope="col" className="d-none d-md-table-cell">Due By</th>
                <th scope="col" className="d-none d-md-table-cell">Evaluator</th>
                <th scope="col" className="d-none d-md-table-cell">On Rotation</th>

                <th></th>
                </tr>
            </thead>
            <tbody>
              
              { 
                  props.evaluationsData !== null && getEvaluationsForProgram(pr.id)
                  .filter(ev=>isEvalDisplayable(ev,pr.id))
                  .map( ev=> {
                    // console.log(ev)
                  return(
                    <tr key={ev.id}>
                      {/* <td>{props.epasData.filter(ep=>ep.id===ev.epa_id).length > 0 && getPropertyOrString(props.categoriesData.filter(c=>c.id===(props.epasData.filter(ep=>ep.id===ev.epa_id)[0].category_id))[0],"name","")}</td>
                      <td>{props.epasData.filter(ep=>ep.id===ev.epa_id).length > 0 && getPropertyOrString(props.programsData.filter(p=>p.id===(props.epasData.filter(ep=>ep.id===ev.epa_id)[0].program_id))[0],"short_name","")}</td> */}
                      <td>{ev.code}</td>
                      <td className="d-none d-md-table-cell"><span className="fw-bold">{ev.short_name}</span> <span className="info-tooltip" data-info-tooltip-content={ev.name}><sup>&#9432;</sup></span></td>
                      
                      
                      <td className="d-md-none"><span className="fw-bold">{ev.short_name}</span> <span className="info-tooltip" data-info-tooltip-content={
                        ev.name + 
                        ((ev.date_sent === undefined || ev.date_sent === null) ? "" : ", Sent On: " + propOrStr(formatDate(ev.date_sent),"short","")) + 
                        ((ev.date_due_by === undefined || ev.date_due_by === null) ? "" : ", Due By: " + propOrStr(formatDate(ev.date_due_by),"short","")) +

                        ((ev.evaluator_name === undefined || ev.evaluator_name === null) ? "" : (", Evaluated By: " + ev.evaluator_name + ((ev.evaluator_role !== null && ev.evaluator_role.length > 0) ? (' (' + decorateRole(ev.evaluator_role) + ')') : "")))
                        }><sup>&#9432;</sup></span></td>

                      <td><span className={`text-capitalize ${wordifyStatus(ev.status).class}`}>{wordifyStatus(ev.status).label}</span></td>
                      
                      <td className="d-none d-md-table-cell">{propOrStr(formatDate(ev.date_sent),"short","")} {(ev.date_sent === undefined || ev.date_sent === null) ? "" : <span className="info-tooltip" data-info-tooltip-content={propOrStr(formatDate(ev.date_sent),"long","")}><sup>&#9432;</sup></span>}</td>
                      <td className="d-none d-md-table-cell">{propOrStr(formatDate(ev.date_due_by),"short","")} {(ev.date_due_by === undefined || ev.date_due_by === null) ? "" : <span className="info-tooltip" data-info-tooltip-content={propOrStr(formatDate(ev.date_due_by),"long","")}><sup>&#9432;</sup></span>}</td>
                      <td className="d-none d-md-table-cell">{ev.evaluator_name}<span className="text-capitalize">{(ev.evaluator_role !== null && ev.evaluator_role.length > 0) ? (' (' + decorateRole(ev.evaluator_role) + ')') : ""}</span></td>
                      <td className="d-none d-md-table-cell">{propOrStr(props.rotationsData.filter(r=>r.id===ev.rotation_id)[0],"name","")}</td>
                      
                      <td>{editButton(pr,ev)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>


              <hr />
              <h5 className="text-center">You still have the following EPAs to send:</h5>
                  <table className="table table-hover table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      {/* <th scope="col">Status</th> */}
                      <th></th>
                      </tr>
                  </thead>
                  <tbody>
                  {getUntouchedEpas(pr.id).map((ep)=>{
                return (
                          <tr key={ep.id}>
                            <td>{propOrStr(ep,"code","")}</td>
                            <td><span className="fw-bold">{propOrStr(ep,"short_name","")}</span> <span className="info-tooltip" data-info-tooltip-content={propOrStr(ep,"name","")}><sup>&#9432;</sup></span> {getEpaShortStats({forEpaId:ep.id,forUserId:userInfo.id, completed:true, required: true, sent:true}).text}</td>
                            {/* <td><span className={`text-capitalize ${wordifyStatus("unsent").class}`}>{wordifyStatus("unsent").label}</span></td> */}
                            <td>{addButton(pr,ep)}</td>
                          </tr>
                  )})}
                  </tbody>
                </table>
          </div>
        </div>




  </div>
    )
  })}

</div>

    );
}
function NavBar(props) {
    return(
<div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
                <small><i className="fa fa-map-marker-alt me-2"></i>Montréal, Canada</small>
                <small className="ms-4"><i className="fa fa-envelope me-2"></i>info@epatool.com</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
                <small>Get in touch:</small>
                <a className="text-white-50 ms-3" href="https://www.linkedin.com/in/fardadja/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
                <a className="text-white-50 ms-3" href="https://www.instagram.com/fardadja/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
            </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <a href="/" className="navbar-brand ms-4 ms-lg-0">
                <h1 className="fw-bold text-primary m-0">EPA<span className="text-white">Tracking</span>Tool</h1>
            </a>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    {/* <a href="/" className="nav-item nav-link active">Home</a> */}
                    {!props.isLoggedIn && (<a href="" className="nav-item nav-link d-lg-none" data-bs-toggle="modal" data-bs-target="#loginForm">Log In</a>)}
                    {!props.isLoggedIn && (<a href="" className="nav-item nav-link d-lg-none" data-bs-toggle="modal" data-bs-target="#registrationForm">Register</a>)}
                    {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#profilePanel" aria-controls="profilePanel">Toggle top offcanvas</button> */}

                    {props.isLoggedIn && (<a href="" className="nav-item nav-link d-lg-none"  onClick={(e)=>{e.preventDefault(); props.myProfileAction(e);}}>My Profile</a>)}
                    {props.isLoggedIn && (<a href="" className="nav-item nav-link d-lg-none"  onClick={(e)=>{e.preventDefault(); props.logout(e);}}>Log Out</a>)}

                    <a href="" className="nav-item nav-link d-lg-none" data-bs-toggle="modal" data-bs-target="#guideModal">Demo</a>
                    
                    {/* <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu m-0">
                            <a href="service.html" className="dropdown-item">Service</a>
                            <a href="donate.html" className="dropdown-item">Donate</a>
                            <a href="team.html" className="dropdown-item">Our Team</a>
                            <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                            <a href="404.html" className="dropdown-item">404 Page</a>
                        </div>
                    </div>
                    <a href="contact.html" className="nav-item nav-link">Contact</a> */}
                </div>
                
                <div className="d-none d-lg-flex ms-2">
                
                    {// My Profile Button
                    props.nonAuthApiLoaded ?
                        (
                            props.isLoggedIn ? (<button type="button" style={{'minWidth' : '150'}} className="btn btn-outline-primary py-2 px-3 mx-2" onClick={(e)=>{props.myProfileAction(e)}}>
                                    My Profile
                                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-arrow-right"></i></div></button>)
                            :
                                    (<button type="button" style={{'minWidth' : '150'}} className="btn btn-outline-primary py-2 px-3 mx-2" data-bs-toggle="modal" data-bs-target="#loginForm">
                                    Log In
                                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-arrow-right"></i></div></button>)
                        )
                    :
                    <button className="buttonload btn btn-outline-primary py-2 px-3">Loading <i className="fa fa-spinner fa-spin"></i></button>    
                }
                    
                    {
                    props.nonAuthApiLoaded ?
                    
                        (props.isLoggedIn ? (<button type="button" style={{'minWidth' : '150px'}} className="btn btn-outline-primary py-2 px-3 mx-2" onClick={(e)=>props.logout(e)}>
                        Log Out
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-arrow-right"></i></div></button>)

                    :
                        (<button type="button" style={{'minWidth' : '150'}} className="btn btn-outline-primary py-2 px-3 mx-2" data-bs-toggle="modal" data-bs-target="#registrationForm">
                        Register
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-arrow-right"></i></div></button>))
                    :
                    <button className="buttonload btn btn-outline-primary py-2 px-3 ms-2">Loading <i className="fa fa-spinner fa-spin"></i></button>        
                    }

                    <button type="button" style={{'minWidth' : '120px'}} className="btn btn-outline-primary py-2 px-3 mx-2"  data-bs-toggle="modal" data-bs-target="#guideModal">Demo 
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-info"></i></div></button>







                    {/* <button type="button" style={{'minWidth' : '150'}} className="btn btn-outline-primary py-2 px-3" data-bs-toggle="modal" data-bs-target="#guideModal">Demo <i className="fa fa-solid fa-circle-info fa-2xl" style={{'color':'white'}}></i></button> */}
                </div>
            </div>
        </nav>
    </div>
    );
}

export default NavBar;
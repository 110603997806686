
export default function Profile(props) {
    const {getProgramsInfo, allBreakdowns, userInfo, setEpaPageMode, handleDisplayProgramContentsSelection} = props;
    
    
    
    return (

        <div className={`offcanvas offcanvas-start${props.shouldShowPanel ? ' show' : ' hide'}`} 
        data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="profilePanel" 
        aria-labelledby="profilePanelLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="profilePanelLabel">Your Profile{", " + userInfo.firstName}</h5>
                
                <button type="button" className="btn-close btn-close-white .btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={(e)=>props.closePanel(e)}></button>
            </div>
                <div className="offcanvas-body">
                    <div>
                        <h6>Quick Summary:</h6>
                        { props.isLoggedIn && 
                        [...getProgramsInfo().homeProgram,...getProgramsInfo().otherPrograms].map(program => {
                            let stats = allBreakdowns({forProgramId: program.id});
                            return (<><p className="text-end">
                            <a href="" onClick={(e)=>{e.preventDefault(); handleDisplayProgramContentsSelection(program.id); }}>{stats.sumRequired} evals for {stats.countOfDistinctEpas} EPAs in {program.short_name}</a> <span className="info-tooltip" data-info-tooltip-content={program.name}><sup>&#9432;</sup></span>:
                            <br /># of evaluations pending: {stats.sumSent} 
                            <br /># of evaluations completed: {stats.sumCompleted}
                            <br />% of evaluations completed: {stats.percentTotalCompleted}%
                        </p>
                        <hr /></>)})
                        
                        }
                    </div>
                    <div className="text-end">
                        <button className="btn btn-outline-primary epa-action-btn d-block mb-2 py-2" data-bs-toggle="modal" data-bs-target="#addEpaForm" onClick={e=>setEpaPageMode({mode:"add", epaId: null, prId: null})} >Add New EPA</button>
                        {/* <button className="btn btn-outline-primary epa-action-btn d-block mb-2 py-2">Edit an EPA</button> */}
                        {/* <button className="btn btn-secondary epa-action-btn d-block mb-2">Delete an EPA</button> */}
                    </div>
                    <div>
                        <div className="account-link">
                            <hr />
                            <button className="btn btn-primary epa-action-btn d-block mb-2 py-2" data-bs-toggle="modal" data-bs-target="#editProfileForm">Edit Account</button>
                        </div>
                    </div>
                </div>
            </div>

)
}
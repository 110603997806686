import { useEffect, useState, useRef } from 'react';
import { Modal } from 'bootstrap';




import './App.css';
// import useScript from './components/ScriptResourceLoader'
import NavBar from './components/Navbar';
import Carousel from './components/Carousel';
import GlobalStats from './components/GlobalStats';
import AboutUs from './components/About';
import EPAsByProgram from './components/EPAsByProgram';
import Register from './components/Register';
import Login from './components/Login';
import Profile from './components/Profile';
import AddEPA from "./components/AddEPA";
import useTokens from './components/useTokens'
import EditProfile from './components/EditProfile';
import LoadingInline from './components/LoadingInline';
import Guide from './components/Guide';


function App() {


  const EVAL_STATUS = {
    UNSENT: "unsent",
    SENT: "sent",
    COMPLETED: "completed",
    FAILED: "failed",
    DELETED: "deleted",
    EXPIRED: "expired",
    NOTSET: null };

const DATE_CONST_NAMES = {
    ASSESSED: "date_assessment",
    SENT: "date_sent",
    RESULTED: "date_resulted",
    DUE: "date_due_by" };

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userInfo, setUserInfo] = useState(null)


  const [programsData, setProgramsData] = useState([]);
  const [evaluationsData, setEvaluationsData] = useState([]);
  const [rotationsData, setRotationsData] = useState([]);
  const [epasData, setEpasData] = useState([]);
  const [globalEpasData, setGlobalEpasData] = useState([]);
  const [globalStatsData, setGlobalStatsData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [allApiLoaded, setAllApiLoaded] = useState(false);
  const [nonAuthApiLoaded, setNonAuthApiLoaded] = useState(false);
  const nonAuthApiLoadStatusRef = useRef(nonAuthApiLoaded);
  const [authApiLoaded, setAuthApiLoaded] = useState(false);
  const [spinnerShow, setSpinnerShow] = useState(true);
  const [profilePanelShow, setProfilePanelShow] = useState(false);
  
  const [imgLib, setImageLib] = useState({});
  
  
  const [modalKeyLogin, setModalKeyLogin] = useState(Date.now() + 1);
  const [modalKeyRegistration, setModalKeyRegistration] = useState(Date.now() + 2);
  const [modalKeyEditProfile, setModalKeyEditProfile] = useState(Date.now() + 3);
  const [modalKeyEpaAdd, setModalKeyEpaAdd] = useState(Date.now() + 4);
  
  
  const [isAlertBoxShowing, setIsAlertBoxShowing] = useState(false);
  const [alertBoxMessage, setAlertBoxMessage] = useState("");
  const [alertBoxTitle, setAlertBoxTitle] = useState("");

  const [displayContentsForProgramId, setDisplayContentsForProgramId] = useState("");

  const alertBoxRef = useRef();
  
  // const profilePanelRef = useRef();

  const { setToken, removeToken, getAuthHeader, screenForFreshToken, isTokenAvailable, getToken } = useTokens();
  

  const [epaInFocus, setEpaInFocus] = useState({});

  useEffect(() => {
    const apiLoadCheckTimer = setTimeout(() => !nonAuthApiLoadStatusRef.current ? showAlertBox("Network Problem","The page did not load properly likely due to a network issue. Please refresh the page.","danger") : null, 3500);
    return () => clearTimeout(apiLoadCheckTimer);
  }, []);

  useEffect(()=>{
    // Resurrect, in case there are any valid tokens (i.e. auto-logins)
    resurrect();
    setSpinnerShow(false);
    const options = {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    }
    Promise.allSettled([
      fetch("/api/get-categories", options),// fetch("https://json.extendsclass.com/bin/86113f7a3dde", options),
      // fetch("/api/get-evaluations", options),// fetch("https://json.extendsclass.com/bin/81fed848e129", options),
    fetch("/api/get-programs", options),// fetch("https://json.extendsclass.com/bin/4c660c9216a1", options),
    fetch("/api/get-global-epas", options),
    fetch("/api/get-global-stats", options),
    fetch("/api/get-rotations", options)// fetch("https://json.extendsclass.com/bin/0cfbaeb98293", options)
    ]).then(([cats,programs,globalEpas,globalStats,rotations]) => {
      // debugger;
        Promise.allSettled([
            cats.value.json(),
            // evals.value.status == 200 ? evals.value.json() : [],
            programs.value.json(),
            globalEpas.value.json(),
            globalStats.value.json(),
            rotations.value.json()
        ]).then(([cats,programs,globalEpas,globalStats,rotations])=>{
          // debugger;
          // setEvaluationsData(evals.value)
          let allLoaded = true;
          if (
            !cats.hasOwnProperty("value") || cats.status!="fulfilled" ||
            !programs.hasOwnProperty("value") || programs.status!="fulfilled" ||
            !globalEpas.hasOwnProperty("value") || globalEpas.status!="fulfilled" ||
            !globalStats.hasOwnProperty("value") || globalStats.status!="fulfilled" ||
            !rotations.hasOwnProperty("value") || rotations.status!="fulfilled"
              ) {
                allLoaded = false;
              }
            setCategoriesData(cats.value);
            setProgramsData(programs.value);
            setGlobalEpasData(globalEpas.value);
            setGlobalStatsData(globalStats.value);
            setRotationsData(rotations.value);
            allLoaded && setNonAuthApiLoaded(true);
        });
    });
    // Check if any special instructions
    const queryParameters = new URLSearchParams(window.location.search);
    const paramOpen = queryParameters.get("open");
    if (paramOpen) {
      switch(paramOpen.toLowerCase()) {
        case "demo":
          //document.getElementById("demo")
          var guideModal = new Modal('#guideModal');
          guideModal.show();
      }
      
    }


}, []);


function handleDisplayProgramContentsSelection(id) {
  // if (e !== undefined && e!== null && typeof e.preventDefault==="function"){
  //   e.preventDefault();
  // }
  // console.log("Setting id " + id);
  setDisplayContentsForProgramId(id);
}
useEffect(()=>{
  if (displayContentsForProgramId) {
    scrollElementIntoView({id:("epaTableContainer-heading-" + displayContentsForProgramId)});
    // handleDisplayProgramContentsSelection(null);
  }
},[displayContentsForProgramId]);

function reachedProgressBarsWaypoint(cl) {
  const progressBars = Array.from(
      document.getElementsByClassName(cl)
  );
  progressBars.forEach(el => {
      el.style.width=el.ariaValueNow+"%";
  });
}


  function registrationCompleted(result) {
    login(result);
  }

  function closeProfilePanel() { setProfilePanelShow(false); }
  function openProfilePanel() { setProfilePanelShow(true); }
  function loadMyProfile() {
    if(profilePanelShow) { closeProfilePanel(); } else { openProfilePanel(); }
  }

  function resurrect(silent=false) {
    // Check if token available
    if (isTokenAvailable()) {
      // console.log("Token available. Attempt to resurrect...");
      // callApi({endPoint: "/api/auth/resurrect", actionableSuccClb: (p)=>login({...p,token:getToken()})});
      callApi({endPoint: "/api/auth/resurrect", actionableSuccClb: (props)=>{login(props,silent)}});
    } else {
      // console.log("No token available. No point in resurrection.");
    }
  }

  function callApi({endPoint, body=null, actionableSuccClb=null, succClb=null, actionableFailClb=null, failClb=null, hdrs=null, meth=null, withAuthHd=true, refrToken=true, catchClb=null, finallyClb=null}) {
    if (!endPoint) { throw("Invalid call params"); }
    let headers = hdrs || {"Accept": "application/json","Content-Type": "application/json"};
    let method = meth || "GET";
    // GET cannot have a body in the request
    if (method == "GET" && body != null) {
      throw "GET cannot have a body"
    }
    let options = (body) ? {method:method,headers: headers, body: body} : {method:method,headers: headers}
      fetch(endPoint, withAuthHd ? getAuthHeader(options) : options).then((res)=>{
        if (res.status !== 200) {
          try {
          res.json().then(result=>{
            // debugger;
            actionableFailClb && actionableFailClb(result);
            failClb && failClb();
          }) 
          .catch ((er)=>{console.log("Promise catch"); catchClb && catchClb(er);})
        } catch(er) { catchClb && catchClb(er);} finally {finallyClb && finallyClb();}
        } else {
          try {
            res.json().then(
              result=>{
                if (refrToken) { screenForFreshToken(result); }
                actionableSuccClb && actionableSuccClb(result);
                succClb && succClb();
          })} catch(er) { catchClb && catchClb(er);} finally {finallyClb && finallyClb();}}})
  }

  function login(props, silent=false) {
    // props should include:
    //  token:    JWT access token
    //  userInfo: non-sensitive user info (eg first/last name, email, isActive, etc)
    // Login may be called from methods with valid token already (eg successful 'resurrection') - so if 'token' is not in the props, don't corrupt it.
    // No token being available does not mean login is unsuccessful! It means no fresh token was sent from server.
    // Attempt to get user's evaluations via API call once token has been set up (for headers)
    if (props.token) {
      setToken(props.token,()=>loadAuthApis({loadAll:true}));
    } else {
      loadAuthApis({loadAll:true});
    }
    setUserInfo(props.userInfo)
    setIsLoggedIn(true);
    if (!silent) {showAlertBox("Hi " + props.userInfo.firstName,"You were logged in successfully!");}
    
  }
  

  // function reloadUserProfile() {
  //   callApi({endPoint: "/api/get-evaluations",
  //         actionableSuccClb: (r)=>{setAuthApiLoaded(true); setEvaluationsData(r); },
  //         actionableFailClb: (er)=>{setAuthApiLoaded(true); showAlertBox("Could not load your data",er); },
  //         catchClb: (er)=>showAlertBox("Error fetching evaluations",er)
  //   });
  // }

  function loadAuthApis({loadEpas=false,loadEvals=false,loadAll=false}) {

    (loadEpas || loadAll) && callApi({endPoint: "/api/get-user-epas",
          actionableSuccClb: (r)=>{setAuthApiLoaded(true); setEpasData(r); },
          actionableFailClb: (er)=>{setAuthApiLoaded(true); showAlertBox("Could not load your data",er); },
          catchClb: (er)=>showAlertBox("Error fetching EPAs",er)
    });

    (loadEvals || loadAll) &&callApi({endPoint: "/api/get-evaluations",
          actionableSuccClb: (r)=>{setAuthApiLoaded(true); setEvaluationsData(r); },
          actionableFailClb: (er)=>{setAuthApiLoaded(true); showAlertBox("Could not load your data",er); },
          catchClb: (er)=>showAlertBox("Error fetching evaluations",er)
    });

  }

  function getEvaluationsForCurrentUser() {
    loadAuthApis({loadEvals:true,loadAll:false})
  }

  // Ensure all data loads before rendering data sensitive info.
  useEffect(()=>{
    if (nonAuthApiLoaded && authApiLoaded) { setAllApiLoaded(true); }
    nonAuthApiLoadStatusRef.current = nonAuthApiLoaded;
  },[nonAuthApiLoaded, authApiLoaded]);

  // Initiating processes that have been dormant, waiting for all APIs to load
  // useEffect(()=>{
  //   if (allApiLoaded !== true) { return; }
  //   console.log("initiate dormant processes");
    
  // },[allApiLoaded]);

  function endAlertBox() {
    if (!alertBoxRef.current.classList.contains("show")) {
      setIsAlertBoxShowing(false);
    }
    alertBoxRef.current.classList.remove("show");
  }

  function showAlertBox(title,msg, mode=null) {
    if (typeof title !== "string") { title = title.toString(); }
    if (typeof msg !== "string") { 
      if (typeof msg === "object") {
        try {
          let k = Object.keys(msg)[0];
          msg = msg[k];
        } catch { msg = msg.toString(); }
      } else { msg = msg.toString(); }
    }
    alertBoxRef.current.classList.remove("mode-danger");
    setAlertBoxTitle(title);
    setAlertBoxMessage(msg);
    if (isAlertBoxShowing) { 
      // Already showing. Don't mess with animations.
      // Let it finish natrually, even if the notification stays for a shrot period.
      // This is a bug that will need fixing.
      return; }
    setIsAlertBoxShowing(true);
    if (mode && mode.toLowerCase()=="danger") {
      alertBoxRef.current.classList.add("mode-danger");
    }
    alertBoxRef.current.classList.add("show");
  }



  function logout(props) {
    callApi({endPoint: "/api/auth/logout", succClb: ()=>showAlertBox("Logged Out","You were logged out successfully!"), failClb: ()=>showAlertBox("Logout Problem","There was an issue with logging you out.")});
    closeProfilePanel();
    removeToken();
    setUserInfo(null);
    setIsLoggedIn(false);
  }




function scrollElementIntoView({el=null, id=null, postpone=null}) {
  if (el === null && id === null) { return; }
  if (el === null) {
    el = document.getElementById(id);
  }
  if (el === null) { return; }
  if (postpone) {
    setTimeout(()=>{el.scrollIntoView({"block":"start", "behavior":"smooth"});},postpone);
  } else {
    el.scrollIntoView({"block":"start", "behavior":"smooth"});
  }
}



function setEpaPageMode({mode,prId,epaId, ev=null}) {
  // setModalKeyEpaAdd(Date.now() + 4); - no because it changes after render, meaining it is always lagging behind 1 step. Can't be done here.
  // This acts as an interface between the properties of the object (currently dictated by the server)
  // and the EPA update module formData properties - some names don't currently match.
  if (ev==null) {
    setEpaInFocus({mode: mode, program_id: prId, epa_id: epaId});
  } else {
    setEpaInFocus({mode: mode, program_id: prId, epa_id: epaId,
      eval_id: ev["id"],
      reminder_evaluator_autosend: ev["reminder_evaluator_autosend"],
      evaluator_email: ev["evaluator_email"],
      evaluator_name: ev["evaluator_name"],
      evaluator_role: ev["evaluator_role"],
      rotation_id: ev["rotation_id"],
      reminder_evaluator_frequency: ev["reminder_evaluator_frequency"],
      reminder_self_autosend: ev["reminder_self_autosend"],
      reminder_self_frequency: ev["reminder_self_frequency"],
      note_to_evaluator: ev["note_to_evaluator"],
      status: ev["status"],
      date_assessment: ev["date_assessment"],
      date_sent: ev["date_sent"],
      evaluation_resdate_resultedulted_date: ev["date_resulted"],
      date_due_by: ev["date_due_by"]
    });
  }
}


  /// STAT CALCULATIONS

  function wordifyStatus(st) {
    let cl = "";
    let val = "";
    let lbl = "";
    switch ((st === undefined ? "" : st.toLowerCase())) {
        case "sent":
          cl = "text-info"; lbl = "pending"; val = st.toLowerCase(); break;
        case "unsent":
          cl = "text-warning"; lbl = "not sent"; val = st.toLowerCase(); break;
        case "completed":
          cl = "text-success"; lbl = st.toLowerCase(); val = st.toLowerCase(); break;
        case "expired":
        case "deleted":
        case "failed":
          cl = "text-danger";
        default:
          lbl = st.toLowerCase(); val = st.toLowerCase();
    }
    return {class: cl, label: lbl, value: val};
}


  function getEpaShortStats({forEpaId,forUserId,required=true,unsent,completed=true,sent,resulted}) {
    let res = getEpaShortStatNumbers(forEpaId, forUserId);
    let text = "";
    text +=
    (required ? "required: " + res.required + ", ":"") + 
    (completed ?  wordifyStatus(EVAL_STATUS.COMPLETED).label + ": " + res.completed + ", ":"") +
    (sent ?  wordifyStatus(EVAL_STATUS.SENT).label + ": " + res.sent + ", ":"") + 
    (resulted ?  wordifyStatus("resulted").label + ": " + res.resulted + ", ":"") +
    (unsent ?  wordifyStatus(EVAL_STATUS.UNSENT).label + ": " + res.unsent + ", ":"");
    text = text.trim();
    if (text.length > 0) {
      while (text.charAt(0)===",") {
        text = text.substring(1);
      }
    }
    if (text.length > 0) {
      while (text.charAt(text.length-1)===",") {
        text = text.slice(0,-1);
      }
    }
    if (text.length > 0) { text = "(" + text + ")" }
    return {text:text,results:res};
}
function getEpaShortStatNumbers(forEpaId, forUserId) {
    if (forEpaId === null || forEpaId === "" || forUserId === null || forUserId === "" ) { return null; }
    const _userInfo = userInfo;
    if (_userInfo === null || !_userInfo.hasOwnProperty("id" || _userInfo.id === null || _userInfo.id === "")) { throw "No user info found"; }
    let epa = epasData.filter((p)=>p.id===forEpaId)
    if (epa === null || epa.length < 1) { throw "No EPA data found"; }
    epa = epa[0];
    // owner_id should already be user's, but just in case.
    let current_evals = evaluationsData.filter((ev)=>ev.epa_id===forEpaId && ev.owner_id===forUserId);
    let quantities = {completed: 0, sent: 0, notCompletedButResulted: 0, unsent: 0,required: epa.quantity_required};
    if(current_evals !== null && current_evals.length > 0) {
        let completed = current_evals.filter((ev)=>ev.status==EVAL_STATUS.COMPLETED);
        quantities.completed = (completed === null || completed.length<1) ? 0 : completed.length;
        let sent = current_evals.filter((ev)=>ev.status==EVAL_STATUS.SENT);
        quantities.sent = (sent === null || sent.length<1) ? 0 : sent.length;
        let resulted = current_evals.filter((ev)=>ev.status==EVAL_STATUS.FAILED || ev.status==EVAL_STATUS.EXPIRED || ev.status==EVAL_STATUS.DELETED );
        quantities.notCompletedButResulted = (resulted === null || resulted.length<1) ? 0 : resulted.length;
        let unsent = current_evals.filter((ev)=>ev.status==EVAL_STATUS.UNSENT);
        quantities.unsent = (unsent === null || unsent.length<1) ? 0 : unsent.length;
    } else {
        // No evaluations found for this EPA
    }
    return quantities;
}
 

  function getProgramsInfo() {
    // This returns programs info from programsData
    let homeProgram = null;
    let tempHomeProgram = (userInfo.homeProgram != undefined && userInfo.homeProgram != null && userInfo.homeProgram.length >0? programsData.filter((p)=>p.id===userInfo.homeProgram.map(e=>e.id)[0]) : []);
    if (tempHomeProgram !== null && tempHomeProgram.length > 0) { homeProgram = [tempHomeProgram[0]]; }
    // let hasOtherPrograms = !(!userInfo.hasOwnProperty("additionalProgramIds") || userInfo.additionalProgramIds === null || userInfo.additionalProgramIds.length < 1);
    let hasOtherPrograms = !(!userInfo.hasOwnProperty("additionalPrograms") || userInfo.additionalProgramIds === null || userInfo.additionalProgramIds.length < 1);
    let programIds = hasOtherPrograms ? userInfo.additionalPrograms.map(e=>e.id) : []
    // Ensure entity is an array (which in most cases shouldn't be, as typically there is only one additional program one should be registered in)
    if (!Array.isArray(programIds)) { programIds = [programIds]; }
    let otherProgramInfos = [];
    programIds.forEach(p => {
        let tempProgram = programsData.filter((pr)=>pr.id===p);
        if (tempHomeProgram !== null && tempProgram.length > 0) { otherProgramInfos.push(tempProgram[0]); }
    });
    // console.log({homeProgram: homeProgram, otherPrograms: otherProgramInfos});
    
    return {homeProgram: homeProgram, otherPrograms: otherProgramInfos}
}

function userSubscribedEpas({forStage=null,forProgramId=null}) {
  // Returns list of all subscribed EPAs that have already been filtered and checked against version subscriptions.

  let result = [];
  // Get all of user's subscribed programs programsData info (i.e. generic info about the program)
  let userPrograms = [];
  let userProgramsInfo = getProgramsInfo();
  userPrograms.push(...userProgramsInfo.homeProgram);
  userPrograms.push(...userProgramsInfo.otherPrograms);
  if (forProgramId!=null){
    userPrograms = userPrograms.filter(p=>p.id==forProgramId);
  }

  // Now get user's subscribed programs info (e.g. details of segments/categories subscriptions)
  let userSubscriptions = [...userInfo.homeProgram];
    userSubscriptions.push(userInfo.additionalPrograms);
    userSubscriptions = userSubscriptions.flat();
    userPrograms.map(p=>{
      let epas = epasData.filter((e=>e.program_id===p.id && userSubscriptions.filter(u=>u.id===p.id)[0].segments[categoriesData.filter(c=>c.id==e.category_id)[0].short_name].includes(e.version)));
      if (epas !== undefined && epas !== null && epas.length > 0) {
        result = result.concat(epas);
      }
    });
    
    if (forStage !== null) {
      let catId = categoriesData.filter(c=>c.short_name.toLowerCase()===forStage)[0]; 
      catId = (catId === undefined || catId === null)? "" : catId.id;
      let filteredResult = result.filter(r=>r.category_id===catId);
      return filteredResult;
  }
    
    return result;
}

/**
 * 
 * @param forStage        - filters down to EPAs in the set stage (aka category) only. Defaults null, meaning all.
 * @param forProgramId    - filters down to EPAs in this program only. Defaults null, meaning all.
 * @param useGlobal       - if false, uses current logged-in user's info (default). If true, uses any registered users' info (active or inactive). Still applies the other filters.
 * @returns 
 */
function breakdownStats({forStage=null,forProgramId=null}) {
    let result = [];
    let epas = userSubscribedEpas({forStage:forStage,forProgramId:forProgramId});
    // if (useGlobal) {
    //   epas = JSON.parse(JSON.stringify(globalEpasData));
    //   if (forProgramId!=null) {
    //     epas = epas.filter(e=>e.program_id==forProgramId);
    //   }
    //   if (forStage !== null) {
    //     let catId = categoriesData.filter(c=>c.short_name.toLowerCase()===forStage)[0]; 
    //     catId = (catId === undefined || catId === null)? "" : catId.id;
    //     epas = epas.filter(e=>e.category_id===catId);
    //   }
    // } else {
      // epas = userSubscribedEpas({forStage:forStage,forProgramId:forProgramId});
    // }
        
    if (epas !== undefined && epas !== null && epas.length > 0) {
      let countOfDistinctEpas = epas.length;
        epas.map(ep => {
        let evals = evaluationsData.filter(ev=>ev.epa_id===ep.id);
        let countCompleted = 0;
        let countSent = 0;
        evals.map(ev=>countCompleted = countCompleted + (ev.status==="completed" ? 1 : 0))
        // Ensure we don't count count completed more than the necessary numbers of required EPAs.
        // Eg if someone has 3 completed evals filled for an EPA that only needs one, count only one as completed otherwise this will mess up stats.
        countCompleted = (countCompleted > ep.quantity_required) ? ep.quantity_required : countCompleted;
        evals.map(ev=>countSent = countSent + (ev.status==="sent" ? 1 : 0))
        result.push({
            quantity_required: ep.quantity_required,
            category_id: ep.category_id,
            categoryShortName: categoriesData.filter(c=>c.id===ep.category_id)[0].short_name,
            name: ep.name,
            epa_id: ep.id,
            program_id: ep.program_id,
            count_completed: countCompleted,
            count_sent: countSent,
            countOfDistinctEpas: countOfDistinctEpas
        })
      })
    }
    // });

    // if (forStage !== null) {
    //     let catId = categoriesData.filter(c=>c.short_name.toLowerCase()===forStage)[0]; 
    //     catId = (catId === undefined || catId === null)? "" : catId.id;
    //     let filteredResult = result.filter(r=>r.category_id===catId);
    //     return filteredResult;
    // }
    // console.log(result);
    return result;
}

function allBreakdowns({forStage=null,forProgramId=null, forceReset=false}){
    // if (breakdowns === null || forceReset) {
    //     setBreakdowns(breakdownStats(forStage,forProgramId));
    // }
    let result = breakdownStats({forStage:forStage,forProgramId:forProgramId});
    let sumCompleted = result.reduce((sum,x)=>sum+x.count_completed,0);
    let sumSent = result.reduce((sum,x)=>sum+x.count_sent,0);
    let sumRequired = result.reduce((sum,x)=>sum+x.quantity_required,0);
    let percentTotalCompleted = sumRequired === 0 ? 0 : Math.ceil(((sumCompleted / sumRequired) * 100));
    let countOfDistinctEpas = result.filter(e=>e.hasOwnProperty("countOfDistinctEpas")).length>0?result.filter(e=>e.hasOwnProperty("countOfDistinctEpas"))[0]["countOfDistinctEpas"] : null;
    return {
        sumCompleted:sumCompleted,
        sumRequired: sumRequired,
        sumSent, sumSent,
        percentTotalCompleted: percentTotalCompleted,
        countOfDistinctEpas: countOfDistinctEpas
    }
}


function makeRandomNum() {
  return Math.random() * 200000 + Math.random() * 1000 + Math.random() * 10000;
}


function generateImageWithDefaultPath({elId, orientation, forceRenew=false, forType=null}) {
  // Internal fn to look for special
  const findSpecial = (term) => {
    if (forType === undefined || forType == null || term === undefined || term == null) { return false; }
      return (forType.toLowerCase().includes(term.toLowerCase()));
  }

  let shouldContinue = true;
  let useRandom = true;
  let ext = ".jpg";
  if (imgLib.hasOwnProperty(elId)) {
    if (forceRenew) { 
      shouldContinue = true;
     } else { 
      return imgLib[elId]; 
    }
  }

  let base = "horizontal-";
  
  let min = 1, max = 23;
  if (orientation == "vertical") {
    base = "vertical-"
    max = 6;
  }
  // debugger;
  if (forType != null) {
    // Special cases - override.
    switch (true) {
      case findSpecial("registration"):
        base = "registration-"; min = 2; max = 3; break;
      case findSpecial("ortho"):
        base = "horizontal-ortho"; useRandom = false; break;
      case findSpecial("plastic"):
        base = "horizontal-plastics"; useRandom = false; break;  
      case findSpecial("surgical foundations"):
        // base = "horizontal-19";
        base = "horizontal-sf";useRandom = false; break;
      case findSpecial("vascular"):
        base = "horizontal-vascular"; useRandom = false; break;
      case findSpecial("cardio"):
        base = "horizontal-cardiology"; useRandom = false; break;
      case findSpecial("radiology"):
        base = "horizontal-radiology"; useRandom = false; break;
    }
  }
  let rand = Math.floor(Math.random() * (max - min + 1) + min);
  let img = "/img/" + base + (useRandom ? rand : "") + ext;
  setImageLib(current=>({...current,[elId]:img}));
  return img;
}



const institutesData = {namesList:[
  { value: 'University of Alberta', label: 'University of Alberta' },
  { value: 'University of Calgary', label: 'University of Calgary' },
  { value: 'University of British Columbia', label: 'University of British Columbia' },
  { value: 'University of Manitoba', label: 'University of Manitoba' },
  { value: 'Memorial University of Newfoundland', label: 'Memorial University of Newfoundland' },
  { value: 'Dalhousie University', label: 'Dalhousie University' },
  { value: 'McMaster University', label: 'McMaster University' },
  { value: 'NOSM University', label: 'NOSM University' },
  { value: "Queen's University", label: "Queen's University" },
  { value: 'Western University', label: 'Western University' },
  { value: 'University of Ottawa', label: 'University of Ottawa' },
  { value: 'University of Toronto', label: 'University of Toronto' },
  { value: 'Université Laval', label: 'Université Laval' },
  { value: 'McGill University', label: 'McGill University' },
  { value: 'Université de Montréal', label: 'Université de Montréal' },
  { value: 'Université de Sherbrooke', label: 'Université de Sherbrooke' },
  { value: 'University of Saskatchewan', label: 'University of Saskatchewan' }
]}





  return (
    <div className="App">






      {/* <div onClick={()=>{
        callApi({endPoint: "/api/get-evaluations",
        finallyClb: ()=>setSpinnerShow(false),
        actionableFailClb: (er)=>{showAlertBox("Failed fetching evaluations",er)},
         catchClb: (er)=>showAlertBox("Error fetching evaluations",er)
      });
        // showAlertBox("Test","OK");
        }} className="btn btn-primary mb-5" style={{position:'fixed', top:'900px', left:'0px', zIndex:'99'}}>TEST ME</div> */}

{/* <button className="btn btn-primary btn-lg" onClick={(e)=>adminPopulateData(e)}>Populate Dummy Data</button> */}



    { isLoggedIn && allApiLoaded && <Profile shouldShowPanel={profilePanelShow} closePanel={closeProfilePanel}
      allBreakdowns={allBreakdowns} getProgramsInfo={getProgramsInfo}
      userInfo={userInfo} isLoggedIn={isLoggedIn} evaluationsData={evaluationsData} categoriesData={categoriesData}
      programsData={programsData} epasData={epasData} rotationsData={rotationsData}
      setEpaPageMode={setEpaPageMode} handleDisplayProgramContentsSelection={handleDisplayProgramContentsSelection}
    ></Profile>}
      
      {isLoggedIn && allApiLoaded && <AddEPA setSpinnerShow={setSpinnerShow} isLoggedIn={isLoggedIn}
        evaluationsData={evaluationsData} categoriesData={categoriesData}
        programsData={programsData} epasData={epasData} rotationsData={rotationsData}
        userInfo={userInfo} callApi={callApi} showAlertBox={showAlertBox}
        getProgramsInfo={getProgramsInfo}
        updateEvaluationsForCurrentUser={getEvaluationsForCurrentUser}
        epaInFocus={epaInFocus} setEpaPageMode={setEpaPageMode} getEpaShortStats={getEpaShortStats} 
        DATE_CONST_NAMES={DATE_CONST_NAMES} EVAL_STATUS={EVAL_STATUS} wordifyStatus={wordifyStatus}
        getEpaShortStatNumbers={getEpaShortStatNumbers}
        key={modalKeyEpaAdd} makeRandomNum={makeRandomNum}
        reset={()=> {setModalKeyEpaAdd(Date.now()); setEpaPageMode({mode: null, prId: null, epaId: null});}} 
        userSubscribedEpas={userSubscribedEpas}
      />}

      <NavBar setSpinnerShow={setSpinnerShow} isLoggedIn={isLoggedIn} logout={logout} myProfileAction={loadMyProfile} nonAuthApiLoaded={nonAuthApiLoaded} />
      <Carousel isLoggedIn={isLoggedIn} myProfileAction={loadMyProfile} nonAuthApiLoaded={nonAuthApiLoaded} />
      

      {isLoggedIn && allApiLoaded ? <EPAsByProgram
        userInfo={userInfo} isLoggedIn={isLoggedIn} evaluationsData={evaluationsData} categoriesData={categoriesData}
        programsData={programsData} epasData={epasData} rotationsData={rotationsData} 
        reachedProgressBarsWaypoint={reachedProgressBarsWaypoint}
        allBreakdowns={allBreakdowns} getProgramsInfo={getProgramsInfo}
        scrollElementIntoView={scrollElementIntoView} getEpaShortStats={getEpaShortStats} wordifyStatus={wordifyStatus}
        setEpaPageMode={setEpaPageMode} userSubscribedEpas={userSubscribedEpas}
        makeImg={generateImageWithDefaultPath} displayContentsForProgramId={displayContentsForProgramId} setDisplayContentsForProgramId={setDisplayContentsForProgramId}
        handleDisplayProgramContentsSelection={handleDisplayProgramContentsSelection}
      /> : isLoggedIn ? <LoadingInline /> : null}
      



      {globalStatsData && globalEpasData.length>0 
        ?
        <GlobalStats
          reachedProgressBarsWaypoint={reachedProgressBarsWaypoint} globalStatsData={globalStatsData}/>
        :
        <LoadingInline />
      }


      


      {nonAuthApiLoaded && <Register setSpinnerShow={setSpinnerShow} isLoggedIn={isLoggedIn} onRegistrationCompletion={registrationCompleted} 
          categoriesData={categoriesData} programsData={programsData} 
          key={modalKeyRegistration} reset={()=>setModalKeyRegistration(Date.now())}
          makeImg={generateImageWithDefaultPath} institutesData={institutesData} />}
      
      {nonAuthApiLoaded && <Login setSpinnerShow={setSpinnerShow} isLoggedIn={isLoggedIn} 
          login={login} callApi={callApi}
          key={modalKeyLogin} reset={()=>setModalKeyLogin(Date.now())}
          makeImg={generateImageWithDefaultPath} />}

      {isLoggedIn && allApiLoaded && 
        <EditProfile setSpinnerShow={setSpinnerShow} isLoggedIn={isLoggedIn}  
          categoriesData={categoriesData} programsData={programsData} epasData={epasData}
           reset={()=>setModalKeyEditProfile(Date.now())} institutesData={institutesData}
           key={modalKeyEditProfile} userInfo={userInfo}
           callApi={callApi} showAlertBox={showAlertBox} reloadProfile={resurrect} makeRandomNum={makeRandomNum}
           />
      }
      
      

      <AboutUs />

      <Guide />





      {/* <div className={`alert-box mt-5${alertBoxShow ? " show" : ""}`} ref={alertBoxRef} onTransitionEnd={()=>{endAlertBox();}} > */}
      <div className="alert-box mt-5" ref={alertBoxRef} onTransitionEnd={()=>{endAlertBox();}} >
        <div className="alert-box-inner-container p-4">
        <div className="alert-box-header p-2">
          <strong className="me-auto">{alertBoxTitle}</strong>
        </div>
        <div className="alert-box-body p-4">
          {Array.isArray(alertBoxMessage) ? alertBoxMessage.reduce((el,a) => el.concat(a, <br />),[]) : alertBoxMessage}
        </div>
        </div>
      </div>
      <div className={`${spinnerShow ? 'show ' : ''}spinner-bg position-fixed translate-middle w-100 vh-100 d-flex top-50 start-50 align-items-center justify-content-center`}>
        <h1 className="spinner-text" role="status">Loading</h1>
      </div>
    </div>
  );



  function adminPopulateData() {
    // const rotationDataToMake = [
    //   {name: "Neurosurgery"},{name: "Gen Sx"},{name: "ICU"},{name: "CCU"},{name: "ER"},{name: "Ortho"},{name: "Ophtho"},{name: "Vascular Sx"},{name: "Plastics"},{name: "Peds Sx"},{name: "IM"},{name: "OMF Surgery"},{name: "Urology"},{name: "Anesthesia"},{name: "ER"},{name: "Cardiac Sx"},{name: "Cardiology"},{name: "ObsGyn"},{name: "Burn Unit"},{name: "Microvascular"},{name: "Research"}
    // ]
    // rotationDataToMake.forEach((i,ind,arr) => {
    //   callApi({endPoint: "/api/admin/rotation-create", meth: "POST", body: JSON.stringify(i), succClb: ()=>console.log("Created " + i + " successfully."), failClb: ()=>console.log("Couldn't create: " + i)});
    // });


    const epaDataToMake = [
      // {code:"1.1", name:"Performing the preoperative preparation of patients for basic surgical procedures", short_name:"Pre-op preparation", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", restrictions: "Case discussion and/or review of documentation by staff, fellow or senior resident", program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", quantity_required: 1},
      // {code:"1.2", name:"Recognizing and initiating early management for critically ill surgical patients", short_name:"Management of critically ill surtical pt", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", restrictions: "Direct observation by staff, fellow or senior resident - clinical or simulated. At least one clinical. At least 2 different assessors.", program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", quantity_required: 2},
      // {code:"1.3", name:"Documenting clinical encounters", short_name:"Documenting clinical encounters", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", restrictions: "Direct observation or case discussion.", program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", quantity_required: 2},
      // {code:"1.4", name:"Demonstrating handover technique", short_name:"Handover", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", restrictions: "Direct observation by staff, fellow or senior resident - clinical or simulated. At least one clinical. At least 2 different assessors.", program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", quantity_required: 2},
      // {code:"1.5", name:"Demonstrating ability to function in the operating room", short_name:"Functioning in OR", restrictions: "Direct observation by staff, fellow or senior resident - clinical or simulated.",quantity_required: 1, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"1.6", name:"Suturing incisions", short_name:"Suturing incisions", restrictions: "Direct observation by staff, fellow or senior resident - clinical or simulated. At least one clinical. At least 2 different assessors. At least one wound > 5cm.",quantity_required: 2, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"1.7", name:"Managing tubes, drains and central lines", short_name:"Tubes, drains, central lines", restrictions: "Direct observation by staff, fellow or senior resident - clinical or simulated. At least one clinical. At least 2 different assessors. 2 different types of tubes.",quantity_required: 2, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.1", name:"Providing initial management for critically ill patients", short_name:"Initial management for critially ill pt", restrictions: "Direct observation by staff, fellow or senior resident - simulation or ward, ER, ICU. At least two clinical. At least two different presentations. At least 2 different assessors.",quantity_required: 3, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.2", name:"Inserting central venous lines", short_name:"Inserting central venous lines", restrictions: "Direct observation by staff, fellow or senior resident - clinical or simulated. At least one clinical. At least 2 different assessors.",quantity_required: 2, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.3", name:"Providing initial management for trauma patients", short_name:"Initial management for trauma pt", restrictions: "Direct observation by trauma team leader (TTL), fellow or senior resident with trauma experience - clinical or simulated. At least 1 primary survery and 1 secondary survey.",quantity_required: 2, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.4", name:"Providing risk assessment and management for preoperative patients in preparation for surgery", short_name:"Pre-op risk assessment and management", restrictions: "Direct or indirect observation by staff, fellow or senior resident. At least 1 elective, 1 emergent, 1 high risk surgeries. At least 2 different assessors.",quantity_required: 4, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.5", name:"Providing patient education and informed consent in preparation for surgical care", short_name:"Informed consent", restrictions: "Direct observation by staff, fellow or senior resident. At least 1 elective, 1 emergency surgeries. At least 2 in clinical setting. At least 2 different assessors.",quantity_required: 3, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.6", name:"Participating in surgical procedures", short_name:"Participating in surgical procedures", restrictions: "Direct observation by staff, fellow or senior resident. At least 2 different types of procedures. At least 2 by staff surgeons. At least 2 different assessors.",quantity_required: 4, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.7A", name:"Managing patients with an uncomplicated post-operative course", short_name:"Uncomplicated post-op management", restrictions: "Direct observation or case discussion by staff, fellow or senior resident. At least 2 high complexity patients. At least 2 different assessors.",quantity_required: 4, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.7B", name:"Managing patients with an uncomplicated post-operative course (collaborative care)", short_name:"Uncomplicated post-op management (collaborative care)", restrictions: "Direct observation or case discussion by supervisor with input from clinical team members. At least 3 observers for each encounter. At least 2 different team member roles for each encounter.",quantity_required: 2, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.8", name:"Managing post-operative patients with complications", short_name:"Complicated post-op management", restrictions: "Case discussion by staff, fellow or senior resident. At least 4 different complications. At least 3 different assessors.",quantity_required: 8, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."},
      // {code:"2.9", name:"Supervising junior learners in the clinical setting", short_name:"Supervising juniors", restrictions: "Direct observation by staff, fellow, senior resident or by junior learners (i.e. medical students). At least 2 different supervisors.",quantity_required: 3, program_id: "d970bcee-5eee-11ed-a813-da673b631adc", version: "2021", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc", description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details."}
      // ,
      // PRS
      // {code:"1.1", name:"Assessing patients with a traumatic injury relevant to Plastic Surgery", short_name:"Assessing Plastics trauma pt", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation or case review by Plastics staff, fellow or senior resident",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "8f1bfa26-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.1", name:"Assessing and providing initial management for patients with skin and soft tissue infections and wounds", short_name:"Initial management of skin/soft tissue infections and wounds", quantity_required: 3, version: "2020 (V 1.0) ", restrictions: "Direct observation or case review by Plastics staff, fellow or TTP/core resident. At least 1 of each: incision and drainage ob abscess; wound debridment; multilayer closure.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.2A", name:"Assessing and providing initial management for patients with an acute hand injury (pt assesment)", short_name:"Initial management of acute hand injury (pt assesment)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation or case review by Plastics staff, fellow or TTP/core resident.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.2B", name:"Assessing and providing initial management for patients with an acute hand injury (procedure)", short_name:"Initial management of acute hand injury (procedure)", quantity_required: 5, version: "2020 (V 1.0) ", restrictions: "Direct or indirect observation or case review by Plastics staff, fellow or TTP/core resident - clinical or simulated. At least one of each procedure: local anesthesia; splinting; reduction; finger amputation/fingertip repair; extensor tendon repair. ",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.3", name:"Performing an initial assessment of patients with craniofacial trauma", short_name:"Initial assessment of craniofacial trauma", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation or case review by Plastics staff, fellow or TTP/core resident. No more than 1 nasal fracture.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.4", name:"Assessing and providing initial management for patients with burns", short_name:"Initial assessment and management of burns", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation and/or case review by Plastics staff, fellow or TTP/core resident. At least 1 minor, at least 1 major.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.5", name:"Integrating Plastic Surgery scientific literature into clinical practice", short_name:"Plastics literature in clinical practice", quantity_required: 3, version: "2020 (V 1.0) ", restrictions: "Direct observation by Plastics staff, fellow or TTP/core resident - journal club, divisional rounds, quality assurance rounds. At least 1 journal club, at least 1 divisional rounds.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.6", name:"Documenting clinical information", short_name:"Documenting clinical information", quantity_required: 5, version: "2020 (V 1.0) ", restrictions: "Review of clinical documentation by Plastics staff, fellow or TTP/core resident - inpatient, outpatient clinic, OR, procedure clinic, ER. At least 2 operative nores of different procedures; at least 1 discharge summary or transfer of care note; at least 2 consultations or admissions.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.7", name:"Closing abdominal incisions", short_name:"Closing abdominal incisions", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation by staff, fellow or TTP/core resident - clinical or simulated.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"2.8", name:"Assessing patients with breast cancer", short_name:"Assessing patients with breast cancer", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation and/or case review by staff, fellow or TTP/core resident.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9acbcc48-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.1A", name:"Managing patients with emergency conditions (pt assessment)", short_name:"Managing patients with emergency conditions (pt assessment)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation or case review by staff, fellow or TTP resident.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.1B", name:"Managing patients with emergency conditions (procedure)", short_name:"Managing patients with emergency conditions (procedure)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation by staff, fellow or TTP resident. At least 2 different clinical emergencies: active bleeding/acute hematoma; compartment syndrome; necrotizing infection; suppurative tendosynovitis; septic arthritis.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.2A", name:"Managing the Plastic Surgery inpatient service (overall pt care)", short_name:"Managing Plastic inpatient service (overall pt care)", quantity_required: 1, version: "2020 (V 1.0) ", restrictions: "Direct or indirect observation by Plastics staff.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.2B", name:"Managing the Plastic Surgery inpatient service (interprofessional)", short_name:"Managing Plastic inpatient service (interprofessional)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Input from at least 3 observers, including 1 staff and 1 junior learner.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.3", name:"Managing adverse events", short_name:"Managing adverse events", quantity_required: 1, version: "2020 (V 1.0) ", restrictions: "Direct observation by Plastics staff.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.4", name:"Performing reconstruction with local flaps", short_name:"Recon with local flaps", quantity_required: 4, version: "2020 (V 1.0) ", restrictions: "Direct or indirect observation by Plastics staff, fellow or TTP resident. Procedures: advancement; rotation; transposition; Z-plasty. At least 1 Z-plasty, at least 2 different flaps.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.5", name:"Performing reconstruction with pedicled flaps", short_name:"Recon with pedicled flaps", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation by Plastics staff. 2 different flaps.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.6A", name:"Performing reconstruction with free flaps (raising)", short_name:"Recon with free flaps (raising)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct or indirect observation by Plastics staff, fellow or TTP resident. 2 different flaps.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.6B", name:"Performing reconstruction with free flaps (recipient vessels prep)", short_name:"Recon with free flaps (recipient vessels prep)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct or indirect observation by Plastics staff, fellow or TTP resident. 2 different recipient sites: breast, upper extremity, lower extremity, head and neck, other site.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},
      // {code:"3.6C", name:"Performing reconstruction with free flaps (microvascular anastomoses, hand sutured)", short_name:"Recon with free flaps (microvascular anastomoses, hand sutured)", quantity_required: 2, version: "2020 (V 1.0) ", restrictions: "Direct observation by Plastics staff, fellow or TTP resident.",description:"Please see Royal College's website for details.",objectives: "Please see Royal College's website for details.", program_id: "6c3c4b60-5eef-11ed-b22b-da673b631adc", category_id: "9d21673c-5ef0-11ed-b22b-da673b631adc"},

    ]
    epaDataToMake.forEach((i,ind,arr) => {
      callApi({endPoint: "/api/admin/epa-create", meth: "POST", body: JSON.stringify(i), succClb: ()=>console.log("Created " + i + " successfully."), failClb: ()=>console.log("Couldn't create: " + i)});
    });
    
  }




}

export default App;

import {useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import Select from 'react-select';

export default function AddEPA(props) {

    const {epaInFocus, setEpaPageMode, EVAL_STATUS, DATE_CONST_NAMES,getEpaShortStatNumbers,getEpaShortStats, makeRandomNum,programsData,epasData,userSubscribedEpas,getProgramsInfo } = props;

// Great effect - but sadly too distracting for a busy form already
// function handleMouseMove(e, refObj) {
//     let windowWidth = window.innerWidth
//     let windowHeight = window.innerHeight;

//     let mouseXpercentage = Math.round(e.pageX / windowWidth * 100);
//     let mouseYpercentage = Math.round(e.pageY / windowHeight * 100);
//     refObj.current.style.background = 'radial-gradient(at ' + mouseXpercentage + '% ' + mouseYpercentage + '%, #3498db, #9b59b6)';
// }
// const modalContentRef = useRef();


  const epaVersionQuantityGroupLabel = (data) => (
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
      <span>{data.label}</span>
      <span className="badge rounded-pill bg-primary fw-bold">{data.options.length} EPA<span className="text-lowercase">{`${data.options.length>1?"s":""}`}</span></span>
    </div>
  );





const STEPS = { PROGRAM: "program", EPA:"EPA",EVALUATOR_NAME:"evaluator_name",EVALUATOR_ROLE:"evaluator_role",
    EVALUATOR_EMAIL:"evaluator_email", ROTATION:"rotation", REMINDER_EVALUATOR_AUTOSEND:"reminder_evaluator_autosend", REMINDER_EVALUATOR_FREQUENCY:"reminder_evaluator_frequency",
    REMINDER_SELF_AUTOSEND:"reminder_self_autosend", REMINDER_SELF_FREQUENCY:"reminder_self_frequency", NOTE_TO_EVALUATOR:"note_to_evaluator", STATUS:"status", DATE_ASSESSED:"date_assessed",
    DATE_SENT:"date_sent", DATE_RESULTED:"date_resulted", DATE_DUE:"date_due" }

const [formData, setFormData] = useState([]);
const [messagesTableau, setMessagesTableau] = useState("");
const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

const [epaOptions, setEpaOptions] = useState(null);
// isEpaSelected is used to determine if steps following EPA selection should be enabled or not
const [isEpaSelected, setIsEpaSelected] = useState(false);
const [epaStatus, setEpaStatus] = useState(EVAL_STATUS.NOTSET)

const [dateWarnings, setDateWarnings] = useState(null)

const [localKey, setLocalKey] = useState(Date.now() + 21);

function getElementDisabledStateForStatus(st, el) {
    // RETURN VALUE: true for disabled, null for enabled
    if (st === null || st === "") { return true; }
    switch (el) {
        case "EpaSentDate":
            return st == EVAL_STATUS.UNSENT ? true : null;
        case "EpaStatusDate":
            return (st == EVAL_STATUS.UNSENT || st == EVAL_STATUS.SENT) ? true : null;
        case "EpaDueDate":
            return (st == EVAL_STATUS.SENT) ? null : true;
        case "EpaEvaluationDate":
                return null;
        default:
            return true;
    }
}
// Send now should be available if:
// Sent date in the past (any date)
function getTextForEvaluationSendSchedule() {
    let sentDateStatus = isSentDateSetAndInPast();
    if (formData.hasOwnProperty("reminder_evaluator_autosend") && typeof formData["reminder_evaluator_autosend"] === "boolean" && formData["reminder_evaluator_autosend"] &&
    formData.hasOwnProperty("reminder_evaluator_frequency") && formData["reminder_evaluator_frequency"] && !isNaN(formData["reminder_evaluator_frequency"])
    && sentDateStatus.isSet

    ) {
        let text = "First reminder will be sent ";
        const nowDate = new Date((new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))).toDateString());
        let sendNow = (formData.hasOwnProperty("reminder_evaluator_send_now") && typeof formData["reminder_evaluator_send_now"] === "boolean" && formData["reminder_evaluator_send_now"] == true);
        
        let isSentDateToday = sentDateStatus.isSet && sentDateStatus.isSentDateToday;
        let freq = parseInt(formData["reminder_evaluator_frequency"]);
        let endDate = null;
        if (formData.hasOwnProperty(DATE_CONST_NAMES.DUE) && formData[DATE_CONST_NAMES.DUE]){
            endDate = new Date(new Date(formData[DATE_CONST_NAMES.DUE].getUTCFullYear(), formData[DATE_CONST_NAMES.DUE].getUTCMonth(), formData[DATE_CONST_NAMES.DUE].getUTCDate()).toDateString());
        }
        let sentDate = new Date(new Date(formData[DATE_CONST_NAMES.SENT].getUTCFullYear(), formData[DATE_CONST_NAMES.SENT].getUTCMonth(), formData[DATE_CONST_NAMES.SENT].getUTCDate()).toDateString());

        if (sentDateStatus.isInPast) {
            // Sent date is in past
            // But actually could still be today: - either because sendNow is checked; or - today falls on the interval.
            let nextSendDate = sentDate;
            while (nextSendDate.getTime()<nowDate.getTime() && nextSendDate.getTime()<endDate.getTime()) {
                nextSendDate = addDays(nextSendDate,freq);
            }
            // Check if nextSendDate is today, or if sendNow is checked
            if (sendNow || nextSendDate.getTime() == nowDate.getTime()) {
                // Next scheduled next date is today.
                text+= "today, and then ";
                // Now check if actual send interval date is today, or sendNow was clicked. This matters in calculating next scheduled date
                if (nextSendDate.getTime() == nowDate.getTime()) {
                    nextSendDate = addDays(nextSendDate,freq);
                    text+= "on " + nextSendDate.toLocaleDateString() + " and again every " + freq + " days until you disable sending reminders, or change the evaluation status";
                } else {
                    // So sendNow must have been clicked. In this case, previously calculated scheduled date must hold.
                    text+= "on " + nextSendDate.toLocaleDateString() + " and again every " + freq + " days until you disable sending reminders, or change the evaluation status";
                }

            } else {
                // Next scheduled next date is not today.
                text+= "on " + nextSendDate.toLocaleDateString() + " and again every " + freq + " days until you disable sending reminders, or change the evaluation status";
            }


        } else if (isSentDateToday) {
            // Sent date is today
            // No need to even check sendNow (for text visualisation purposes! Functionally, it might be past job run time, so that needs to be checked elsewhere)
            text+= "today, and then ";
            let nextSendDate = addDays(sentDate,freq);
            text+= "on " + nextSendDate.toLocaleDateString() + " and again every " + freq + " days until you disable sending reminders, or change the evaluation status";

        } else {
            // Sent date is in future
            // There would be no sendNow. So only calc future dates.
            
            let nextSendDate = sentDate;
            while (nextSendDate.getTime()<nowDate.getTime() && nextSendDate.getTime()<endDate.getTime()) {
                nextSendDate = addDays(nextSendDate,freq);
            }
            text+= "on " + nextSendDate.toLocaleDateString() + " and again every " + freq + " days until you disable sending reminders, or change the evaluation status";

        }
        
            
        if (endDate != null) {
            text+= ". This automatic sending of reminders will expire on " + endDate.toLocaleDateString();
        }
        return text;
    } else {
        return null;
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }
}


function getTextForSampleReminderEmail() {
    let note = "";
    let rotation = "";
    let epa = "";
    let assessmentDate = "";
    if (formData.hasOwnProperty("note_to_evaluator") && formData["note_to_evaluator"] && formData["note_to_evaluator"].length > 0) {
        note = <>
        {props.userInfo.firstName} has provided the following note for you to consider when filling out the evaluation:<br />
        ------------------------------------------------------------<br />
        <p style={{whiteSpace: "pre-wrap"}}>{formData["note_to_evaluator"]}</p>
        ------------------------------------------------------------
        <br /><br />
        </>;
    }

    if (formData.hasOwnProperty("rotation_id") && formData["rotation_id"] && formData["rotation_id"].length > 0 && props.rotationsData.filter(r=>r.id==formData["rotation_id"]).length>0) {
        rotation = `, who worked with you on ${props.rotationsData.filter(r=>r.id==formData["rotation_id"])[0].name} rotation.`;
    }
    
    if (isEpaSelected) {
        if (formData.hasOwnProperty(DATE_CONST_NAMES.ASSESSED) && formData[DATE_CONST_NAMES.ASSESSED] && formData[DATE_CONST_NAMES.ASSESSED] && new Date(formData[DATE_CONST_NAMES.ASSESSED]) instanceof Date){
            assessmentDate = ", which was evaluated on " + formData["assessment_date"]
        }
        epa = ` This reminder is about the ${props.categoriesData.filter((p)=>p.id==props.epasData.filter(p=>p.id==formData["epa_id"])[0].category_id)[0].name} EPA ${epaOptions.reduce((a,c)=>[...a,...c.options],[]).filter(e=>e.id===formData["epa_id"]).map(p=>p.code)}, ${props.epasData.filter(p=>p.id==formData["epa_id"])[0].name}${assessmentDate}.`
    }

    let text =  <p className="text-start">Dear {addPrefix()}{formData.hasOwnProperty("evaluator_name") ? formData["evaluator_name"] : "Evaluator"},<br /><br />

    We hope this email finds you well. We are writing to kindly remind you about the EPA evaluation for {props.userInfo.firstName} {props.userInfo.lastName}{rotation}.{epa}<br /><br />
    
    As per the resident development plan and as part of their Royal College progress plan, it is important the residents and the programs receive your EPA feedback on {props.userInfo.firstName}'s performance in order to help them grow and improve in their role.<br /><br />
    {note}
    Filling in the EPA is done via the PGME's web interface.<br /><br />
    
    If you have any questions or concerns, you could contact the resident who sent this EPA to you via our platform. {props.userInfo.firstName}'s email address is: {props.userInfo.email}<br /><br />
    
    Thank you for your time and support in helping to develop the next generation of our healthcare providers!<br /><br />
    
    Best regards,<br />
    EPA Tracking Tool<br />
    https://www.epatool.com</p>
    return text;


    function addPrefix() {
        let prefix = "";
        let evaluatorRole = "";
        if (formData.hasOwnProperty("evaluator_role") && formData["evaluator_role"]) {
            evaluatorRole = formData["evaluator_role"];
        }
        if (evaluatorRole.toLocaleLowerCase() == "staff" || evaluatorRole.toLocaleLowerCase() == "resident" || evaluatorRole.toLocaleLowerCase() == "fellow" || evaluatorRole.toLocaleLowerCase() == "ttl") {
            prefix = "Dr. ";
        }
        if (formData.hasOwnProperty("evaluator_name") && formData["evaluator_name"] && formData["evaluator_name"].length > 0) {
            if (formData["evaluator_name"].toLowerCase().startsWith("dr.") || formData["evaluator_name"].toLowerCase().startsWith("dr .") ||
            formData["evaluator_name"].toLowerCase().startsWith("dr ") || 
            formData["evaluator_name"].toLowerCase().startsWith("dre.") || formData["evaluator_name"].toLowerCase().startsWith("dre .") ||
            formData["evaluator_name"].toLowerCase().startsWith("dre ")) {
                return "";
            } else {
                return prefix;
            }
        }
        return "";
    }
}


function propOrStr(obj, property, notFoundText) {
    if(obj === undefined || obj === null) { return notFoundText; }
    if (obj.hasOwnProperty(property)) {
        return obj[property];
    } else { return notFoundText; }
}

function handleFormChange(e) {
    let {name, value, options, checked} = e.target
    // Select tags have options - for multi-select ones put them into an array. If only one, collapse.
    if (options) {
      value = [...e.target.options].filter(o=>o.selected).map(o=>o.value);
      if (value.length == 1){value = value[0];}
    }
    // Capture sensitive actionable changes
    if (name == "program_id") {
        // Ensure only EPAs for this program are shown
        setFormData(current=> ({
            ...current,
            ["epa_id"]: null
          }));
        setIsEpaSelected(false);
        // setEpaOptions(epasData.filter((p)=>p.program_id===value));
        setEpaOptions(getEpasGroupedByVersionForProgram(value));
    }
    if (name == "epa_id") {
        if (value == null || value == ""){
            setIsEpaSelected(false)
        } else {
            setIsEpaSelected(true)
        }
    }
    // Capture valuation status change
    if (name == "status") {
        if (value == null || value == "") {
            setEpaStatus(null);
        } else {
            setEpaStatus(value);
        } 
    }
    if (name=="reminder_evaluator_autosend" || name=="reminder_self_autosend" || name=="reminder_evaluator_send_now") {
        value = checked;
    }
    setFormData(current=> ({
      ...current,
      [name]: value
    }));
    // console.log(formData);
}

function getEpasGroupedByVersionForProgram(id) {
    let grouped = []
    // Check if version is in user's subscriptions
    let userSubscriptions = userSubscribedEpas({forProgramId:formData["program_id"]});
    let epas = epasData.filter((p)=>p.program_id===formData["program_id"] && userSubscriptions.filter(e=>e.id===p.id).length>0);
    let versions = programsData.filter(p=>p.id===id)[0].epa_versions;
    if (versions.length>0) {
        for (let i=0; i<versions.length;i++) {
            
            grouped.push(
                {label: versions[i],
                    options: 
                        epas.filter(e=>e.version==versions[i]).map(e=>{ return {...e,value:e.id, label:
                        e.code + " " + e.short_name + " " + getEpaShortStats({forEpaId:e.id,forUserId:props.userInfo.id, completed:true, required: true}).text
                    }})
                }
            )
        }
        // console.log("Grouped:")
        // console.log(grouped);
    }
    return grouped;
}

function shouldEvaluatorReminderInputsBeDisabled() {
    return !isEpaSelected || !formData.hasOwnProperty("status") || !formData["status"] || formData["status"].trim().length < 1 || formData["status"].trim()!==EVAL_STATUS.SENT;
}


function shouldSelfReminderInputsBeDisabled() {
    return !isEpaSelected || !formData.hasOwnProperty("status") || !formData["status"] || formData["status"].trim().length < 1 || formData["status"].trim()!==EVAL_STATUS.UNSENT ||
        !formData.hasOwnProperty(DATE_CONST_NAMES.ASSESSED) || !(formData[DATE_CONST_NAMES.ASSESSED] instanceof Date);
}


function syncUIWithData() {
    if (formData["program_id"] !== undefined && formData["program_id"] !== null && formData["program_id"] !== ""){
    // setEpaOptions(epasData.filter((p)=>p.program_id===formData["program_id"]));
    setEpaOptions(getEpasGroupedByVersionForProgram(formData["program_id"]));
    // getEpasGroupedByVersionForProgram(formData["program_id"])
    }
    if (formData["epa_id"] === undefined || formData["epa_id"] === null || formData["epa_id"] == ""){
        setIsEpaSelected(false)
    } else {
        setIsEpaSelected(true)
    }
    if (formData["status"] === undefined || formData["status"] === null || formData["status"] == ""){
        setEpaStatus(null);
    } else {
        setEpaStatus(formData["status"]);
    } 
}

useEffect(()=>{
    performDateComparison(); 
    syncUIWithData();
 }, [formData]);

//  useEffect(()=>{
//     if (epaStatus != null && epaStatus != EVAL_STATUS.SENT){
//         // If EPA status is not SENT (i.e. pending), ensure evaluator autosend is also not selected
//     }
//  },[epaStatus]);

useEffect(()=>{
    setLocalKey(Date.now() + 21);
    setMessagesTableau("");
    setShowDeleteConfirm(false);
    console.log("Current:" + formData["note_to_evaluator"]);
    console.log("Replacing with:" + epaInFocus["note_to_evaluator"] === undefined ? null : epaInFocus["note_to_evaluator"]);
    setFormData(current=>({
        ["mode"]:  epaInFocus["mode"] === undefined ? null : epaInFocus["mode"],
        ["eval_id"]:  epaInFocus["eval_id"] === undefined ? null : epaInFocus["eval_id"],
        ["program_id"]: epaInFocus["program_id"] === undefined ? null : epaInFocus["program_id"],
        ["epa_id"]: epaInFocus["epa_id"] === undefined ? null : epaInFocus["epa_id"],        
        ["evaluator_email"]: epaInFocus["evaluator_email"] === undefined ? null : epaInFocus["evaluator_email"],
        ["evaluator_name"]: epaInFocus["evaluator_name"] === undefined ? null : epaInFocus["evaluator_name"],
        ["evaluator_role"]: epaInFocus["evaluator_role"] === undefined ? null : epaInFocus["evaluator_role"],
        ["rotation_id"]: epaInFocus["rotation_id"] === undefined ? null : epaInFocus["rotation_id"],
        ["reminder_evaluator_autosend"]: epaInFocus["reminder_evaluator_autosend"] === undefined ? false : epaInFocus["reminder_evaluator_autosend"],
        ["reminder_evaluator_frequency"]: epaInFocus["reminder_evaluator_frequency"] === undefined ? null : epaInFocus["reminder_evaluator_frequency"],
        // reminder_evaluator_send_now does not need to be extracted - send now is a one time event upon the creation of the evaluation
        ["reminder_self_autosend"]: epaInFocus["reminder_self_autosend"] === undefined ? false : epaInFocus["reminder_self_autosend"],
        ["reminder_self_frequency"]: epaInFocus["reminder_self_frequency"] === undefined ? null : epaInFocus["reminder_self_frequency"],
        ["note_to_evaluator"]: epaInFocus["note_to_evaluator"] === undefined ? null : epaInFocus["note_to_evaluator"],
        ["status"]: epaInFocus["status"] === undefined ? null : epaInFocus["status"],
        [DATE_CONST_NAMES.ASSESSED]: epaInFocus[DATE_CONST_NAMES.ASSESSED] === undefined ? null : tryToDateify(epaInFocus[DATE_CONST_NAMES.ASSESSED]),
        [DATE_CONST_NAMES.SENT]: epaInFocus[DATE_CONST_NAMES.SENT] === undefined ? null : tryToDateify(epaInFocus[DATE_CONST_NAMES.SENT]),
        [DATE_CONST_NAMES.DUE]: epaInFocus[DATE_CONST_NAMES.DUE] === undefined ? null : tryToDateify(epaInFocus[DATE_CONST_NAMES.DUE]),
        [DATE_CONST_NAMES.RESULTED]: epaInFocus[DATE_CONST_NAMES.RESULTED] === undefined ? null : tryToDateify(epaInFocus[DATE_CONST_NAMES.RESULTED])
    }));
    
    function tryToDateify(d) {
        if (d instanceof Date) {
            return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        }
        if (typeof d === "string") {
            let x = new Date(d);
            return new Date(x.getUTCFullYear(), x.getUTCMonth(), x.getUTCDate());
        }
    }



    // if (epaInFocus.mode === undefined || epaInFocus.mode === null || epaInFocus.mode == "add") {
    //     // ADD MODE INITIALISATION
    //     if (epaInFocus.hasOwnProperty("prId") && epaInFocus["prId"] != undefined && epaInFocus["prId"] != null ) {
    //         if (epaInFocus.hasOwnProperty("epaId") && epaInFocus["epaId"] != undefined && epaInFocus["epaId"] != null ) {
    //             setFormData(current=> ({...current,"program_id": epaInFocus["prId"], "epa_id": epaInFocus["epaId"]}));
    //             console.log("SET DATA WITH MODE")
    //             setEpaPageMode({mode:null,epaId:null,prId:null});
    //         } else {
    //             setFormData(current=> ({...current,"program_id": epaInFocus["prId"]}));
    //             setEpaPageMode({mode:null,epaId:null,prId:null});
    //             console.log("SET PARTIAL DATA WITH MODE")
    //         }
    //     }
    //     console.log("ADD MODE");
    //     setFormData(current=> ({...current,"reminder_evaluator_autosend": false,"reminder_self_autosend": false}));
    // } else if (epaInFocus.mode == "edit") {
    //     // EDIT MODE INITIALISATION
    //     setEpaPageMode({mode:null,epaId:null,prId:null});
    //     console.log("EDIT MODE");
    // }
},[epaInFocus]);


function getBadgeFor(step) {
    function requiredBadge() { return <span className="badge text-bg-danger mb-1">Required</span> }
    function optionalBadge() { return <span className="badge text-bg-secondary mb-1">Optional</span> }
    function satisfiedBadge() { return <span className="badge text-bg-primary mb-1">Looks Good</span> }

    switch(step) {
        case STEPS.PROGRAM:
            if (!formData.hasOwnProperty("program_id") || !formData["program_id"] || formData["program_id"].trim().length < 1) { return requiredBadge();
            } else { return satisfiedBadge(); }
        case STEPS.EPA:
            if (!formData.hasOwnProperty("epa_id") || !formData["epa_id"] || formData["epa_id"].trim().length < 1) { return requiredBadge();  }
            else { return satisfiedBadge(); }
        case STEPS.EVALUATOR_NAME:
            if (!shouldEvaluatorReminderInputsBeDisabled() && formData.hasOwnProperty("reminder_evaluator_autosend") && typeof formData["reminder_evaluator_autosend"] === "boolean" && formData["reminder_evaluator_autosend"] === true) { 
                if (!formData.hasOwnProperty("evaluator_name") || !formData["evaluator_name"] || formData["evaluator_name"].trim().length < 1) { return requiredBadge(); }
                else { return satisfiedBadge(); }
             }
            else { return optionalBadge(); }
        case STEPS.EVALUATOR_ROLE:
            return optionalBadge();
        case STEPS.EVALUATOR_EMAIL:
            if (!shouldEvaluatorReminderInputsBeDisabled() && formData.hasOwnProperty("reminder_evaluator_autosend") && typeof formData["reminder_evaluator_autosend"] === "boolean" && formData["reminder_evaluator_autosend"] === true) { 
                if (!formData.hasOwnProperty("evaluator_email") || !formData["evaluator_email"] || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData["evaluator_email"].trim()))) { return requiredBadge(); }
                else { return satisfiedBadge(); }
             }
            else { return optionalBadge(); }    
        case STEPS.ROTATION:
            return optionalBadge();
        case STEPS.REMINDER_EVALUATOR_AUTOSEND:
            return optionalBadge();
        case STEPS.REMINDER_EVALUATOR_FREQUENCY:
            if (!shouldEvaluatorReminderInputsBeDisabled() && formData.hasOwnProperty("reminder_evaluator_autosend") && typeof formData["reminder_evaluator_autosend"] === "boolean" && formData["reminder_evaluator_autosend"] === true) { 
                if (!formData.hasOwnProperty("reminder_evaluator_frequency") || !formData["reminder_evaluator_frequency"] || isNaN(formData["reminder_evaluator_frequency"])) { return requiredBadge(); }
                else { return satisfiedBadge(); }
             }
            else { return optionalBadge(); }

        case STEPS.REMINDER_SELF_AUTOSEND:
            return optionalBadge();
        case STEPS.REMINDER_SELF_FREQUENCY:
            if (!shouldSelfReminderInputsBeDisabled() && formData.hasOwnProperty("reminder_self_autosend") && typeof formData["reminder_self_autosend"] === "boolean" && formData["reminder_self_autosend"] === true) { 
                if (!formData.hasOwnProperty("reminder_self_frequency") || !formData["reminder_self_frequency"] || isNaN(formData["reminder_self_frequency"])) { return requiredBadge(); }
                else { return satisfiedBadge(); }
             }
            else { return optionalBadge(); }
        case STEPS.NOTE_TO_EVALUATOR:
            return optionalBadge();
        case STEPS.STATUS:
            if (!formData.hasOwnProperty("status") || !formData["status"] || formData["status"].trim().length < 1 || (
                formData["status"].trim() != EVAL_STATUS.UNSENT && formData["status"].trim() != EVAL_STATUS.SENT && formData["status"].trim() != EVAL_STATUS.COMPLETED &&
                formData["status"].trim() != EVAL_STATUS.DELETED && formData["status"].trim() != EVAL_STATUS.FAILED && formData["status"].trim() != EVAL_STATUS.EXPIRED)) {
                return requiredBadge();
                } else { return satisfiedBadge(); }
        case STEPS.DATE_ASSESSED:
            return optionalBadge();
        case STEPS.DATE_SENT:
            if (formData.hasOwnProperty("status") && formData["status"] !== null && formData["status"].trim() == EVAL_STATUS.SENT) { 
                if (!formData.hasOwnProperty(DATE_CONST_NAMES.SENT) || !(formData[DATE_CONST_NAMES.SENT] instanceof Date)) { return requiredBadge(); }
                else { return satisfiedBadge(); }
            } else { return optionalBadge(); }
        case STEPS.DATE_RESULTED:
            return optionalBadge();
        case STEPS.DATE_DUE:
            if (formData.hasOwnProperty("status") && formData["status"] !== null && formData["status"].trim() == EVAL_STATUS.SENT) { 
                if (!formData.hasOwnProperty(DATE_CONST_NAMES.DUE) || !(formData[DATE_CONST_NAMES.DUE] instanceof Date)) { return requiredBadge(); }
                else { return satisfiedBadge(); }
            } else { return optionalBadge(); }

    }
}




function performDateComparison() {
    setDateWarnings(null);
    let hasError = false;
    if (formData.hasOwnProperty("status") && formData["status"] != null && formData["status"] != "") {
       let dateWarningMsgs = []
       // Ensure only dates follow each other in a chronological sense
        switch (formData["status"]){
            case EVAL_STATUS.UNSENT:
               // Nothing to compare here as only assessment date should be settable
               break;
            case EVAL_STATUS.SENT:
               if (compareDatePrecedence(DATE_CONST_NAMES.ASSESSED,DATE_CONST_NAMES.DUE,<><strong>Error:</strong> Due Date must be on or after Assessment Date<br /></>,dateWarningMsgs)) {hasError = true;}
               if (compareDatePrecedence(DATE_CONST_NAMES.SENT,DATE_CONST_NAMES.DUE,<><strong>Error:</strong> Due Date must be on or after Sent Date<br /></>,dateWarningMsgs)) {hasError = true;}
               break;
            case EVAL_STATUS.COMPLETED:
            case EVAL_STATUS.FAILED:
            case EVAL_STATUS.DELETED:
            case EVAL_STATUS.EXPIRED:
                if (compareDatePrecedence(DATE_CONST_NAMES.ASSESSED,DATE_CONST_NAMES.RESULTED,<><strong>Error:</strong> Status Change for EPA Date must be on or after Assessment Date<br /></>,dateWarningMsgs)) {hasError = true;}
                if (compareDatePrecedence(DATE_CONST_NAMES.SENT,DATE_CONST_NAMES.RESULTED,<><strong>Error:</strong> Status Change Date for EPA must be on or after Sent Date<br /></>,dateWarningMsgs)) {hasError = true;}
                break;
        }
        if (compareDatePrecedence(DATE_CONST_NAMES.ASSESSED,DATE_CONST_NAMES.SENT,<><strong>Watch out!</strong> Your Assessment Date is after your Sent Date - this is OK if you're planning your EPAs<br /></>,dateWarningMsgs)) {hasError = true;}
        if(formData.hasOwnProperty(DATE_CONST_NAMES.ASSESSED) && 
           formData[DATE_CONST_NAMES.ASSESSED] instanceof Date &&
           formData[DATE_CONST_NAMES.ASSESSED] > Date.now()) {
                dateWarningMsgs.push(<><strong>Watch out!</strong> Your Assessment Date is in the future - this is OK if you're planning your EPAs<br /></>)
           }
        if (dateWarningMsgs.length>0) {
            setDateWarnings(dateWarningMsgs);
        }
    }

    function compareDatePrecedence(proximalDateKey, distalDateKey, warningMsg, warningsList) {
        if(
            formData.hasOwnProperty(proximalDateKey) && 
            formData[proximalDateKey] instanceof Date &&
            formData.hasOwnProperty(distalDateKey) &&
            formData[distalDateKey] instanceof Date &&
            formData[distalDateKey] < formData[proximalDateKey]
            ) {
                warningsList.push(warningMsg);
                // Return true for has error
                return true;
            }
            return false;     
    }
    return hasError;
}


function isSentDateSetAndInPast() {
    let _status = EVAL_STATUS.NOTSET;
    let isSentDateSet = true;
    let isSentDateInPast = false;
    let isSentDateToday = false;

    if (formData.hasOwnProperty("status") && formData["status"] != undefined && formData["status"] != null) {
        _status = formData["status"].trim();
    }
    if ( _status == EVAL_STATUS.SENT) {
        if (!formData.hasOwnProperty(DATE_CONST_NAMES.SENT) || formData[DATE_CONST_NAMES.SENT] == undefined || formData[DATE_CONST_NAMES.SENT] == null || !(new Date(formData[DATE_CONST_NAMES.SENT]) instanceof Date) || isNaN(new Date(formData[DATE_CONST_NAMES.SENT]))) { 
            isSentDateSet = false;
        } else {
            if (!formData[DATE_CONST_NAMES.SENT] instanceof Date) {
                isSentDateSet = false;
            } else {
                let sentDate = new Date(new Date(formData[DATE_CONST_NAMES.SENT].getUTCFullYear(), formData[DATE_CONST_NAMES.SENT].getUTCMonth(), formData[DATE_CONST_NAMES.SENT].getUTCDate()).toDateString());
                let nowDate = new Date(new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())).toDateString());
                
                if (sentDate.getTime() < nowDate.getTime()) {
                    isSentDateInPast = true;
                } else {
                    isSentDateInPast = false;
                }
                isSentDateToday = (sentDate.getTime() == nowDate.getTime());
            }
        }
    } else {
        isSentDateInPast = false;
        isSentDateSet = false;
    }
    return {isSet: isSentDateSet, isInPast:isSentDateInPast, isSentDateToday: isSentDateToday}
}


function getEpaShortPortfolio(forEpaId, forUserId) {
    let res = getEpaShortStatNumbers(forEpaId, forUserId);
    if (res === null || !res.hasOwnProperty("completed") || isNaN(res.completed) || !res.hasOwnProperty("required") || isNaN(res.required)) { return ""; }
    let needed = res.required - res.completed;
    if (needed > 0) {
        return "You need " + needed + " evaluations for this EPA (required: " + res.required + ", completed with passing grade: " + res.completed + ", completed without passing grade: " + res.notCompletedButResulted + ", pending: " + res.unsent + ")";
    } else {
        return "Well done! You've achieved the required number of evaluations with passing grade for this EPA! (required: " + res.required + ", completed with passing grade: " + res.completed + ", completed without passing grade: " + res.notCompletedButResulted + ", pending: " + res.unsent + ")";
    }
}


function submitForm(e) {
    e.preventDefault();
    setMessagesTableau("");
    let hasError = false;
    let err_msg = [];
    let validStatusSelected = true;
    // console.log("Validating: ");
    // console.log(formData);
    

    if (!formData.hasOwnProperty("program_id") || !formData["program_id"] || formData["program_id"].trim().length < 1) { err_msg.push("Invalid program selected."); hasError = true; }
    if (!formData.hasOwnProperty("epa_id") || !formData["epa_id"] || formData["epa_id"].trim().length < 1) { err_msg.push("Invalid EPA selected."); hasError = true; }
    if (!formData.hasOwnProperty("reminder_evaluator_autosend") || typeof formData["reminder_evaluator_autosend"] !== "boolean") { err_msg.push("Invalid setting for reminder for evaluator."); hasError = true; }
    if (!shouldEvaluatorReminderInputsBeDisabled() && formData.hasOwnProperty("reminder_evaluator_autosend") && typeof formData["reminder_evaluator_autosend"] === "boolean" && formData["reminder_evaluator_autosend"] === true) { 
        if (!formData.hasOwnProperty("evaluator_email") || !formData["evaluator_email"] || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData["evaluator_email"].trim()))) { err_msg.push("You've selected to send automatic reminders to evaluators - please provide valid email."); hasError = true; }
        if (!formData.hasOwnProperty("evaluator_name") || !formData["evaluator_name"] || formData["evaluator_name"].trim().length < 1) { err_msg.push("You've selected to send automatic reminders to evaluators - please provide a name."); hasError = true; }
        if (!formData.hasOwnProperty("reminder_evaluator_frequency") || !formData["reminder_evaluator_frequency"] || isNaN(formData["reminder_evaluator_frequency"])) { err_msg.push("Provide a valid frequency for evaluator reminders or disable it."); hasError = true; }
     }
     if (!formData.hasOwnProperty("reminder_self_autosend") || typeof formData["reminder_self_autosend"] !== "boolean") { err_msg.push("Invalid setting for reminder for self."); hasError = true; }
     if (!shouldSelfReminderInputsBeDisabled() && formData.hasOwnProperty("reminder_self_autosend") && typeof formData["reminder_self_autosend"] === "boolean" && formData["reminder_self_autosend"] === true) { 
        if (!formData.hasOwnProperty("reminder_self_frequency") || !formData["reminder_self_frequency"] || isNaN(formData["reminder_self_frequency"])) { err_msg.push("Provide a valid frequency for self reminders or disable it."); hasError = true; }
     }

     if (!formData.hasOwnProperty("status") || !formData["status"] || formData["status"].trim().length < 1 || (
        formData["status"].trim() != EVAL_STATUS.UNSENT && formData["status"].trim() != EVAL_STATUS.SENT && formData["status"].trim() != EVAL_STATUS.COMPLETED &&
        formData["status"].trim() != EVAL_STATUS.DELETED && formData["status"].trim() != EVAL_STATUS.FAILED && formData["status"].trim() != EVAL_STATUS.EXPIRED)) { validStatusSelected = false; err_msg.push("Invalid EPA status selected."); hasError = true; }
    if (validStatusSelected) {
        // Assessment Date and Resulted Date are always optional. Only Sent Date and Due Date are required in a SENT status. If not yet sent, no check is then necessary.
        const _status = formData["status"].trim();
        if ( _status == EVAL_STATUS.SENT){ // || _status == EVAL_STATUS.COMPLETED || _status == EVAL_STATUS.DELETED || _status == EVAL_STATUS.EXPIRED || _status == EVAL_STATUS.FAILED) {
            if (!formData.hasOwnProperty(DATE_CONST_NAMES.SENT) || formData[DATE_CONST_NAMES.SENT] == undefined || formData[DATE_CONST_NAMES.SENT] == null || !(new Date(formData[DATE_CONST_NAMES.SENT]) instanceof Date) || isNaN(new Date(formData[DATE_CONST_NAMES.SENT]))) { err_msg.push("Please specify when the evaluation was/will-be sent."); console.log("Date ERR"); hasError = true; }    
            if (!formData.hasOwnProperty(DATE_CONST_NAMES.DUE) || formData[DATE_CONST_NAMES.DUE] == undefined || formData[DATE_CONST_NAMES.DUE] == null || !(new Date(formData[DATE_CONST_NAMES.DUE]) instanceof Date) || isNaN(new Date(formData[DATE_CONST_NAMES.DUE]))) { err_msg.push("Please specify when the evaluation is due."); hasError = true; }
        }
        let hasDateErrors = performDateComparison();
        if (dateWarnings !== null) { err_msg.concat(dateWarnings); }
        if (hasDateErrors) { hasError = true; }
     }

     function prepDateToSubmit(d) {
        if (!(d instanceof Date)) { return d; }
        return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
     }
     function prepDateAdjusted(d){
        // Makes a UTC version for comparison on server. Eg. Sent Date for checking if today or not - server may be on diff timezone.
        if (!(d instanceof Date)) { return d; }
        let nowDate = new Date();
        let newDate = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),nowDate.getHours(),nowDate.getMinutes(),nowDate.getSeconds()).getTime() 
                        + ((new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),nowDate.getHours(),nowDate.getMinutes(),nowDate.getSeconds()))).getTimezoneOffset() * 60 * 1000));
        // let newDate = new Date(new Date(new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),nowDate.getHours(),nowDate.getMinutes(),nowDate.getSeconds()))).getTime() 
        //                 + ((new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),nowDate.getHours(),nowDate.getMinutes(),nowDate.getSeconds()))).getTimezoneOffset() * 60 * 1000));
        // let newDate = new Date(new Date(new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),nowDate.getHours(),nowDate.getMinutes(),nowDate.getSeconds()))).getTime() 
        //                 + ((new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),nowDate.getHours(),nowDate.getMinutes(),nowDate.getSeconds()))).getTimezoneOffset() * 60 * 1000));

        
        
        // new Date(new Date(Date.UTC(formData[DATE_CONST_NAMES.SENT].getUTCFullYear(), formData[DATE_CONST_NAMES.SENT].getUTCMonth(), formData[DATE_CONST_NAMES.SENT].getUTCDate())).toDateString())
        console.log(newDate);
        return newDate;

     }

     if (hasError) {
                showError(err_msg);
            } else {
                // Shallow copy of formData.
               let dataToSubmit = {...formData};
               dataToSubmit["owner_id"] = props.userInfo.id;
               if (shouldEvaluatorReminderInputsBeDisabled()) {
                // Ensure reminder_evaluator_autosend is not true
                dataToSubmit["reminder_evaluator_autosend"] = false
                dataToSubmit["reminder_evaluator_frequency"] = null
               }
               if (shouldSelfReminderInputsBeDisabled()) {
                // Ensure reminder_evaluator_autosend is not true
                dataToSubmit["reminder_self_autosend"] = false
                dataToSubmit["reminder_self_frequency"] = null
               }

               if (shouldEvaluatorReminderInputsBeDisabled() || !isSentDateSetAndInPast().isSet || !isSentDateSetAndInPast().isInPast) {
                dataToSubmit["reminder_evaluator_send_now"] = null
               } 
               
               if (dataToSubmit.hasOwnProperty(DATE_CONST_NAMES.ASSESSED) && formData[DATE_CONST_NAMES.ASSESSED] instanceof Date) { dataToSubmit[DATE_CONST_NAMES.ASSESSED] = prepDateToSubmit(dataToSubmit[DATE_CONST_NAMES.ASSESSED]) }
               if (dataToSubmit.hasOwnProperty(DATE_CONST_NAMES.SENT) && formData[DATE_CONST_NAMES.SENT] instanceof Date) { dataToSubmit[DATE_CONST_NAMES.SENT] = prepDateToSubmit(dataToSubmit[DATE_CONST_NAMES.SENT]); }
               if (dataToSubmit.hasOwnProperty(DATE_CONST_NAMES.RESULTED) && formData[DATE_CONST_NAMES.RESULTED] instanceof Date) { dataToSubmit[DATE_CONST_NAMES.RESULTED] = prepDateToSubmit(dataToSubmit[DATE_CONST_NAMES.RESULTED]) }
               if (dataToSubmit.hasOwnProperty(DATE_CONST_NAMES.DUE) && formData[DATE_CONST_NAMES.DUE] instanceof Date) { dataToSubmit[DATE_CONST_NAMES.DUE] = prepDateToSubmit(dataToSubmit[DATE_CONST_NAMES.DUE]) }
               dataToSubmit["today_date"] = prepDateToSubmit(new Date());
               dataToSubmit["today_tz_offset"] = new Date().getTimezoneOffset();
            //    console.log("OK - submit for evaluation creation/update");
                props.setSpinnerShow(true);
                let actionWord = isEditMode() ? "Modifie" : "Create";
               props.callApi({endPoint: "/api/create-evaluation",meth:"POST",body: JSON.stringify(dataToSubmit), 
                    actionableSuccClb: 
                        (r)=>{ props.showAlertBox("EPA " + actionWord + "d Successfully",r["result"]);
                        props.updateEvaluationsForCurrentUser();
                        document.getElementById("addEpaCancelBtn").click();
                        // Should remain last actionable statement. Immediately gets rid of the modal.
                        props.reset();
                    }, actionableFailClb: (r)=>{props.showAlertBox("Could not " + actionWord + " EPA",r["result"]);},
                    finallyClb: ()=>props.setSpinnerShow(false), catchClb: (er)=>props.showAlertBox("Error",er)});
                // console.log(dataToSubmit);
            }
}


function deleteEvaluation(isConfirmed) {
    if (!isConfirmed) { return; }
    setMessagesTableau("");
    let hasError = false;
    let err_msg = [];

    if (!formData.hasOwnProperty("eval_id") || !formData["eval_id"] || formData["eval_id"].trim().length < 1) { err_msg.push("Evaluation was not loaded properly - cannot proceed with deleting it."); hasError = true; }
    props.setSpinnerShow(true);
    let dataToSubmit = {...formData};
    dataToSubmit["owner_id"] = props.userInfo.id;
    props.callApi({endPoint: "/api/delete-evaluation",meth:"DELETE",body: JSON.stringify(dataToSubmit), 
        actionableSuccClb: 
            (r)=>{ props.showAlertBox("EPA deleted Successfully",r["result"]);
            props.updateEvaluationsForCurrentUser();
            document.getElementById("addEpaCancelBtn").click();
            // Should remain last actionable statement. Immediately gets rid of the modal.
            props.reset();
        }, actionableFailClb: (r)=>{props.showAlertBox("Could not delete EPA",r["result"]);},
        finallyClb: ()=>props.setSpinnerShow(false), catchClb: (er)=>props.showAlertBox("Error",er)});
}

function isEditMode() { 
    return (formData.hasOwnProperty("mode") && formData["mode"] == "edit");
}

function showError(msg) {
    let err_msg = [<h5 key="header" style={{'marginBottom':'0','paddingBottom':'0'}}>EPA could not be {isEditMode() ? "modified" : "added" } because:</h5>].concat(msg)
    setMessagesTableau(err_msg);
    // setMessagesTableau(err_msg.reduce((el,a) => el.concat(a, <br />),[]))
}

return(

<div className="modal fade" id="addEpaForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
  <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content rainbow-bg" 
        // ref={modalContentRef} onMouseMove={(e)=>handleMouseMove(e, modalContentRef)}
        >
      <div className="modal-header">
        <h5 className="modal-title" id="addEpaFormLabel">{ isEditMode() ? "Edit EPA" : "Add EPA" }</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="container h-100" key={localKey}>
          {props.isLoggedIn && (<div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12">
              <div className="card text-black p-2" style={{"borderRadius": "25px"}}>
                <div className="card-body p-md-0 pt-0">
                  <div className="row justify-content-center">
                    <div className="col-md-9">

                      <form>
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                {getBadgeFor(STEPS.PROGRAM)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-house fa-lg me-3 fa-fw"></i>
                                    <select className="form-select" aria-label="Program selection"
                                        name="program_id" onChange={(e) => handleFormChange(e)} >
                                        <option value="">Select Program</option>
                                        {props.programsData.filter(p=>[...getProgramsInfo().homeProgram,...getProgramsInfo().otherPrograms].some(e=>e.id===p.id)).map((p)=><option key={`"${p.id}"`} value={`${p.id}`} selected={formData["program_id"] === p.id}>{p.name}</option>)}
                                </select>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                {getBadgeFor(STEPS.EPA)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-dna fa-lg me-3 fa-fw"></i>
                                    
                                    
                                    <Select key={makeRandomNum()} name="epa_id" isSearchable={true} isClearable={false} placeholder="EPA selection"
                                        onChange={(e)=>handleFormChange({target: {name:"epa_id", value:e["value"]}})}
                                        className="form-outline flex-fill mb-0"
                                        isDisabled={(!formData.hasOwnProperty("program_id") || formData["program_id"] == null || formData["program_id"] == "" || epaOptions === null || epaOptions.length < 1) ? true : null}
                                        options={epaOptions != null && epaOptions}
                                        
                                        defaultValue={
                                            (!formData.hasOwnProperty("program_id") || formData["program_id"] == null || formData["program_id"] == "" || epaOptions === null || epaOptions.length < 1)
                                            ? {value:"",label:"First Select a Program"}
                                            :
                                            formData.hasOwnProperty("epa_id") && formData["epa_id"] !== undefined && formData["epa_id"] !== null && epaOptions.reduce((a,c)=>[...a,...c.options],[]).filter(e=>e.id===formData["epa_id"]).length>0
                                                ? {value:formData["epa_id"],label:epaOptions.reduce((a,c)=>[...a,...c.options],[]).filter(e=>e.id===formData["epa_id"]).map(p=>p.code + " " + p.short_name + " " + getEpaShortStats({forEpaId:p.id,forUserId:props.userInfo.id, completed:true, required: true}).text)}
                                                : {value:"",label:"Select EPA"}}


                                        formatGroupLabel={epaVersionQuantityGroupLabel}

                                        


                                        



                                            
                                        
                                    />
                                    
                                    
                                    
                                    
                                    {/* <select className="form-select" aria-label="EPA selection"
                                        name="epa_id" onChange={(e) => handleFormChange(e)} 
                                        disabled={(!formData.hasOwnProperty("program_id") || formData["program_id"] == null || formData["program_id"] == "" || epaOptions == null || epaOptions.length < 1) ? true : null}>

                                        {(!formData.hasOwnProperty("program_id") || formData["program_id"] == null || formData["program_id"] == "" || epaOptions == null || epaOptions.length < 1) ? 
                                        <option value="">First Select a Program</option> : <option value="">Select EPA</option>}

                                        {epaOptions != null && epaOptions.sort((a,b)=>(a.code>b.code) ? 1 : ((a.code<b.code) ? -1 : 0)).map((p)=><option key={`"${p.id}"`} value={`${p.id}`} selected={formData["epa_id"] === p.id} >{p.code + " " + p.short_name + getEpaShortStats({forEpaId:p.id,forUserId:props.userInfo.id, completed:true, required: true}).text}</option>)}
                                    </select> */}







                                </div>
                            </div>















                           






                        <div className="col-md-12"><hr /></div>

                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                {/* EPA Status */}
                                {getBadgeFor(STEPS.STATUS)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-1">
                                    <i className="fa-solid fa-battery-half fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                        <select className="form-select" aria-label="EPA status selection"
                                            name="status" onChange={(e) => handleFormChange(e)} disabled={!isEpaSelected} >
                                            <option value="">EPA Status</option>
                                            <option value={EVAL_STATUS.UNSENT} selected={formData["status"] === EVAL_STATUS.UNSENT}>Not sent yet</option>{}
                                            <option value={EVAL_STATUS.SENT} selected={formData["status"] === EVAL_STATUS.SENT}>Pending (sent but not filled yet)</option>
                                            <option value={EVAL_STATUS.COMPLETED} selected={formData["status"] === EVAL_STATUS.COMPLETED}>Completed (with 'passing grade')</option>
                                            <option value={EVAL_STATUS.FAILED} selected={formData["status"] === EVAL_STATUS.FAILED}>Failed</option>
                                            <option value={EVAL_STATUS.DELETED} selected={formData["status"] === EVAL_STATUS.DELETED}>Deleted</option>
                                            <option value={EVAL_STATUS.EXPIRED} selected={formData["status"] === EVAL_STATUS.EXPIRED}>Expired</option>
                                        </select>
                                        <label className="form-label" htmlFor="epa_status">EPA Status</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                {/* Evaluation date */}  
                                {getBadgeFor(STEPS.DATE_ASSESSED)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    <i className="fa-solid fa-calendar-days fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    <DatePicker
                                        value={formData[DATE_CONST_NAMES.ASSESSED]}
                                        onChange={(e) => {let p = {}; p.target = {value: e, name: DATE_CONST_NAMES.ASSESSED}; handleFormChange(p);}}
                                        name={DATE_CONST_NAMES.ASSESSED}
                                        calendarIcon={<i className="fa-solid fa-calendar"></i>}
                                        clearIcon={<i className="fa-solid fa-trash"></i>}
                                        format="yyyy MMM d"
                                        yearPlaceholder="Year"
                                        monthPlaceholder="Month"
                                        dayPlaceholder="Day"
                                        className="EpaEvaluationDate"
                                        disabled={!isEpaSelected || getElementDisabledStateForStatus(epaStatus,"EpaEvaluationDate")}
                                     />
                                     <span className="form-label d-block">Date Evaluation was/would-be <strong className="fw-bold fst-italic">assessed</strong></span>
                                     </div>    
                                </div>
                            </div>


                            <div className="col-md-12 col-lg-6">
                                {/* EPA Sent date */}  
                                {getBadgeFor(STEPS.DATE_SENT)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    <i className="fa-solid fa-calendar-days fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    <DatePicker 
                                        value={formData[DATE_CONST_NAMES.SENT]}
                                        
                                        onChange={(e) => {let p = {}; p.target = {value: e, name: DATE_CONST_NAMES.SENT}; handleFormChange(p);}}
                                        name={DATE_CONST_NAMES.SENT}
                                        calendarIcon={<i className="fa-solid fa-calendar"></i>} 
                                        clearIcon={(!isEpaSelected || !formData.hasOwnProperty("status") || formData["status"] === EVAL_STATUS.SENT) ? null : <i className="fa-solid fa-trash"></i>} format="yyyy MMM d" yearPlaceholder="Year" monthPlaceholder="Month" dayPlaceholder="Day"
                                        className="EpaSentDate"
                                        disabled={!isEpaSelected || getElementDisabledStateForStatus(epaStatus,"EpaSentDate")}
                                     />
                                     <span className="form-label d-block">Date EPA was <strong className="fw-bold fst-italic">sent on</strong></span>
                                     </div>    
                                </div>
                            </div>


                            <div className="col-md-12 col-lg-6">
                                {/* EPA Due By date */}  
                                {getBadgeFor(STEPS.DATE_DUE)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    <i className="fa-solid fa-calendar-days fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    <DatePicker 
                                        value={formData[DATE_CONST_NAMES.DUE]}
                                        onChange={(e) => {let p = {}; p.target = {value: e, name: DATE_CONST_NAMES.DUE}; handleFormChange(p);}}
                                        name={DATE_CONST_NAMES.DUE}
                                        calendarIcon={<i className="fa-solid fa-calendar"></i>} clearIcon={(!isEpaSelected || !formData.hasOwnProperty("status") || formData["status"] === EVAL_STATUS.SENT) ? null : <i className="fa-solid fa-trash"></i>} format="yyyy MMM d" yearPlaceholder="Year" monthPlaceholder="Month" dayPlaceholder="Day"
                                        className="EpaDueDate"
                                        disabled={!isEpaSelected || getElementDisabledStateForStatus(epaStatus,"EpaDueDate")}
                                     />
                                     <span className="form-label d-block">Date EPA is <strong className="fw-bold fst-italic">due by</strong> (often 3 weeks from sent)</span>
                                     </div>    
                                </div>
                            </div>
                            

                            <div className={`${(dateWarnings != null && dateWarnings!= "") ? "col-md-12 col-lg-6" : "col-md-12"}`} >
                                {/* EPA STATUS date */}  
                                {getBadgeFor(STEPS.DATE_RESULTED)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    <i className="fa-solid fa-calendar-days fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    <DatePicker 
                                        value={formData[DATE_CONST_NAMES.RESULTED]}
                                        onChange={(e) => {let p = {}; p.target = {value: e, name: DATE_CONST_NAMES.RESULTED}; handleFormChange(p);}}
                                        name={DATE_CONST_NAMES.RESULTED}
                                        calendarIcon={<i className="fa-solid fa-calendar"></i>}
                                        clearIcon={<i className="fa-solid fa-trash"></i>}
                                        format="yyyy MMM d" yearPlaceholder="Year" monthPlaceholder="Month" dayPlaceholder="Day"
                                        className="EpaStatusDate"
                                        disabled={!isEpaSelected || getElementDisabledStateForStatus(epaStatus,"EpaStatusDate")}
                                     />
                                     {(epaStatus != null && (epaStatus != EVAL_STATUS.UNSENT && epaStatus != EVAL_STATUS.SENT)) ? 
                                        (<span className="form-label d-block">Date EPA was <strong className="fw-bold fst-italic">{epaStatus}</strong></span>)
                                        :
                                        (<span className="form-label d-block fst-italic">Not applicable</span>)
                                     }
                                     </div>    
                                </div>
                            </div>

                            

                            {(dateWarnings != null && dateWarnings!= "") && (<div className="col-md-12 col-lg-6">
                                {/* Date errors tableau */}  
                                <span className="badge text-bg-danger mb-1"></span><br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    {/* <i className="fa-solid fa-circle-exclamation fa-lg me-3 fa-fw"></i> */}
                                    <div className="form-outline flex-fill mb-0">
                                    <p className="alert alert-warning">
                                        <span className="h5">Warning</span><br />
                                        {Array.isArray(dateWarnings) ? dateWarnings.reduce((el,a) => el.concat(a, <br />),[]) : dateWarnings}
                                    </p>
                                    </div>    
                                </div>
                            </div>)}
                            {/* End of Row */}
                        </div>







                        <div className="col-md-12"><hr /></div>



                        <div className="col-md-12 col-lg-6">
                                {/* Self Reminder */}
                                {getBadgeFor(STEPS.REMINDER_SELF_AUTOSEND)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    <i className="fa-solid fa-bell fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    {shouldSelfReminderInputsBeDisabled() && <span className="fst-italic">This option is only active when evaluation's status is "Not yet sent" and an assessment date is chosen</span>}
                                    <div className="form-check form-switch">
                                        <input checked={formData["reminder_self_autosend"] == true} className="form-check-input" type="checkbox" role="switch" id="reminder_self_autosend" disabled={shouldSelfReminderInputsBeDisabled()}
                                         onChange={(e) => handleFormChange(e)} name="reminder_self_autosend" />
                                        <label className="form-check-label" htmlFor="reminder_self_autosend">Send automatic reminders to <strong className="fw-bold text-decoration-underline">yourself</strong></label>
                                    </div>
                                     <span className="form-label d-block fst-italic">This is independent from reminders to evaluators. This is helpful for planning your EPAs to make sure you keep on track.</span>
                                     </div>    
                                </div>
                            </div>


                            <div className="col-md-12 col-lg-6">
                                {/* Self Reminder Frequency */}
                                {getBadgeFor(STEPS.REMINDER_SELF_FREQUENCY)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-1">
                                    <i className="fa-solid fa-envelope-open fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                        <select className="form-select" aria-label="Reminder frequency selection"
                                            name="reminder_self_frequency" onChange={(e) => handleFormChange(e)} 
                                            disabled={shouldSelfReminderInputsBeDisabled() || !formData.hasOwnProperty("reminder_self_autosend") || formData["reminder_self_autosend"] == false } >
                                            <option value="">Reminder Frequency</option>
                                            <option value="5" selected={formData["reminder_self_frequency"] == "5"}>Q 5 days</option>
                                            <option value="7" selected={formData["reminder_self_frequency"] == "7"}>Q 1 week</option>
                                            <option value="14" selected={formData["reminder_self_frequency"] == "14"}>Q 2 weeks</option>
                                            <option value="21" selected={formData["reminder_self_frequency"] == "21"}>Q 3 weeks</option>
                                        </select>
                                        <label className="form-label" htmlFor="reminder_self_frequency">How Often to Send a Reminder <span className="fst-italic">(you can change this any time)</span></label>
                                    </div>
                                </div>
                            </div>






                            <div className="col-md-12"><hr /></div>



                            




                            <div className="col-md-12 col-lg-6">
                                {/* Evaluator Name */}
                                {getBadgeFor(STEPS.EVALUATOR_NAME)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-user fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    <input type="text" id="evaluator_name" name="evaluator_name" className="form-control"
                                    onChange={(e) => handleFormChange(e)} disabled={!isEpaSelected} defaultValue={formData["evaluator_name"]} />
                                    <label className="form-label" htmlFor="evaluator_name">Evaluator's Name</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                {/* Evaluator Role */}
                                {getBadgeFor(STEPS.EVALUATOR_ROLE)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-user-doctor fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                    <select className="form-select" aria-label="Role selection"
                                        name="evaluator_role" onChange={(e) => handleFormChange(e)} disabled={!isEpaSelected} >
                                        <option value="">Select Role</option>
                                        <option value="staff" selected={formData["evaluator_role"] === "staff"}>Staff</option>
                                        <option value="ttl" selected={formData["evaluator_role"] === "ttl"}>Trauma Team Leader</option>
                                        <option value="fellow" selected={formData["evaluator_role"] === "fellow"}>Fellow</option>
                                        <option value="resident" selected={formData["evaluator_role"] === "resident"}>Resident</option>
                                        <option value="nurse" selected={formData["evaluator_role"] === "nurse"}>Nurse</option>
                                        <option value="student" selected={formData["evaluator_role"] === "student"}>Student</option>
                                        <option value="other" selected={formData["evaluator_role"] === "other"}>Other health alliance team member</option>
                                    </select>
                                    <label className="form-label" htmlFor="evaluator_role">Evaluator's Role</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                {/* Evaluator Email */}
                                {getBadgeFor(STEPS.EVALUATOR_EMAIL)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                        <input type="email" id="evaluator_email" name="evaluator_email" className="form-control"
                                        onChange={(e) => handleFormChange(e)} disabled={!isEpaSelected} defaultValue={[formData["evaluator_email"]]} />
                                        <label className="form-label" htmlFor="evaluator_email">Evaluator's Email</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                {/* Rotation */}
                                {getBadgeFor(STEPS.ROTATION)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-dna fa-lg me-3 fa-fw"></i>
                                    <select className="form-select" aria-label="Rotation selection"
                                        name="rotation_id" onChange={(e) => handleFormChange(e)} disabled={!isEpaSelected} >
                                        <option value="">Rotation</option>
                                        {props.rotationsData.map((p)=><option key={`"${p.id}"`} value={`${p.id}`} selected={formData["rotation_id"] === p.id}>{p.name}</option>)}
                                    </select>
                                </div>
                            </div>



                            <div className="col-md-12"><hr /></div>



                            <div className="col-md-12 col-lg-6">
                                {/* Evaluator Reminder */}  
                                {getBadgeFor(STEPS.REMINDER_EVALUATOR_AUTOSEND)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-2">
                                    <i className="fa-solid fa-bell fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                        {shouldEvaluatorReminderInputsBeDisabled() && <span className="fst-italic">This option is only active when evaluation's status is "Pending"</span>}
                                        <div className="form-check form-switch">
                                            <input checked={formData["reminder_evaluator_autosend"] == true} className="form-check-input" type="checkbox" role="switch" id="reminder_evaluator_autosend" 
                                            disabled={shouldEvaluatorReminderInputsBeDisabled()}
                                            onChange={(e) => handleFormChange(e)} name="reminder_evaluator_autosend" />
                                            <label className="form-check-label" htmlFor="reminder_evaluator_autosend">Send automatic reminders to <strong className="fw-bold text-decoration-underline">evaluator</strong></label>
                                        </div>
                                        <span className="form-label d-block fst-italic">You will always receive a notification before a reminder goes out, with a chance to cancel/postpone it.</span>
                                     </div>    
                                </div>
                            </div>


                            <div className="col-md-12 col-lg-6">
                                {/* Evaluator Reminder Frequency */}
                                {getBadgeFor(STEPS.REMINDER_EVALUATOR_FREQUENCY)}<br />
                                <div className="d-flex flex-row align-items-center mb-4 mt-1">
                                    <i className="fa-solid fa-envelope-open fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                        
                                        <select className="form-select" aria-label="Reminder frequency selection"
                                            name="reminder_evaluator_frequency" onChange={(e) => handleFormChange(e)} 
                                            disabled={shouldEvaluatorReminderInputsBeDisabled() || !formData.hasOwnProperty("reminder_evaluator_autosend") || formData["reminder_evaluator_autosend"] == false } >
                                            <option value="">Reminder Frequency</option>
                                            <option value="5" selected={formData["reminder_evaluator_frequency"] == "5"}>Q 5 days</option>
                                            <option value="7" selected={formData["reminder_evaluator_frequency"] == "7"}>Q 1 week</option>
                                            <option value="14" selected={formData["reminder_evaluator_frequency"] == "14"}>Q 2 weeks</option>
                                            <option value="21" selected={formData["reminder_evaluator_frequency"] == "21"}>Q 3 weeks</option>
                                        </select>
                                        <label className="form-label" htmlFor="reminder_evaluator_frequency">How Often to Send a Reminder <span className="fst-italic">(you can change this any time)</span></label>
                                        

                                        {
                                            isSentDateSetAndInPast().isSet && isSentDateSetAndInPast().isInPast && !isEditMode() &&
                                        <div className="form-check form-switch">
                                            <input checked={formData["reminder_evaluator_send_now"] == true} className="form-check-input" type="checkbox" role="switch" id="reminder_evaluator_send_now" 
                                            onChange={(e) => handleFormChange(e)} name="reminder_evaluator_send_now"
                                            disabled={!isEpaSelected || !formData.hasOwnProperty("reminder_evaluator_autosend") || formData["reminder_evaluator_autosend"] == false }  />
                                            <label className="form-check-label" htmlFor="reminder_evaluator_send_now">Send an reminder to your evaluator <strong className="fw-bold text-decoration-underline">right now</strong>, and then with the frequency below:</label>
                                        </div>
                                        }


                                    </div>
                                </div>
                            </div>



                            

                            <div className="col-md-12">
                                <p className="px-4">{getTextForEvaluationSendSchedule()}</p>
                                <hr />
                            </div>







                            




                            <div className="col-md-12 ">
                                {getBadgeFor(STEPS.NOTE_TO_EVALUATOR)}<br />
                                <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-book fa-lg me-3 fa-fw"></i>
                                    <div className="form-outline flex-fill mb-0">
                                        <textarea id="note_to_evaluator" name="note_to_evaluator" className="form-control"
                                            onChange={(e) => handleFormChange(e)} disabled={!isEpaSelected} defaultValue={formData["note_to_evaluator"]} />
                                        <label className="form-label" htmlFor="note_to_evaluator">Note to Evaluator</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                      </form>

                    </div>
                    <div className="col-md-3 mt-2">
                    {isEpaSelected && (formData["epa_id"] !== undefined && formData["epa_id"] !== null && formData["epa_id"] != "") && (    
                        <div><h3 className="fw-bold">EPA Info</h3>
                        
                        <div>
                            
                            <h5>Category:</h5>
                            <span className="fw-bold fst-italic">{ !isEpaSelected ? "" : props.categoriesData.filter((p)=>p.id==props.epasData.filter(p=>p.id==formData["epa_id"])[0].category_id)[0].name }</span>
                        </div>
                        <div>
                            <br />
                            <span className="h5">Version:</span>
                            <span> { !isEpaSelected ? "" : props.epasData.filter(p=>p.id==formData["epa_id"])[0].version }</span>
                        </div>

                        <div>
                            <br />
                            <h5>Full name:</h5>
                            <span>{ !isEpaSelected ? "" : props.epasData.filter(p=>p.id==formData["epa_id"])[0].name }</span>
                        </div>
                        <div>
                            <br />
                            <h5 className="h5">Your Portfolio:</h5>
                            <span className="fst-italic">{ !isEpaSelected ? "" : getEpaShortPortfolio(props.epasData.filter(p=>p.id==formData["epa_id"])[0].id,props.userInfo.id) }</span>
                        </div>
                        <div>
                            <br />
                            <h5>Key Features:</h5>
                            <span>{ !isEpaSelected ? "" : props.epasData.filter(p=>p.id==formData["epa_id"])[0].key_features }</span>
                        </div>
                        <div>
                            <br />
                            <h5>Restrictions:</h5>
                            <span>{ !isEpaSelected ? "" : props.epasData.filter(p=>p.id==formData["epa_id"])[0].restrictions }</span>
                        </div>
                        

                        {/* <div>
                            <br />
                            <h5>Objectives:</h5>
                            <span>{ !isEpaSelected ? "" : props.epasData.filter(p=>p.id==formData["epa_id"])[0].objectives }</span>
                        </div>
                        <div>
                            <br />
                            <h5>Description:</h5>
                            <span>{ !isEpaSelected ? "" : props.epasData.filter(p=>p.id==formData["epa_id"])[0].description }</span>
                        </div> */}
                        </div>)}
                        
                    </div>

                    <div className="row">
                            
                    {messagesTableau.length > 1 && (<div className="alert alert-danger" role="alert">
                        {messagesTableau.filter((i)=>i.key==="header").map((it)=>it)}
                        <ul>
                          {messagesTableau.filter((i)=>i.key!=="header").map((it)=><li>{it}</li>)}
                        </ul>
                      </div>)}

                            </div>



                    {showDeleteConfirm && <div className="col-md-12">
                        <div className="justify-content-center mx-4 mt-2 mb-3 mb-lg-4 text-center" style={{borderTop: "1px solid #dee2e6"}}>
                            <p className="h5 d-block mt-3">Are you sure you want to permanently delete this evaluation?<br /></p>
                            <button type="button" className="btn btn-secondary mx-2" style={{minWidth: "75px"}} onClick={()=>setShowDeleteConfirm(false)}>No</button>
                            <button type="button" className="btn btn-warning mx-2" style={{minWidth: "75px"}} onClick={(e)=>deleteEvaluation(true)}>Yes</button>
                        </div>
                    </div>}

                    <div className="row">
                    
                    <div className="col-md-12 text-center">
                        {(formData.hasOwnProperty("reminder_evaluator_autosend") && typeof formData["reminder_evaluator_autosend"] === "boolean" && formData["reminder_evaluator_autosend"] === true)
                        ?
                        <div>
                        <p>
                            <span className="fw-bold">Note: </span>You have turned <span className="fw-bold">on</span> sending automatic reminders to this evaluator for this evaluation.
                            <br />{getTextForEvaluationSendSchedule()}
                        </p>

                        {formData.hasOwnProperty("evaluator_name") && formData["evaluator_name"] && formData["evaluator_name"].trim().length > 0 &&
                        formData.hasOwnProperty("evaluator_email") && formData["evaluator_email"] && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData["evaluator_email"].trim())) &&
                        (<><button className="btn btn-secondary btn-outline-primary btn-sm mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#sampleReminderEmailToEvaluator" aria-expanded="false" aria-controls="sampleReminderEmailToEvaluator">
                                See Sample Reminder Email
                            </button>
                            <div className="collapse" id="sampleReminderEmailToEvaluator">
                                <div className="card card-body">
                                    <div className="h5">How The Reminder Email Will Look Like</div>
                                    {getTextForSampleReminderEmail()}
                                </div>
                            </div></>)}


                        </div>

                        :
                        <p><span className="fw-bold">Note: </span>Automatic reminders to this evaluator for this evaluation is turned <span className="fw-bold">off</span></p>
                        }
                    </div>
                        <div className="col-md-12">
                            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4 modal-footer">
                                {isEditMode() && <button type="button" className="btn btn-danger btn-lg" style={{minWidth:"110px"}} 
                                    onClick={(e)=>setShowDeleteConfirm(true)}>Delete</button>}
                                <button type="button" id="addEpaCancelBtn" className="btn btn-secondary btn-lg btn-outline-primary" style={{minWidth:"110px"}} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary btn-lg" style={{minWidth:"110px"}}
                                    onClick={(e)=>submitForm(e)}>{ isEditMode() ? "Update" : "Add" }</button>
                            </div>
                        </div>
                        
                        
                    </div>


                    





                    
                    {/* <div className="row justify-content-center">
                    <div className="col-md-12 d-flex align-items-top text-center">
                      <div className="col-sm-12 col-md-6">
                          Forgotten your password?
                      <br /><br />
                      <button type="button" className="btn btn-outline-primary py-2 px-3 ms-2" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#registrationForm"
                      style={{'minWidth':'200px'}}>
                              Reset Password
                              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                  <i className="fa fa-arrow-right"></i>
                              </div>
                          </button>
                      </div>
                      <div className="col-sm-12 col-md-6">
                          Don't have an account yet?
                      <br /><br />
                      <button type="button" className="btn btn-outline-primary py-2 px-3 ms-2" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#registrationForm"
                      style={{'minWidth':'200px'}}>
                              Register
                              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                  <i className="fa fa-arrow-right"></i>
                              </div>
                          </button>
                      <br /><br />
                      
                      </div>
                      
                    </div>
                    </div> */}
                      
                      
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        </div>

      </div>
    </div>
  </div>
</div>        

);
}

function Carousel(props) {
    return (
<div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100" src="/img/carousel-1.jpg" alt="Image" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 pt-5">
                                    <h1 className="display-4 text-white mb-3 animated slideInDown">EPA Tracking Tool for Canadian Residents</h1>
                                    <hr />
                                    <h4 className="display-6 text-white mb-3 animated slideInDown">Your unofficial helper tool made by a fellow resident :)</h4>
                                    <p className="fs-5 text-white-50 mb-5 animated slideInDown">Tired of the hassle of tracking your EPAs, what's been completed, what's pending and chasing your evaluators with reminders? This is exactly what you can use EPA Tracking Tool for - <strong className="text-secondary">and yes, it can automatically send reminders to your evaluators!</strong></p>
                                    {// Login/My-Profile Button
                                        props.nonAuthApiLoaded ?
                                            (!props.isLoggedIn ? (<button type="button" className="btn btn-outline-primary py-2 px-3" data-bs-toggle="modal" data-bs-target="#loginForm">
                                            Log In
                                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-arrow-right"></i></div></button>)
                                            :
                                            <button type="button" className="btn btn-outline-primary py-2 px-3" onClick={(e)=>{props.myProfileAction(e)}}>
                                                My Profile
                                                <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                    <i className="fa fa-arrow-right"></i>
                                                </div>
                                            </button>
                                            )
                                        :
                                            <button className="buttonload btn btn-outline-primary py-2 px-3">Loading <i className="fa fa-spinner fa-spin"></i></button>
                                    }
                                    

                                    {/* <button type="button" className="btn btn-outline-primary py-2 px-3" data-bs-toggle="modal" data-bs-target="#editProfileForm">
                                        Edit Profile
                                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </button> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src="/img/carousel-2.jpg" alt="Image" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 pt-5">
                                    <h1 className="display-4 text-white mb-3 animated slideInDown">Keeping your Personal EPA Management in One Place</h1>
                                    <p className="fs-5 text-white-50 mb-5 animated slideInDown">If you want to keep track of the EPAs you've sent, what's pending, and what else you are yet to send and don't want to let things go missed because you write them on paper, Excel, Notes or email yourself, here you can keep track of everything in one place!</p>
                                    {// Login/My-Profile Button
                                        props.nonAuthApiLoaded ?
                                        (!props.isLoggedIn ? (<button type="button" className="btn btn-outline-primary py-2 px-3" data-bs-toggle="modal" data-bs-target="#loginForm">
                                        Log In
                                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2"><i className="fa fa-arrow-right"></i></div></button>)
                                        :
                                        <button type="button" className="btn btn-outline-primary py-2 px-3" onClick={(e)=>{props.myProfileAction(e)}}>
                                            My Profile
                                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                <i className="fa fa-arrow-right"></i>
                                            </div>
                                        </button>
                                        )
                                    :
                                        <button className="buttonload btn btn-outline-primary py-2 px-3">Loading <i className="fa fa-spinner fa-spin"></i></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    );
}
export default Carousel;
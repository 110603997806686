import { useState, useEffect } from 'react';

function useTokens() {

  const [tokenChangeCallbackList, setTokenChangeCallbackList] = useState([]);

  function retrieveToken() {
    const userToken = localStorage.getItem("token");
    return userToken;
  }

  const [token, setToken] = useState(retrieveToken());

  function saveToken(userToken, clbFn=null) {
    if (clbFn !== null) { console.log("Adding token callback"); setTokenChangeCallbackList(c=>[...c,clbFn]); }
    localStorage.setItem("token", userToken);
    setToken(userToken);
    // console.log("New token saved in storage")
  };

  useEffect(()=>{
    // console.log("checking for any callbacks..." + tokenChangeCallbackList.length)
    
      tokenChangeCallbackList.forEach((f)=>f())
      tokenChangeCallbackList.splice(0,tokenChangeCallbackList.length);
  },[token]);

  // Don't expose the 'token' object directly - make it only read-only from outside
  function getToken() {
    return token;
  }
  function removeToken(clbFn=null) {
    if (clbFn !== null) { setTokenChangeCallbackList(c=>[...c,clbFn]); }
    localStorage.removeItem("token");
    setToken(null);
  }


  function screenForFreshToken(data) {
    if (!data || !data.hasOwnProperty("token")) {
       return; 
    } else {
      if (data.hasOwnProperty("token")) {
        const new_token = data["token"]
        // console.log("Fresh token found - updating...");
        saveToken(new_token);
      }
    }
  }

  function isTokenAvailable() {
    return retrieveToken()!=null
  }

  function getAuthHeader(options) {
    let t = retrieveToken();
    if (t === undefined || t === null ) {
      // console.log("Token undefined - not setting Auth header")
      return options;
    } else {
      // console.log("Setting up Auth header")
      options.headers = {...options.headers, Authorization: "Bearer " + t}
      return options;
    }
  }

  return {
    setToken: saveToken,
    screenForFreshToken,
    removeToken,
    getAuthHeader,
    isTokenAvailable,
    getToken
  }

}

export default useTokens;
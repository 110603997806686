import {useState, useEffect} from 'react'
import Select from 'react-select';

export default function Register(props) {

  const {makeImg,institutesData} = props;


    const [formData, setFormData] = useState([])
    const [doPasswordsMatch, setDoPasswordsMatch] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [registrationErrors, setRregistrationErrors] = useState("")
    // const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false)
    const [imgFullPath,setImgFullPath] = useState("");

    function handleFormChange(e) {
        let {name, value, options} = e.target
        // Select tags have options - for multi-select ones put them into an array. If only one, collapse.
        if (options) {
          value = [...e.target.options].filter(o=>o.selected).map(o=>o.value);
          if (value.length === 1){value = value[0];}
        }

        // Ensure Additional IDs is an array
        if (name==="additionalProgramIds") {if (!Array.isArray(value)) {value = [value];}}
        setFormData(current=> {
          let adjustProgramIds = false;
          let pids = [];
          // If home program ID changes, ensure it is not selected in the additional programs
          if (current["additionalProgramIds"] != undefined && current["additionalProgramIds"]!=null) {
            if (name==="homeProgramId") {
              if (current["additionalProgramIds"].includes(value)) {
                pids = current["additionalProgramIds"].filter(v=>v!=value);
              }
            }
          }
          if (adjustProgramIds) {
            return {...current,[name]: value,additionalProgramIds:pids}
          } else {
            return {...current,[name]: value}
          }
        })
    }

    function showError(msg) {
        let err_msg = [<h5 key="" style={{'marginBottom':'0','paddingBottom':'0'}}>Registration failed because:</h5>].concat(msg)
        setRregistrationErrors(err_msg.reduce((el,a) => el.concat(a, <br />),[]))
    }

    function submitForm(e) {
        e.preventDefault();
        setRregistrationErrors("");
        let hasError = false;
        let err_msg = [];
        if (!isPasswordValid) {err_msg.push("Password is invalid. It must be 6 or more characters (and also less than 48 characters)!"); hasError = true;}
        if (!doPasswordsMatch) { err_msg.push("Passwords don't match!"); hasError = true;}
        if (!formData["firstName"] || formData["firstName"].trim().length < 1) { err_msg.push("First name is required!"); hasError = true;}
        if (!formData["lastName"] || formData["lastName"].trim().length < 1) { err_msg.push("Last name is required!"); hasError = true;}
        if (!formData["email"] || formData["email"].trim().length < 1) { err_msg.push("Email address is required!"); hasError = true;}
        if ((formData["email"] && formData["email"].trim().length >= 1) && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData["email"].trim()))) { err_msg.push("Please enter a valid email!"); hasError = true;}
        if (!formData["pword"] || formData["pword"].length < 6) { hasError = true;}
        if (!formData["pwordrpt"] || formData["pwordrpt"].length < 6) { hasError = true;}
        if (!formData["educationalInstitute"] || formData["educationalInstitute"].trim().length < 1) { err_msg.push("Educational Institute is required!"); hasError = true;}
        if (!formData["gender"] || formData["gender"].trim().length < 1) { err_msg.push("Gender is required!"); hasError = true;}
        if (!formData["residencyYear"] || formData["residencyYear"].trim().length < 1) { err_msg.push("Residency year is required!"); hasError = true;}
        if (!formData["homeProgramId"] || formData["homeProgramId"].length < 1) { err_msg.push("You must select a home program!"); hasError = true;}

        
        
        if (hasError) {
            showError(err_msg);
        } else {
            // Shallow copy of formData and removing rpt pwd. Actual pwd can also be hashed, albeit weak and crackable.
           let homeProgram = [{id:formData["homeProgramId"]}]
           let additionalPrograms = []
           if (formData.hasOwnProperty("additionalProgramIds") && formData["additionalProgramIds"]) {
              formData["additionalProgramIds"].map(p=>additionalPrograms.push({id:p}));


              // Ensure a program is not repeated in both home program and additional programs
              if (formData["additionalProgramIds"].includes(formData["homeProgramId"])) {
                err_msg.push("A program cannot be in both Home Program and Additional Programs!");
                hasError = true;
                showError(err_msg);
                return;
              }
           }
          
          


          let dataToSubmit = {...formData, mode:"add", homeProgram:homeProgram, additionalPrograms:additionalPrograms};

           delete dataToSubmit["pwordrpt"];
          //  console.log("OK - submit for registration")
           const options = {
            method: "POST",
            headers: {"Accept": "application/json","Content-Type": "application/json"},
            body: JSON.stringify(dataToSubmit)
            }
            props.setSpinnerShow(true);
           fetch("/api/auth/register",options).then((res)=>{
            props.setSpinnerShow(false);
            // console.log("Status: " + res.status)
            if (res.status !== 200) {
              // console.log("Server-side Error");
              res.json().then(result=>{
                // Server-side failure
                if(result.hasOwnProperty("result")) {
                  showError(result["result"]);
                }
              }) 
            } else {
             res.json().then(
              result=>{
              // Registration successful
              
              props.onRegistrationCompletion(result);
              // setRegisteredSuccessfully(true)}
        })}})
            // console.log(dataToSubmit);
        }
    }

    useEffect(()=>{
        setDoPasswordsMatch(formData["pword"] && formData["pwordrpt"] && formData["pword"] === formData["pwordrpt"])
        setIsPasswordValid(formData["pword"] && formData["pword"].length>=6)
        // console.log("Form data changed: ")
        // console.log(formData)
    },[formData])

    useEffect(()=>{
      setImgFullPath(makeImg({elId:"registration-staticimage",forType:"registration"}));
    },[])


return(
<div className="modal fade" id="registrationForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="registrationFormLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title d-lg-none" id="staticBackdropLabel">Sign Up</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">

        <div className="container h-100">
          {props.isLoggedIn && (
            <div className="alert alert-success text-center" role="alert">
              <h4 className="alert-heading">Well done!</h4>
              <p>You were registered successfully! You can now log and track your EPAs for your home program as well as any additional programs you selected (if not, you can always add this later in your settings).</p>
              <hr />
              <p className="mb-4 fw-bold">Happy EPA tracking!</p>
              <button type="button" className="btn btn-primary btn-lg" data-bs-dismiss="modal">Start</button>
            </div>
          )}
          {!props.isLoggedIn && (<div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12">
              <div className="card text-black" style={{"borderRadius": "25px"}}>
                <div className="card-body p-md-5 pt-0 pt-md-0">
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-8 col-xl-6 order-2 order-lg-1">

                      <p className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-4">Sign Up</p>

                      <form className="mx-1 mx-md-4">

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <div className="form-floating mb-3">
                              <input type="text" name="firstName" className="form-control"
                                  onChange={(e) => handleFormChange(e)} placeholder="First Name" />
                              <label className="form-label">First Name</label>
                            </div>

                            <div className="form-floating mb-3">
                              <input type="text" name="lastName" className="form-control"
                                  onChange={(e) => handleFormChange(e)} placeholder="Last Name" />
                              <label className="form-label">Last Name</label>
                            </div>

                            <select className="form-select" aria-label="Gender selection" style={{'width':'100%'}}
                              name="gender" onChange={(e) => handleFormChange(e)} >
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>

                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <div className="form-floating mb-3">
                              <input type="email" name="email" className="form-control"
                                  onChange={(e) => handleFormChange(e)} placeholder="Email" />
                              <label className="form-label" htmlFor="email">Email</label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                          {!isPasswordValid && (<div className="alert alert-warning" role="alert" style={{'marginBottom':'0.2em','padding':'0.2em'}}>6 characters or more please</div>)}
                            <div className="form-floating mb-3">
                              <input type="password" name="pword" className="form-control"
                                  onChange={(e) => handleFormChange(e)} autoComplete="off" placeholder="Current Password" />
                              <label className="form-label" htmlFor="">Password</label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            {!doPasswordsMatch && (<div className="alert alert-danger" role="alert" style={{'marginBottom':'0.2em','padding':'0.2em'}}>Passwords need to match!</div>)}
                            <div className="form-floating mb-3">
                              <input type="password" name="pwordrpt" className="form-control"
                                  onChange={(e) => handleFormChange(e)} autoComplete="off" placeholder="Repeat Password" />
                              <label className="form-label" htmlFor="">Repeat password</label>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fa-solid fa-school fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <Select name="educationalInstitute" options={institutesData.namesList} isMulti={false} 
                                isSearchable={true} isClearable={false} placeholder="Select your residency institute" 
                                onChange={(e)=>handleFormChange({target: {name:"educationalInstitute", value:e["value"]}})}
                            />
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-hashtag fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <select className="form-select" aria-label="Year of residency selection" style={{'width':'100%'}}
                              name="residencyYear" onChange={(e) => handleFormChange(e)} >
                                <option value="">Post graduate year (year of residency)</option>
                                <option value="1">R 1</option>
                                <option value="2">R 2</option>
                                <option value="3">R 3</option>
                                <option value="4">R 4</option>
                                <option value="5">R 5</option>
                                <option value="6">R 6</option>
                                <option value="7">R 7</option>
                                <option value="8">R 8</option>
                            </select>
                            <div className="text-center fst-italic" style={{color:"gray"}}>(this automatically updates every July 1)</div>
                          </div>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fa-regular fa-hospital fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <Select name="homeProgramId" isSearchable={true} isClearable={false} placeholder="Select your home program"
                              options={props.programsData.filter(p=>p.name!=="Surgical Foundations").map((p)=>{return {value:p.id,label:p.name}})}
                              onChange={(e)=>handleFormChange({target: {name:"homeProgramId", value:e["value"]}})}
                            />
                          </div>
                        </div>


                        {/* {<div className="d-flex flex-row align-items-center mb-4">
                          <i className="fa-regular fa-hospital fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                          <Select name="homeprogramepaversion" isSearchable={true} isClearable={false} placeholder="Select your home program"
                              options={props.programsData.filter(p=>p.name!=="Surgical Foundations").map((p)=>{return {value:p.id,label:p.name}})}
                              onChange={(e)=>handleFormChange({target: {name:"homeprogramepaversion", value:e["value"]}})}
                            />
                          </div>
                        </div>} */}




                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fa-solid fa-hospital fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <Select isMulti={true} isSearchable={true} isClearable={false} isDisabled={!formData.hasOwnProperty("homeProgramId") || formData["homeProgramId"]==undefined || formData["homeProgramId"]==null || formData["homeProgramId"].length < 1}
                                placeholder="Select any additional programs" name="additionalProgramIds"
                                options={props.programsData.filter(p=>{return p.id!==formData["homeProgramId"]}).map((p)=>{return {value:p.id, label:p.name}})}
                                onChange={(e)=>{for (let i=0; i<e.length;i++) {e[i].selected = true;}; handleFormChange({target: {name:"additionalProgramIds", options:e}});}}
                            />
                          </div>
                        </div>





                        

                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4 modal-footer">
                          <button type="button" className="btn btn-secondary btn-lg btn-outline-primary" style={{minWidth:"110px"}} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary btn-lg" style={{minWidth:"110px"}}
                            onClick={(e)=>submitForm(e)}>Register</button>
                          
                        </div>
                        {registrationErrors.length > 1 && (<div className="alert alert-danger" role="alert">
                              {registrationErrors}
                          </div>)}

                      </form>

                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-6 d-flex align-items-center order-1 order-lg-2">
                      <div className="row justify-content-center">
                        <div className="col-md-12 d-flex align-items-center">
                          <div>
                              You must select your home program. You can also select any additional programs - for a surgical resident, this is typically only Surgical Foundations.
                          <br /><br />
                              <span className="fw-bold">For example,</span> if you're an ENT resident, you may select your home program as Otorhinolaryngology and choose Surgical Foundations as the additional program so you can track the EPA progress for both programs.
                              <hr />It is recommended you sign up with your <span className="fw-bold">school's email address</span> (e.g. john.smith@mail.mcgill.ca).
                              <hr /><span className="fw-bold">EPA Versions:</span><br />By default, you are signed up to the latest version of an EPA for any program you select. <span className="fw-bold">However,</span> if you need to change this, you can do so in the Edit Profile section.
                          
                          </div>
                          
                        </div>
                        <div className="col-md-11 d-flex align-items-center">
                         <img src={imgFullPath}
                            className="img-fluid d-md-none d-lg-block rounded" alt="Registraion" />
                        </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  </div>
</div>

);
}
import {useEffect, useState} from 'react';
import { Waypoint } from 'react-waypoint';
import EPAsTable from './ProgramsEPAsTables';

export default function EPAsByProgram(props) {
const {getProgramsInfo, allBreakdowns, makeImg, handleDisplayProgramContentsSelection} = props;
const STAGES = { 
    TRANSITION_TO_DISCIPLINE: "ttd", 
    FOUNDATIONS_OF_DISCIPLINE: "fod",
     CORE_OF_DISCIPLINE: "cod",
      TRANSITION_TO_PRACTICE: "ttp" }



    return(
        <div className="container-xxl bg-light my-5 stats-container">
        { 
        props.isLoggedIn &&
        (
            <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{'maxWidth': '500px'}}>
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">EPA Fulfilment Stats</div>
                <h2 className="display-6 text-capitalize">Welcome  {props.userInfo.firstName}</h2>
                <h3 className="mb-5">Your EPA Breakdown By Program</h3>
            </div>
                <div className="row g-4 justify-content-center">
                    

                <Waypoint onEnter={props.reachedProgressBarsWaypoint("programs-progress-bars")}></Waypoint>

                    {/* program(s) */}
                    {[...getProgramsInfo().homeProgram,... getProgramsInfo().otherPrograms].map((program,index,arr) => {return (
                    <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                            <div className="text-center p-4 pt-0">
                                <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                    <small>{index==0?"Home":"Additional"} Program</small>
                                </div>
                                <h5 className="mb-3">{program.name}</h5>
                                <p className="text-start">A 'Completed' evaluation means an evaluation that has been filled by an evaluator with a passing grade, counting towards the total required numbers.
                                    <br />
                                    Below you can see how many {program.name} evaluation have been Completed.
                                </p>
                                
                                <p className="fw-bold">
                                {(()=>{
                                    let stats = allBreakdowns({forProgramId:program.id});
                                    return (stats.sumSent > 0 ?
                                        "You also have " + stats.sumSent + " evaluations sent and pending completion."
                                        :
                                        "You have no evaluations previously sent and pending completion by an evaluator.")
                                 })()}
                                </p>

                                {(()=>{
                                    let stats = allBreakdowns({forProgramId:program.id});
                                    return(
                                <div className="causes-progress bg-light p-3 pt-2">
                                    <div className="d-flex justify-content-between">
                                        <p className="text-dark">{stats.sumCompleted} <small className="text-body">Completed</small></p>
                                        <p className="text-dark">{stats.sumRequired} <small className="text-body">Total</small></p>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar programs-progress-bars" role="progressbar" aria-valuenow={stats.percentTotalCompleted} aria-valuemin="0" aria-valuemax="100">
                                            <span className="fw-bold">{stats.percentTotalCompleted}%</span>
                                        </div>
                                    </div>
                                </div>
                                )})()}

                            </div>
                            <div className="position-relative mt-auto">
                                <img className="img-fluid" src={makeImg({elId:"epasbyprogram-" + program.id,orientation:"horizontal", forType:program.name})} alt="" />
                                <div className="causes-overlay">
                                    <a className="btn btn-outline-primary" href="" onClick={(e)=>{e.preventDefault(); handleDisplayProgramContentsSelection(program.id); }}>
                                        Show List
                                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>)})}








                </div>


                <hr />

                <EPAsTable
                    props={props}
                />


                <hr />





                <div className="text-center mx-auto mb-5 mt-3 wow fadeInUp" data-wow-delay="0.1s" style={{'maxWidth': '500px'}}>
                    <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">EPA Fulfilment Stats</div>
                    <h1 className="display-6 mb-5">Your EPA Breakdown By Stage</h1>
                </div>

                <div className="row g-4 justify-content-center">
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                            <div className="text-center p-4 pt-0">
                                <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                    <small>Transition to Discipline</small>
                                </div>
                                
                                
                                
                                
                                
                                {(()=>{
                                    let stats = allBreakdowns({forStage: STAGES.TRANSITION_TO_DISCIPLINE});
                                    return(<>
                                    <div style={{minHeight:"200px"}}>
                                    <p>All Transition to Discipine EPAs for {[...getProgramsInfo().homeProgram, ...getProgramsInfo().otherPrograms].map((p,index)=>(index>0?", ":"") + p.name)}</p>
                                <p className="fw-bold">
                                {stats.sumSent > 0 ?
                                    "You also have " + stats.sumSent + " EPAs sent and pending filling."
                                    :
                                    "You have no EPAs already sent and pending filling by an evaluator."
                                }</p>
                                </div>
                                <Waypoint onEnter={props.reachedProgressBarsWaypoint("discipline-stats-progress-bars")}></Waypoint>
                                <div className="causes-progress bg-light p-3 pt-2">
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <p className="text-dark">{stats.sumCompleted} <small className="text-body">Completed</small></p>
                                            <p className="text-dark">{stats.sumRequired} <small className="text-body">Total</small></p>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar discipline-stats-progress-bars" role="progressbar" aria-valuenow={stats.percentTotalCompleted} aria-valuemin="0" aria-valuemax="100">
                                                <span className="fw-bold">{stats.percentTotalCompleted}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>)})()}



                                {/* </Waypoint> */}
                            </div>
                            <div className="position-relative mt-auto text-center">
                                <img className="img-fluid stat-cover-img" src="/img/stat-transition.jpg" alt="" />
                                {/* <div className="causes-overlay">
                                    <a className="btn btn-outline-primary" href="">
                                        Show List
                                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                            <div className="text-center p-4 pt-0">
                                <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                    <small>Foundations of Discipline</small>
                                </div>
                                {/* <h5 className="mb-3"></h5> */}
                                

                                {(()=>{
                                    let stats = allBreakdowns({forStage: STAGES.FOUNDATIONS_OF_DISCIPLINE});
                                    return(<>
                                    <div style={{minHeight:"200px"}}>
                                    <p>All Foundations of Discipine EPAs for {[...getProgramsInfo().homeProgram, ...getProgramsInfo().otherPrograms].map((p,index)=>(index>0?", ":"") + p.name)}</p>


                                <p className="fw-bold">
                                {stats.sumSent > 0 ?
                                    "You also have " + stats.sumSent + " EPAs sent and pending filling."
                                    :
                                    "You have no EPAs already sent and pending filling by an evaluator."
                                }</p>
                                </div>
                                
                                <Waypoint onEnter={props.reachedProgressBarsWaypoint("discipline-stats-progress-bars")}></Waypoint>
                                <div className="causes-progress bg-light p-3 pt-2">
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <p className="text-dark">{stats.sumCompleted} <small className="text-body">Completed</small></p>
                                            <p className="text-dark">{stats.sumRequired} <small className="text-body">Total</small></p>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar discipline-stats-progress-bars" role="progressbar" aria-valuenow={stats.percentTotalCompleted} aria-valuemin="0" aria-valuemax="100">
                                                <span className="fw-bold">{stats.percentTotalCompleted}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>)})()}



                            </div>
                            <div className="position-relative mt-auto text-center">
                                <img className="img-fluid stat-cover-img" src="img/stat-foundations.jpg" alt="" />
                                {/* <div className="causes-overlay">
                                    <a className="btn btn-outline-primary" href="">
                                        Show List
                                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                            <div className="text-center p-4 pt-0">
                                <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                    <small>Core of Discipline</small>
                                </div>
                                {/* <h5 className="mb-3"></h5> */}
                                
                                
                                
                                
                                {(()=>{
                                    let stats = allBreakdowns({forStage: STAGES.CORE_OF_DISCIPLINE});
                                    return(<>
                                    <div style={{minHeight:"200px"}}>
                                    <p>All Core of Discipine EPAs for {[...getProgramsInfo().homeProgram, ...getProgramsInfo().otherPrograms].map((p,index)=>(index>0?", ":"") + p.name)}</p>

                                    
                                <p className="fw-bold">
                                {stats.sumSent > 0 ?
                                    "You also have " + stats.sumSent + " EPAs sent and pending filling."
                                    :
                                    "You have no EPAs already sent and pending filling by an evaluator."
                                }</p>
                                </div>
                                
                                <Waypoint onEnter={props.reachedProgressBarsWaypoint("discipline-stats-progress-bars")}></Waypoint>
                                <div className="causes-progress bg-light p-3 pt-2">
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <p className="text-dark">{stats.sumCompleted} <small className="text-body">Completed</small></p>
                                            <p className="text-dark">{stats.sumRequired} <small className="text-body">Total</small></p>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar discipline-stats-progress-bars" role="progressbar" aria-valuenow={stats.percentTotalCompleted} aria-valuemin="0" aria-valuemax="100">
                                                <span className="fw-bold">{stats.percentTotalCompleted}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>)})()}


                            </div>
                            <div className="position-relative mt-auto text-center">
                                <img className="img-fluid stat-cover-img" src="img/stat-core.jpg" alt="" />
                                {/* <div className="causes-overlay">
                                    <a className="btn btn-outline-primary" href="">
                                        Show List
                                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                            <div className="text-center p-4 pt-0">
                                <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                    <small>Transition to Practice</small>
                                </div>
                                {/* <h5 className="mb-3"></h5> */}
                                
                                
                                
                                
                                {(()=>{
                                    let stats = allBreakdowns({forStage: STAGES.TRANSITION_TO_PRACTICE});
                                    return(<>
                                    <div style={{minHeight:"200px"}}>
                                    <p>All Transition to Practice EPAs for {[...getProgramsInfo().homeProgram, ...getProgramsInfo().otherPrograms].map((p,index)=>(index>0?", ":"") + p.name)}</p>


                                    
                                <p className="fw-bold">
                                {stats.sumSent > 0 ?
                                    "You also have " + stats.sumSent + " EPAs sent and pending filling."
                                    :
                                    "You have no EPAs already sent and pending filling by an evaluator."
                                }</p>
                                </div>
                                
                                <div className="causes-progress bg-light p-3 pt-2">
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <p className="text-dark">{stats.sumCompleted} <small className="text-body">Completed</small></p>
                                            <p className="text-dark">{stats.sumRequired} <small className="text-body">Total</small></p>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar discipline-stats-progress-bars" role="progressbar" aria-valuenow={stats.percentTotalCompleted} aria-valuemin="0" aria-valuemax="100">
                                                <span className="fw-bold">{stats.percentTotalCompleted}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>)})()}






                            </div>
                            <div className="position-relative mt-auto text-center">
                                <img className="img-fluid stat-cover-img" src="img/stat-practice.jpg" alt="" />
                                {/* <div className="causes-overlay">
                                    <a className="btn btn-outline-primary" href="">
                                        Show List
                                        <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                

















            </div>)}
        </div>
    );
}
import {Waypoint} from 'react-waypoint';

export default function GlobalStats(props) {

    const {globalStatsData} = props;


    // let ttdStats = allBreakdowns({forStage:"ttd",useGlobal:true, useWeight:globalWeightsData.wt});
    // let fodStats = allBreakdowns({forStage:"fod",useGlobal:true, useWeight:globalWeightsData.wt});
    // let codStats = allBreakdowns({forStage:"cod",useGlobal:true, useWeight:globalWeightsData.wt});
    // let ttpStats = allBreakdowns({forStage:"ttp",useGlobal:true, useWeight:globalWeightsData.wt});

    function commatise(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    
    
    


    return(
        <div className="container-xxl bg-light my-5 py-5 stats-container">
    {/* <div className="container-xxl bg-light my-5 py-5 stats-container"  data-parallax="scroll" data-image-src="img/carousel-5.jpg"> */}
        <div className="container py-5">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{'maxWidth': '500px'}}>
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">EPA Fulfilment Stats</div>
                <h1 className="display-6 mb-5">Pan-Canadian Stats for Current Residents</h1>
            </div>
            <div className="row g-4 justify-content-center">
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                        <div className="text-center p-4 pt-0">
                            <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                <small>Transition To Discipline</small>
                            </div>
                            <div style={{minHeight:"120px"}}>
                                <p>Transition to Discipline completion rate across all schools in Canada for residents using this website</p>
                            </div>
                            <Waypoint onEnter={props.reachedProgressBarsWaypoint("global-stats-progress-bars")}>
                            <div className="causes-progress bg-light p-3 pt-2">
                                <div className="d-flex justify-content-between">
                                    <p className="text-dark">{commatise(globalStatsData.ttd.completed)} <small className="text-body">Completed</small></p>
                                    <p className="text-dark">{commatise(globalStatsData.ttd.totalSubscribed)} <small className="text-body">Total</small></p>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar global-stats-progress-bars" role="progressbar" aria-valuenow={Math.ceil(globalStatsData.ttd.completed/globalStatsData.ttd.totalSubscribed)} aria-valuemin="0" aria-valuemax="100">
                                        <span>{Math.ceil(globalStatsData.ttd.completed/globalStatsData.ttd.totalSubscribed)}%</span>
                                    </div>
                                </div>
                            </div>
                            </Waypoint>
                        </div>
                        <div className="position-relative mt-auto text-center">
                            <img className="img-fluid stat-cover-img" src="img/stat-transition.jpg" alt="" />
                            {/* <div className="causes-overlay">
                                <a className="btn btn-outline-primary" href="">
                                    Show List
                                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                        <i className="fa fa-arrow-right"></i>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                        <div className="text-center p-4 pt-0">
                            <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                <small>Foundations of Discipline</small>
                            </div>
                            <div style={{minHeight:"120px"}}>
                                <p>Foundations of Discipline completion rate across all schools in Canada for residents using this website</p>
                            </div>
                            <div className="causes-progress bg-light p-3 pt-2">
                                <div className="d-flex justify-content-between">
                                    <p className="text-dark">{commatise(globalStatsData.fod.completed)} <small className="text-body">Completed</small></p>
                                    <p className="text-dark">{commatise(globalStatsData.fod.totalSubscribed)} <small className="text-body">Total</small></p>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar global-stats-progress-bars" role="progressbar" aria-valuenow={Math.ceil(globalStatsData.fod.completed/globalStatsData.fod.totalSubscribed)} aria-valuemin="0" aria-valuemax="100">
                                        <span>{Math.ceil(globalStatsData.fod.completed/globalStatsData.fod.totalSubscribed)}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative mt-auto text-center">
                            <img className="img-fluid stat-cover-img" src="img/stat-foundations.jpg" alt="" />
                            {/* <div className="causes-overlay">
                                <a className="btn btn-outline-primary" href="">
                                    Show List
                                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                        <i className="fa fa-arrow-right"></i>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                        <div className="text-center p-4 pt-0">
                            <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                <small>Core of Discipline</small>
                            </div>
                            <div style={{minHeight:"120px"}}>
                                <p>Core of Discipline completion rate across all schools in Canada for residents using this website</p>
                            </div>

                            <div className="causes-progress bg-light p-3 pt-2">
                                <div className="d-flex justify-content-between">
                                    <p className="text-dark">{commatise(globalStatsData.cod.completed)} <small className="text-body">Completed</small></p>
                                    <p className="text-dark">{commatise(globalStatsData.cod.totalSubscribed)} <small className="text-body">Total</small></p>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar global-stats-progress-bars" role="progressbar" aria-valuenow={Math.ceil(globalStatsData.cod.completed/globalStatsData.cod.totalSubscribed)} aria-valuemin="0" aria-valuemax="100">
                                        <span>{Math.ceil(globalStatsData.cod.completed/globalStatsData.cod.totalSubscribed)}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative mt-auto text-center">
                            <img className="img-fluid stat-cover-img" src="img/stat-core.jpg" alt="" />
                            {/* <div className="causes-overlay">
                                <a className="btn btn-outline-primary" href="">
                                    Show List
                                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                        <i className="fa fa-arrow-right"></i>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>



                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                        <div className="text-center p-4 pt-0">
                            <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                <small>Transition to Practice</small>
                            </div>
                            <div style={{minHeight:"120px"}}>
                                <p>Transition to Practice completion rate across all schools in Canada for residents using this website</p>
                            </div>
                            <div className="causes-progress bg-light p-3 pt-2">
                                <div className="d-flex justify-content-between">
                                    <p className="text-dark">{commatise(globalStatsData.ttp.completed)} <small className="text-body">Completed</small></p>
                                    <p className="text-dark">{commatise(globalStatsData.ttp.totalSubscribed)} <small className="text-body">Total</small></p>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar global-stats-progress-bars" role="progressbar" aria-valuenow={Math.ceil(globalStatsData.ttp.completed/globalStatsData.ttp.totalSubscribed)} aria-valuemin="0" aria-valuemax="100">
                                        <span>{Math.ceil(globalStatsData.ttp.completed/globalStatsData.ttp.totalSubscribed)}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative mt-auto text-center">
                            <img className="img-fluid stat-cover-img" src="img/stat-practice.jpg" alt="" />
                            {/* <div className="causes-overlay">
                                <a className="btn btn-outline-primary" href="">
                                    Show List
                                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                        <i className="fa fa-arrow-right"></i>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}


function AboutUs() {
    return (
        <div className="container-xxl py-5">
            <div className="container text-left">
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="position-relative overflow-hidden h-100" style={{'minHeight': '400px'}}>
                            <img className="position-absolute w-100 h-100 pt-5 pe-5" src="img/about-1.jpg" alt="" style={{'objectFit': 'cover'}} />
                            <img className="position-absolute top-0 end-0 bg-white ps-2 pb-2" src="img/about-2.jpg" alt="" style={{'width': '200px', 'height': '200px'}} />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="h-100">
                            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">About Us</div>
                            <h1 className="display-6 mb-5">A tool created to help you track your EPAs</h1>
                            <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                                <p className="text-dark mb-2">Efficiently keeping track of EPAs can be confusing, cumbersome and takes precious time residents have on a task that should be trivial.
                                The official website of <a href="https://www.royalcollege.ca/rcsite/cbd/implementation/cbd-milestones-epas-e" target="_blank" >Royal College of Physicians and Surgeons of Canada</a> has the latest and most up to date information on the EPAs. 
                                <br />
                                I made this tool for myself and also you, my fellow residents, to have the ability to efficiently track the progression of the Competency By Design EPAs and have it send automatic reminders to the evaluators, to save us the discomfort of sending constant reminders!
                                <br />
                                <strong>Of note, just a reminder that this is an unofficial tool and is not affliciated with any official body or school.</strong>
                                </p>
                                <a href="http://www.fardadja.com" target="_blank" className="text-primary">Fardad Jabbary</a>
                            </div>
                            <p className="mb-5">Since each school provides its own way of administering EPAs, it's virtually impossible to &ldquo;connect&rdquo; to each school's internal EPA management system. While this is techinically feasible and would be a great addition, it is up to each school's administration to facilitate the access. At the moment, this software's accuracy completely relies on you. Think of it as a modern tool specifically created for residents by a fellow resident to help keep track of all the EPAs.
                                <br />
                                If you have any issues using this software or have any suggestions or comments, please get in touch via social media or email, I'd love to hear from you.
                            </p>
                            {/* <a className="btn btn-primary py-2 px-3 me-3" href="">
                                Learn More
                                <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                    <i className="fa fa-arrow-right"></i>
                                </div>
                            </a> */}
                            <a className="btn btn-outline-primary py-2 px-3" href="http://www.fardadja.com" target="_blank">
                                Get in Touch
                                <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                    <i className="fa fa-arrow-right"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
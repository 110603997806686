import {useState, useEffect} from 'react';
import Select from 'react-select';

export default function EditProfile(props) {
    const {institutesData, userInfo, programsData, callApi, makeRandomNum} = props;

    const [formData, setFormData] = useState([])
    const [doPasswordsMatch, setDoPasswordsMatch] = useState(true)
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [isOldPasswordValid, setIsOldPasswordValid] = useState(true)
    const [registrationErrors, setRregistrationErrors] = useState("")
    const [hasAPorgramOrEpaChanged, setHasAPorgramOrEpaChanged] = useState(false);
    const [hasUserInteractedWithUI,setHasUserInteractedWithUI] = useState(false);
    const [showUpdateConfirmDialogue, setShowUpdateConfirmDialogue] = useState(false);
    // const [valueKeeper, setValueKeeper] = useState([]);
    


    function isChangingPassword() {
        return formData.hasOwnProperty("pwordmode") && formData["pwordmode"]=="change";
    }

    function handleFormChange(e, pId=null, ver=null) {
        let {name, value, options, checked} = e.target
        setHasUserInteractedWithUI(true);
        // Select tags have options - for multi-select ones put them into an array.
        if (options) {
            value = [...e.target.options].filter(o=>o.selected).map(o=>o.value);
            if (name == "gender" || name == "residencyYear") {
                if (value.length === 1){value = value[0];}
            }
        }

        if (name=="pwordmode") {
            value = checked ? "change" : "abstain";
        }

        if(pId !== null) {
            setHasAPorgramOrEpaChanged(true);
            switch (name) {
                case "ttd":
                case "fod":
                case "cod":
                case "ttp":
                let isHomeProgram = false;
                if (formData.hasOwnProperty("homeProgramId") && formData["homeProgramId"].length>0 && formData["homeProgramId"][0]==pId) {
                    isHomeProgram = true;
                }
                handleSegmentCheckEvent(name, isHomeProgram);
            }
        }

        // If home program ID changes, ensure it is not selected in the additional programs
        // This is an old fn - it really is not necessary anymore as the options are already checked before element population.
        if (name=="homeProgramId") {
          if (formData["additionalProgramIds"] != undefined && formData["additionalProgramIds"]!=null) {
            if (typeof formData["additionalProgramIds"] == "string") {
              if (formData["additionalProgramIds"] == value) {
                formData["additionalProgramIds"] = null;
              }
            } else if (Array.isArray(formData["additionalProgramIds"])) {
              
              let pids = formData["additionalProgramIds"].filter(v=>v!=value);
              setFormData(current=> ({...current,"additionalProgramIds": pids}));
            }
          }
          value = [value]
        }

        
        
        
        // Ignore updating the version check boxes.
        switch (name) {
            case "ttd":case "fod":case "cod":case "ttp":break;
            default:
                setFormData(current=> ({...current,[name]: value}));
        }
        
        
        function handleSegmentCheckEvent(catName, isHomeProgram) {
            let componentName = isHomeProgram? "homeProgram" : "additionalPrograms"
            
            let filtered = formData[componentName].filter(p=>p.id==pId);
            if (filtered.length>0) {
                if (filtered[0].hasOwnProperty("segments")) {
                    let segments = filtered[0]["segments"];
                    // Create shallow copy so state won't change
                    let cat = [...segments[catName]] ?? [];
                    if (!cat.includes(ver)) {
                        // Version does not exist - if checked, add it
                        if (checked) {
                            cat.push(ver);
                        }
                    } else {
                        // Value exists - if unchecked, remove it
                        if (!checked) {
                            var index = cat.indexOf(ver);
                            if (index !== -1) {
                                cat.splice(index, 1);
                            }
                        }
                    }
                    setFormData(current=>{
                        let idIndex = current[componentName].findIndex(e=>e.id==pId);
                        if (idIndex== -1) { throw ("Version integrity check error!") }
                        return {
                            
                            ...current,
                                [componentName]:[
                                    ...current[componentName].map(e=>{
                                        return e.id != pId
                                            ? e
                                            : {
                                                ...e,segments:{
                                                    ...current[componentName].filter(x=>x.id==pId)[0].segments,
                                                    [catName]:cat
                                                }
                                            }
                                    })
                                ]
                        }
                    });
                }
            }

            
        }
    }

    

    function showError(msg) {
        let err_msg = [<h5 key="" style={{'marginBottom':'0','paddingBottom':'0'}}>Editing profile failed because:</h5>].concat(msg)
        setRregistrationErrors(err_msg.reduce((el,a) => el.concat(a, <br />),[]))
    }

    function submitForm(e) {
        e.preventDefault();
        setRregistrationErrors("");
        let hasError = false;
        let err_msg = [];
        
        
        if (!formData["firstName"] || formData["firstName"].trim().length < 1) { err_msg.push("First name is required!"); hasError = true;}
        if (!formData["lastName"] || formData["lastName"].trim().length < 1) { err_msg.push("Last name is required!"); hasError = true;}
        if (!formData["email"] || formData["email"].trim().length < 1) { err_msg.push("Email address is required!"); hasError = true;}
        if ((formData["email"] && formData["email"].trim().length >= 1) && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData["email"].trim()))) { err_msg.push("Please enter a valid email!"); hasError = true;}
        if (isChangingPassword()) {
            if (!isOldPasswordValid) {err_msg.push("Your current password is invalid. It must be 6 or more characters (and also less than 48 characters)!"); hasError = true;}
            if (!isPasswordValid) {err_msg.push("New password is invalid. It must be 6 or more characters (and also less than 48 characters)!"); hasError = true;}
            if (!doPasswordsMatch) { err_msg.push("Passwords don't match!"); hasError = true;}
            if (!formData["oldpword"] || formData["oldpword"].length < 6) { hasError = true;}
            if (!formData["pword"] || formData["pword"].length < 6) { hasError = true;}
            if (!formData["pwordrpt"] || formData["pwordrpt"].length < 6) { hasError = true;}
        }
        if (!formData["educationalInstitute"] || formData["educationalInstitute"].trim().length < 1) { err_msg.push("Educational Institute is required!"); hasError = true;}
        if (!formData["gender"] || formData["gender"].trim().length < 1) { err_msg.push("Gender is required!"); hasError = true;}
        if (!formData["residencyYear"] || formData["residencyYear"].trim().length < 1) { err_msg.push("Residency year is required!"); hasError = true;}
        if (!formData["homeProgramId"] || formData["homeProgramId"].length < 1) { err_msg.push("You must select a home program!"); hasError = true;}
        
        if (hasError) {
            showError(err_msg);
        } else {








            // Shallow copy of formData and removing rpt pwd. Actual pwd can also be hashed, albeit weak and crackable.
            
           let dataToSubmit = {...formData, mode: "edit", userid: props.userInfo.id};
           if (dataToSubmit.hasOwnProperty("gender") && Array.isArray(dataToSubmit["gender"]) && dataToSubmit["gender"].length > 0) {dataToSubmit["gender"] = dataToSubmit["gender"][0];}
           if (dataToSubmit.hasOwnProperty("residencyYear") && Array.isArray(dataToSubmit["residencyYear"]) && dataToSubmit["residencyYear"].length > 0) {dataToSubmit["residencyYear"] = dataToSubmit["residencyYear"][0];}
           delete dataToSubmit["pwordrpt"];
           console.log("Submitting:")
           console.log(dataToSubmit);
          //  console.log("OK - submit for edit profile")
           const options = {
            method: "POST",
            headers: {"Accept": "application/json","Content-Type": "application/json"},
            body: JSON.stringify(dataToSubmit)
            }
            props.setSpinnerShow(true);
            

            callApi({endPoint: "/api/auth/register",meth:"POST",body: JSON.stringify(dataToSubmit), 
                 actionableSuccClb: 
                     (r)=>{ props.showAlertBox("Profile Updated Successfully",r["result"]);
                     props.reloadProfile(true)
                     document.getElementById("editProfileCancelBtn").click();
                     // Should remain last actionable statement. Immediately gets rid of the modal.
                     props.reset();
                 }, actionableFailClb: (r)=>{props.showAlertBox("Could not update profile",r["result"]);showError(r["result"]);},
                 finallyClb: ()=>props.setSpinnerShow(false), catchClb: (er)=>props.showAlertBox("Error",er)});
            // console.log(dataToSubmit);
        }
    }

    useEffect(()=>{
        setDoPasswordsMatch(formData["pword"] && formData["pwordrpt"] && formData["pword"] === formData["pwordrpt"])
        setIsPasswordValid(formData["pword"] && formData["pword"].length>=6)
        setIsOldPasswordValid(formData["oldpword"] && formData["oldpword"].length>=6)
        
    },[formData])

    function isChecked(id,cat,ver) {
        if (formData["homeProgram"] && formData["homeProgram"].length>0) {
            if (formData["homeProgram"].filter(p=>p.id==id).length>0){
                return formData["homeProgram"].filter(p=>p.id==id)[0].segments[cat].includes(ver);
            }
        }
        if (formData["additionalPrograms"] && formData["additionalPrograms"].length>0) {
            if (formData["additionalPrograms"].filter(p=>p.id==id).length>0){
                return formData["additionalPrograms"].filter(p=>p.id==id)[0].segments[cat].includes(ver);
            }
        }
        return false;
    }


    function init() {
        // Initial setup        

        
        setFormData({
            ["homeProgramId"]:[userInfo.homeProgramId],
            ["homeProgram"]: userInfo.homeProgram,
            ["additionalProgramIds"]:userInfo.additionalProgramIds,
            ["additionalPrograms"]: [...userInfo.additionalPrograms],
            ["firstName"]:userInfo.firstName,
            ["lastName"]:userInfo.lastName,
            ["email"]:userInfo.email,
            ["gender"]:userInfo.gender,
            ["educationalInstitute"]:userInfo.educationalInstitute,
            ["residencyYear"]:userInfo.residencyYear
        });
    }


    useEffect(()=>{
        // Necessary after profile update.
        init();
    },[userInfo])

    useEffect(()=>{
        init();
    },[])

    useEffect(()=>{
        syncProgramIdsWithAdditionalPrograms("additionalProgramIds","additionalPrograms");
    },[formData["additionalProgramIds"]]);

    useEffect(()=>{
        syncProgramIdsWithAdditionalPrograms("homeProgramId","homeProgram");
    },[formData["homeProgramId"]]);

    function syncProgramIdsWithAdditionalPrograms(idComponentName, programComponentName){
        function createEmptySegmentsScafolding() {
            let segments = {}
            segments["ttd"] = [];segments["fod"] = [];segments["cod"] = [];segments["ttp"] = [];
            return segments;
        }
        // This fn should be called when programids change - purpose is to keep additionalprograms synced
        // Ensure only program IDs are showing for version selection which have actually been selected
        if (formData.hasOwnProperty(idComponentName) && formData.hasOwnProperty(programComponentName)) {
            // Ensure all selected programs are showing their versions also
            let pids = formData[idComponentName]
            if (pids.length<1) {
                setFormData(current=>({...current, [programComponentName]: []}));
                if (hasUserInteractedWithUI) {setHasAPorgramOrEpaChanged(true);}
                return;
            }
            let capturedComponent = [...formData[programComponentName]];
            
            for (let i = 0; i< pids.length; i++) {
                // Make sure all selected program ids are in additionalprograms
                if (capturedComponent.filter(v=>v.id==pids[i]).length<1) {
                    if (hasUserInteractedWithUI) {setHasAPorgramOrEpaChanged(true);}
                    capturedComponent.push({"id":pids[i], "segments":createEmptySegmentsScafolding()});
                }
            }
            let obsoleteList = capturedComponent.filter(p=>!formData[idComponentName].includes(p.id));
            for (let i = 0; i< obsoleteList.length;i++) {
                if (hasUserInteractedWithUI) {setHasAPorgramOrEpaChanged(true);}
                capturedComponent.splice(capturedComponent.findIndex(item=>item.id==obsoleteList[i].id),1);
                clearSegmentsFor(obsoleteList[i].id);
            }
            setFormData(current=>({...current,[programComponentName]:capturedComponent}));
        }
    }

    

    function clearSegmentsFor(id) {
        // Clears segments in UI and model components
        // let toKeep = [];
        // for (let i=valueKeeper.length-1;i>=0;i--){
        //     if (!valueKeeper[i].startsWith(id)) {
        //         toKeep.push(valueKeeper[i]);
        //     }
        // }
        // setValueKeeper(toKeep);
        
        let homeToKeep = formData["homeProgram"].filter(e=>e.id!=id);
        setFormData(current=>({...current,"homeProgram":homeToKeep}));

        let additionalsToKeep = formData["additionalPrograms"].filter(e=>e.id!=id);
        setFormData(current=>({...current,"additionalPrograms":additionalsToKeep}));
        
    }
    function doAllSegmentsHaveValue(segments) {
        if (segments == undefined || segments == null) {
            return false;
        }
        if (!segments.hasOwnProperty("ttd") || !segments["ttd"] ||
            !segments.hasOwnProperty("fod") || !segments["fod"] ||
            !segments.hasOwnProperty("cod") || !segments["cod"] ||
            !segments.hasOwnProperty("ttp") || !segments["ttp"]) {
                return false;
        }
        if (
            segments["ttd"].length<1 ||
            segments["fod"].length<1 ||
            segments["cod"].length<1 ||
            segments["ttp"].length<1
        ) {
            return false;
        }
        return true;

    }


return(

<div className="modal fade" id="editProfileForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="EditProfileLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
            <div className="modal-header"><button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
            <div className="modal-body">
                <div className="container h-100">
                        {(<div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-lg-12 col-xl-11">
                        <div className="card text-black" style={{"borderRadius": "25px"}}>
                            <div className="card-body p-md-5 pt-0 pt-md-0">
                            <div className="row justify-content-center">
                            <h2 className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-4">Edit Profile</h2>
                                <div className="col-md-12 col-lg-6">

                                

                                <form className="mx-1 mx-md-4">

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <div className="form-floating mb-3">
                                                <input type="text" name="firstName" className="form-control"
                                                    onChange={(e) => handleFormChange(e)} placeholder="First Name" defaultValue={formData["firstName"]} />
                                                <label className="form-label">First Name</label>
                                            </div>


                                            <div className="form-floating mb-3">
                                                <input type="text" name="lastName" className="form-control"
                                                    onChange={(e) => handleFormChange(e)} placeholder="Last Name" defaultValue={formData["lastName"]} />
                                                <label className="form-label">Last Name</label>
                                            </div>
                                            

                                            

                                            <select className="form-select" aria-label="Gender selection" style={{'width':'100%'}}
                                                name="gender" onChange={(e) => handleFormChange(e)} >
                                                <option value="">Gender</option>
                                                <option value="male" selected={formData["gender"] === "male"}>Male</option>
                                                <option value="female" selected={formData["gender"] === "female"}>Female</option>
                                                <option value="other" selected={formData["gender"] === "other"}>Other</option>
                                            </select>

                                        </div>
                                    </div>


                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-hashtag fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <select className="form-select" aria-label="Year of residency selection" style={{'width':'100%'}}
                                            name="residencyYear" onChange={(e) => handleFormChange(e)} >
                                                <option value="">Post graduate year (year of residency)</option>
                                                <option value="1" selected={formData["residencyYear"] == "1"}>R 1</option>
                                                <option value="2" selected={formData["residencyYear"] == "2"}>R 2</option>
                                                <option value="3" selected={formData["residencyYear"] == "3"}>R 3</option>
                                                <option value="4" selected={formData["residencyYear"] == "4"}>R 4</option>
                                                <option value="5" selected={formData["residencyYear"] == "5"}>R 5</option>
                                                <option value="6" selected={formData["residencyYear"] == "6"}>R 6</option>
                                                <option value="7" selected={formData["residencyYear"] == "7"}>R 7</option>
                                                <option value="8" selected={formData["residencyYear"] == "8"}>R 8</option>
                                            </select>
                                            <div className="text-center fst-italic" style={{color:"gray"}}>(this automatically updates every July 1)</div>
                                        </div>
                                        </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <div className="form-floating mb-3">
                                                <input type="email" name="email" className="form-control"
                                                    onChange={(e) => handleFormChange(e)} placeholder="Email"  defaultValue={formData["email"]} />
                                                <label className="form-label" htmlFor="email">Email</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Passwords - START */}
                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-shield fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <div className="form-check form-switch mb-2">
                                                <input className="form-check-input" name="pwordmode" id="pwordmodecheckbox" type="checkbox" role="switch" // No need for this really - form 'resets' after use. checked={isChangingPassword()}
                                                    onChange={(e) => handleFormChange(e)}/>
                                                <label className="form-check-label" htmlFor="pwordmodecheckbox">Change password?</label>
                                            </div>
                                        
                                        { isChangingPassword() && !isOldPasswordValid && (<div className="alert alert-warning" role="alert" style={{'marginBottom':'0.2em','padding':'0.2em'}}>6 letters or more please</div>)}
                                        { isChangingPassword() && <div className="form-floating mb-3">
                                                <input type="password" name="oldpword" className="form-control"
                                                    onChange={(e) => handleFormChange(e)} autoComplete="off" placeholder="Current Password" />
                                                <label className="form-label" htmlFor="">Current Password</label>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    { isChangingPassword() && <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                        {isChangingPassword && !isPasswordValid && (<div className="alert alert-warning" role="alert" style={{'marginBottom':'0.2em','padding':'0.2em'}}>6 letters or more please</div>)}
                                        <div className="form-floating mb-3">
                                                <input type="password" name="pword" className="form-control"
                                                    onChange={(e) => handleFormChange(e)} autoComplete="off" placeholder="Password" />
                                                <label className="form-label" htmlFor="">Password</label>
                                            </div>
                                        </div>
                                    </div>}

                                    {isChangingPassword() && <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            {!doPasswordsMatch && (<div className="alert alert-danger" role="alert" style={{'marginBottom':'0.2em','padding':'0.2em'}}>Passwords need to match!</div>)}
                                            <div className="form-floating mb-3">
                                                <input type="password" name="pwordrpt" className="form-control"
                                                    onChange={(e) => handleFormChange(e)} autoComplete="off" placeholder="Repeat Password" />
                                                <label className="form-label" htmlFor="">Repeat password</label>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* Passwords - END */}

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fa-solid fa-school fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <Select key={makeRandomNum()} name="educationalInstitute" options={institutesData.namesList} isMulti={false} 
                                                isSearchable={true} isClearable={false} placeholder="Select your residency institute" 
                                                onChange={(e)=>handleFormChange({target: {name:"educationalInstitute", value:e["value"]}})}
                                                defaultValue={
                                                    formData["educationalInstitute"] && formData["educationalInstitute"].length>0 ?
                                                    {value: formData["educationalInstitute"], label:formData["educationalInstitute"]}: null
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                        <i className="fa-regular fa-hospital fa-lg me-3 fa-fw"></i>
                                        <div className="form-outline flex-fill mb-0">
                                            <Select key={makeRandomNum()} name="homeProgramId" isSearchable={true} isClearable={false} placeholder="Select your home program"
                                                options={props.programsData.filter(p=>p.name!=="Surgical Foundations" && !(formData["additionalProgramIds"] ?? []).includes(p.id)).map((p)=>{return {value:p.id,label:p.name}})}
                                                onChange={(e)=>handleFormChange({target: {name:"homeProgramId", value:e["value"]}})}
                                                defaultValue={
                                                    formData["homeProgramId"] && formData["homeProgramId"].length>0 ?
                                                    {value: formData["homeProgramId"], label:(programsData.filter(e=>e.id==formData["homeProgramId"]).length > 0? programsData.filter(e=>e.id==formData["homeProgramId"])[0].name:"")}:null
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-row align-items-center mb-4">
                                    <i className="fa-solid fa-hospital fa-lg me-3 fa-fw"></i>


                                    <Select key={makeRandomNum()} isMulti={true} isSearchable={true} isClearable={false}  isDisabled={!formData.hasOwnProperty("homeProgramId") || formData["homeProgramId"]==undefined || formData["homeProgramId"]==null || formData["homeProgramId"].length < 1}
                                        placeholder="Select any additional programs" name="additionalProgramIds"
                                        options={props.programsData.filter(p=>{return p.id!==((formData["homeProgramId"] != null && formData["homeProgramId"].length>0) ? formData["homeProgramId"][0] : "Loading...")}).map((p)=>{return {value:p.id, label:p.name}})}
                                        onChange={(e)=>{for (let i=0; i<e.length;i++) {e[i].selected = true;}; handleFormChange({target: {name:"additionalProgramIds", options:e}});}}
                                        className="form-outline flex-fill mb-0"
                                        defaultValue={
                                            formData["additionalProgramIds"] && formData["additionalProgramIds"].length>0 ?
                                            formData["additionalProgramIds"].map(e=>{
                                                return {value: e, label:(programsData.filter(p=>p.id==e).length > 0? programsData.filter(p=>p.id==e)[0].name : "Loading...")}
                                            }):null
                                        }
                                    />
                                    
                                    </div>





                                    

                                    

                                </form>

                                </div>
                                <div className="col-md-12  col-lg-6 d-flex align-items-center">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 d-flex align-items-center">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <h4>Your subscriptions to your programs EPAs<span className="info-tooltip fs-6 fw-normal" style={{fontFamily:"initial !important"}} data-info-tooltip-content="Some institutions may require a resident to 
                                                    adhere to a different/newer version of an entire EPA or a portion of it (i.e. TTD, FOD, COD and TTP) if a new version is released. here you can (un)subscribe to different versions of the EPAs for a program."><sup>&#9432;</sup></span></h4>
                                                <p className="fs-6">
                                                    <span className="fw-bold">Legend: </span><br />
                                                    <span className="fw-bold">TTD: </span>Transition To Discipline <br />
                                                    <span className="fw-bold">FOD: </span>Foundations Of Discipline<br />
                                                    <span className="fw-bold">COD: </span>Core Of Discipline<br />
                                                    <span className="fw-bold">TTP: </span>Transition To Practice
                                                </p>

                                                {/* EPA granularity selection - START */}

                                                {/* HOME PROGRAM START */}
                                                {formData.hasOwnProperty("homeProgramId") && formData["homeProgramId"].map(pid=>
                                                        programsData.filter(pd=>pd.id==pid).map(pd=>{
                                                            return (
                                                                <div key={makeRandomNum()}>
                                                                <hr key={makeRandomNum()} />
                                                                <h5 key={makeRandomNum()} className="text-primary">{pd.name}</h5>
                                                                { formData.hasOwnProperty("homeProgram") && formData["homeProgram"].length>0 && !doAllSegmentsHaveValue(formData["homeProgram"][0]["segments"]) && <div key={makeRandomNum()} className="alert alert-warning p-1">Not all categories are selected for this program! Although this is possible to do, it may not be what you want.</div>}
                                                                {pd.epa_versions.map((v,index,arr)=>
                                                                {return (
                                                                    <div key={makeRandomNum()}>
                                                                        
                                                                <h6 key={makeRandomNum()}>{v}</h6>
                                                                <div key={makeRandomNum()} className={`d-flex ${index<arr.length-1?"border-bottom border-primary":""} mb-1 pb-1`}>
                                                                    <div key={makeRandomNum()} className="form-check">
                                                                        <input key={makeRandomNum()} className="ms-3 form-check-input" type="checkbox" id={pd.id+"ttd"+v} name="ttd" onChange={(e)=>handleFormChange(e,pd.id, v)} checked={isChecked(pd.id,"ttd",v)?"checked":""}
                                                                        />
                                                                        <label key={makeRandomNum()} className="form-check-label ps-1" htmlFor={pd.id+"ttd"+v}>TTD</label>
                                                                    </div>
                                                                    <div key={makeRandomNum()} className="ms-1 form-check">
                                                                        <input key={makeRandomNum()} className="form-check-input" type="checkbox" id={pd.id+"fod"+v} checked={isChecked(pd.id,"fod",v)?"checked":""} name="fod" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label" htmlFor={pd.id+"fod"+v} >FOD</label>
                                                                    </div>
                                                                    <div key={makeRandomNum()} className="ms-1 form-check">
                                                                        <input key={makeRandomNum()} className="form-check-input" type="checkbox" id={pd.id+"cod"+v} checked={isChecked(pd.id,"cod",v)?"checked":""} name="cod" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label" htmlFor={pd.id+"cod"+v} >COD</label>
                                                                    </div>
                                                                    <div key={makeRandomNum()} className="ms-1 form-check">
                                                                        <input key={makeRandomNum()} className="form-check-input" type="checkbox" id={pd.id+"ttp"+v} checked={isChecked(pd.id,"ttp",v)?"checked":""} name="ttp" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label" htmlFor={pd.id+"ttp"+v} >TTP</label>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                )})}
                                                                </div>
                                                            )}))}
                                                
                                                {/* HOME PROGRAM END */}
                                                {/* ADDITIONAL PROGRAMS START */}

                                                {formData.hasOwnProperty("additionalProgramIds") && formData["additionalProgramIds"].map(pid=>
                                                        programsData.filter(pd=>pd.id==pid).map(pd=>{
                                                            return (
                                                                <div key={makeRandomNum()}>
                                                                <hr key={makeRandomNum()} />
                                                                <h5 key={makeRandomNum()} className="text-primary">{pd.name}</h5>
                                                                { formData.hasOwnProperty("additionalPrograms") && formData["additionalPrograms"].length>0 && formData["additionalPrograms"].filter(ap=>ap.id==pd.id).length>0 && !doAllSegmentsHaveValue(formData["additionalPrograms"].filter(ap=>ap.id==pd.id)[0]["segments"]) && <div key={makeRandomNum()} className="alert alert-warning p-1">Not all categories are selected for this program! Although this is possible to do, it may not be what you want.</div>}
                                                                {pd.epa_versions.map((v,index,arr)=>
                                                                {return (
                                                                    <div  key={makeRandomNum()}>
                                                                <h6 key={makeRandomNum()}>{v}</h6>
                                                                <div key={makeRandomNum()} className={`d-flex ${index<arr.length-1?"border-bottom border-primary":""} mb-1 pb-1`}>
                                                                    <div key={makeRandomNum()} className="form-check">
                                                                        <input key={makeRandomNum()} className="ms-3 form-check-input" type="checkbox" id={pd.id+"ttd"+v} checked={isChecked(pd.id,"ttd",v)?"checked":""} name="ttd" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label ps-1" htmlFor={pd.id+"ttd"+v}>TTD</label>
                                                                    </div>
                                                                    <div key={makeRandomNum()} className="ms-1 form-check">
                                                                        <input key={makeRandomNum()} className="form-check-input" type="checkbox" id={pd.id+"fod"+v} checked={isChecked(pd.id,"fod",v)?"checked":""} name="fod" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label" htmlFor={pd.id+"fod"+v} >FOD</label>
                                                                    </div>
                                                                    <div key={makeRandomNum()} className="ms-1 form-check">
                                                                        <input key={makeRandomNum()} className="form-check-input" type="checkbox" id={pd.id+"cod"+v} checked={isChecked(pd.id,"cod",v)?"checked":""} name="cod" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label" htmlFor={pd.id+"cod"+v} >COD</label>
                                                                    </div>
                                                                    <div key={makeRandomNum()} className="ms-1 form-check">
                                                                        <input key={makeRandomNum()} className="form-check-input" type="checkbox" id={pd.id+"ttp"+v} checked={isChecked(pd.id,"ttp",v)?"checked":""} name="ttp" onChange={(e)=>handleFormChange(e,pd.id, v)} />
                                                                        <label key={makeRandomNum()} className="form-check-label" htmlFor={pd.id+"ttp"+v} >TTP</label>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                )})}
                                                                </div>
                                                            )}))}

                                                {/* EPA granularity selection - START */}






                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className="col-md-11 d-flex align-items-center">
                                    {registrationErrors.length > 1 && (<div className="alert alert-danger" role="alert">
                                        {registrationErrors}
                                    </div>)}
                                    </div>
                                </div>
                                </div>

                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4 modal-footer">
                                            {/* This button is only for closing the modals programmatically - to avoic unnecessarily loading BS JS lib for one action */}
                                            <button type="button" className="btn" data-bs-dismiss="modal" id="editProfileCancelBtn" style={{display:"none"}}>Cancel</button>
                                        {
                                            !showUpdateConfirmDialogue &&
                                            <>
                                            <button type="button" className="btn btn-secondary btn-lg btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn-primary btn-lg" 
                                            onClick={(e)=>{hasAPorgramOrEpaChanged? setShowUpdateConfirmDialogue(true) : submitForm(e)}}>Update</button>
                                            </>}


                                        {showUpdateConfirmDialogue && <div className="text-center">
                                            <div className="alert alert-warning" role="alert">
                                                You have made changes to your Program(s) or EPA versions - this may result in deletion of some evaluations you have already entered. Are you sure you want to continue?
                                            </div>
                                            <button type="button" className="btn btn-secondary btn-outline-primary me-2" style={{minWidth:"75px"}} onClick={()=>setShowUpdateConfirmDialogue(false)} >Cancel</button>
                                            <button type="button" className="btn btn-warning btn fw-bold" style={{minWidth:"75px"}}
                                                onClick={(e)=>submitForm(e)}
                                            >Confirm</button>
                                        </div>}
                                    
                                    </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
</div>
);
}
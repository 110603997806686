import { useRef } from "react";

export default function Guide(props) {

var ytFrame = useRef();

    return (
<div className="modal fade" id="guideModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content rainbow-bg"
        // ref={modalContentRef} onMouseMove={(e)=>handleMouseMove(e, modalContentRef)}
        >
        <div className="modal-header">
            <h5 className="modal-title">EPA Tracking Tool Demo</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{
                // ytFrame.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                ytFrame.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                }}></button>
        </div>
        <div className="modal-body">

            <div className="container h-100">
            <iframe ref={ytFrame} width="100%" height="400" src="https://www.youtube.com/embed/9IZ9zyZchEA?enablejsapi=1" title="Demo for EPA Tracking Tool" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="allowFullScreen"></iframe>
            </div>
        </div>
    </div>
</div>
</div>
    );
}
import {useState, useEffect} from 'react'


export default function Login(props) {

  const {callApi, login, setSpinnerShow} = props;

const [formData, setFormData] = useState([])
const [isPasswordValid, setIsPasswordValid] = useState(true)
const [messagesTableau, setMessagesTableau] = useState("")
const [isPasswordResetMode,setIsPasswordResetMode] = useState(false);

function handleFormChange(e) {
    let {name, value} = e.target
    
    setFormData(current=> ({
      ...current,
      [name]: value
    }))
}

function showError(title,msg) {
  let err_msg = [<h5 key="header" style={{'marginBottom':'0','paddingBottom':'0'}}>{title}</h5>].concat(msg)
  setMessagesTableau(err_msg);
  // setMessagesTableau(err_msg.reduce((el,a) => el.concat(a, <br />),[]))
}

function cleanUp() {
  clearPassword();
  setFormData([]);
}

function clearPassword() { document.getElementById("loginpword").value=""; }

function submitForm(e) {
    e.preventDefault();
    setMessagesTableau("");
    let hasError = false;
    let err_msg = [];
    if (!isPasswordResetMode && !isPasswordValid) {err_msg.push("Password is invalid. It must be 6 or more characters (and also less than 48 characters)!"); hasError = true;}
    if (!formData["email"] || formData["email"].trim().length < 1) { err_msg.push("Email address is required!"); hasError = true;}
    if ((formData["email"] && formData["email"].trim().length >= 1) && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData["email"].trim()))) { err_msg.push("Please enter a valid email!"); hasError = true;}
    if (!isPasswordResetMode && (!formData["pword"] || formData["pword"].length < 6)) { hasError = true;}
    
    if (hasError) {
        showError("Login failed because:",err_msg);
    } else {
        // Shallow copy of formData and removing rpt pwd. Actual pwd can also be hashed, albeit weak and crackable.
        let dataToSubmit = {...formData};
        setSpinnerShow(true);
        let ep = isPasswordResetMode? "/api/auth/reset/p" : "/api/auth/login";
        let successAction = null;
        let failAction = null;
        let catchAction = null;

        if (isPasswordResetMode) {
          successAction = (r)=> {setSpinnerShow(false);
            setIsPasswordResetMode(false);
            showError("Password reset request received:",r.hasOwnProperty("result")?r["result"]:"");}
          failAction = (er) => {setSpinnerShow(false);showError("Password reset request failed because:",er.hasOwnProperty("result")?er["result"]:"");}
          catchAction = (er) => {setSpinnerShow(false);showError("Password reset request failed because:",er.hasOwnProperty("result")?er["result"]:"");}
        } else {
            successAction = (r)=>{
              // Action successful
              setSpinnerShow(false);
              login(r);
              // Get rid of the modal
              cleanUp();
              document.getElementById("loginCancelBtn").click();
            }
            failAction = (er) => {setSpinnerShow(false);showError("Login failed because:",er.hasOwnProperty("result")?er["result"]:"");}
            catchAction = (er) => {setSpinnerShow(false);showError("Login failed because:",er.hasOwnProperty("result")?er["result"]:"");}
        }
        callApi({
          endPoint: ep,
          meth: "POST",
          body: JSON.stringify(dataToSubmit),
          actionableSuccClb: successAction,
          actionableFailClb: failAction,
          catchClb: catchAction
        });
    }
}

useEffect(()=>{
    setIsPasswordValid(formData["pword"] && formData["pword"].length>=6)
},[formData])

return(

<div className="modal fade" id="loginForm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="container h-100">
          {!props.isLoggedIn && (<div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12 col-xl-11">
              <div className="card text-black" style={{"borderRadius": "25px"}}>
                <div className="card-body p-md-0 pt-0">
                  <div className="row justify-content-center">
                    <div className="col-md-12">

                      <p className="text-center h1 fw-bold mb-3 mx-1 mx-md-4 mt-4">{isPasswordResetMode? "Password Reset" : "Sign In"}</p>

                      <form className="mx-1 mx-md-4">

                          <div className="d-flex flex-row align-items-center mb-4">
                              <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                              <div className="form-outline flex-fill mb-0">
                              <input type="email" id="email" name="email" className="form-control"
                              onChange={(e) => handleFormChange(e)} autoComplete="off" />
                              <label className="form-label" htmlFor="email">Email</label>
                              </div>
                          </div>


                        { !isPasswordResetMode && <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                          {!isPasswordValid && (<div className="alert alert-warning" role="alert" style={{'marginBottom':'0.2em','padding':'0.2em'}}>6 characters or more please</div>)}
                            <input type="password" id="loginpword" name="pword" className="form-control"
                            onChange={(e) => handleFormChange(e)} autoComplete="off" />
                            <label className="form-label" htmlFor="loginpword">Password</label>
                          </div>
                        </div> }

                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4 modal-footer">
                        <button type="button" id="loginCancelBtn" className="btn btn-secondary btn-lg btn-outline-primary" data-bs-dismiss="modal"
                        onClick={()=>{clearPassword();}}>Cancel</button>
                          <button type="button" className="btn btn-primary btn-lg" 
                          onClick={(e)=>submitForm(e)}>{isPasswordResetMode?"Reset":"Sign In"}</button>
                          
                        </div>
                        {messagesTableau.length > 1 && (<div className="alert alert-danger" role="alert">
                        {messagesTableau.filter((i)=>i.key==="header").map((it)=>it)}
                        <ul>
                          {messagesTableau.filter((i)=>i.key!=="header").map((it)=><li>{it}</li>)}
                        </ul>
                      </div>)}

                      </form>

                    </div>
                    
                    <div className="row justify-content-center text-center">
                      <div className="col-sm-12 col-lg-6">
                          {isPasswordResetMode? "Remember your password?": "Forgotten your password?"}
                      <br /><br />
                      
                      {!isPasswordResetMode ?
                      <button type="button" className="btn btn-outline-primary py-2 px-3 ms-2" style={{'minWidth':'200px'}} onClick={(e)=>{setIsPasswordResetMode(true)}}>
                              Reset Password
                              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                  <i className="fa fa-arrow-right"></i>
                              </div>
                      </button>
                      :
                      <button type="button" className="btn btn-outline-primary py-2 px-3 ms-2"style={{'minWidth':'200px'}} onClick={(e)=>{setIsPasswordResetMode(false)}} >
                              Log In
                              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                  <i className="fa fa-arrow-right"></i>
                              </div>
                      </button>
                      }
                      </div>

                      
                      <div className="col-sm-12 col-lg-6">
                          Don't have an account yet?
                      <br /><br />
                      <button type="button" className="btn btn-outline-primary py-2 px-3 ms-2" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#registrationForm"
                      style={{'minWidth':'200px'}}>
                              Register
                              <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                  <i className="fa fa-arrow-right"></i>
                              </div>
                          </button>
                      <br /><br />
                      
                      </div>
                      
                    </div>
                      
                      
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        </div>

      </div>
    </div>
  </div>
</div>

);
}